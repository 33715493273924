var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,attrs:{"id":"main-container"}},[_c('div',{staticClass:"custom-row-element-wrap mt-5 mb-5"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"custom-bottom-border-separator"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[(_vm.urdudata == false)?_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('Home.1sttitle')))]):_vm._e(),(_vm.urdudata == true)?_c('h2',{staticClass:"widget-title text-uppercase",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.$t('Home.1sttitle')))]):_vm._e()]),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11"},[_c('carousel',{staticStyle:{"direction":"ltr"},attrs:{"per-page":3,"mouseDrag":true}},_vm._l((_vm.Upcomingevents),function(ix){return _c('slide',{key:ix.id},[_c('div',{staticClass:"style-brands bg-white"},[_c('div',{staticClass:"pl-3 pr-3"},[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{staticClass:"post-thumbnail mt-3",attrs:{"src":_vm.url + ix.image}})])])])])])]),(_vm.urdudata == false)?_c('span',[_c('router-link',{attrs:{"to":{
                              name: 'Eventdetails',
                              params: { id: ix.id }
                            }}},[_c('h6',{staticStyle:{"display":"block","word-break":"break-word","transition":"all 0.3s ease-in-out","color":"#000"}},[_vm._v(" "+_vm._s(ix.title))])])],1):_vm._e(),(_vm.urdudata == true)?_c('span',[_c('router-link',{attrs:{"to":{
                              name: 'Eventdetails',
                              params: { id: ix.id }
                            }}},[_c('h6',{staticStyle:{"display":"block","word-break":"break-word","transition":"all 0.3s ease-in-out","color":"#000"}},[_vm._v(" "+_vm._s(ix.urduTitle))])])],1):_vm._e()])])}),1)],1)])])])])]),_c('div',{staticClass:"custom-bottom-border-separator"},[_c('div',{staticClass:"row no-gutters"},[(_vm.urdudata == false)?_c('div',{staticClass:"col-lg-6"},[(_vm.isHealthAndBeauty)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-1"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('Home.HEALTH&BEAUTY')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.healthAndBeauty.videoOrImage != '' && _vm.healthAndBeauty.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.healthAndBeauty.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                                name: 'PostDetail',
                                params: { id: _vm.healthAndBeauty.id }
                              }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)])]):(_vm.healthAndBeauty.youtubeLink != '' && _vm.healthAndBeauty.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.healthAndBeauty.youtubeLink.substring(30, _vm.healthAndBeauty.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.healthAndBeauty.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.healthAndBeauty.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(0),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.healthAndBeauty.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                              name: 'CategoryList',
                              params: { id: _vm.healthAndBeauty.categoryId }
                            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.healthAndBeauty.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
                            name: 'PostDetail',
                            params: { id: _vm.healthAndBeauty.id }
                          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.healthAndBeauty.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.healthAndBeauty.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                                  name: 'CategoryList',
                                  params: { id: _vm.healthAndBeauty.categoryId }
                                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.healthAndBeauty.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.healthAndBeauty.numberofDays)+" days ago ")])]):(_vm.healthAndBeauty.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.healthAndBeauty.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-lg-6"},[(_vm.isHealthAndBeauty)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-1"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.$t('Home.HEALTH&BEAUTY')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.healthAndBeauty.videoOrImage != '' && _vm.healthAndBeauty.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.healthAndBeauty.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                                name: 'PostDetail',
                                params: { id: _vm.healthAndBeauty.id }
                              }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)])]):(_vm.healthAndBeauty.youtubeLink != '' && _vm.healthAndBeauty.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.healthAndBeauty.youtubeLink.substring(30, _vm.healthAndBeauty.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.healthAndBeauty.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.healthAndBeauty.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(1),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.healthAndBeauty.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                              name: 'CategoryList',
                              params: { id: _vm.healthAndBeauty.categoryId }
                            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.healthAndBeauty.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
                            name: 'PostDetail',
                            params: { id: _vm.healthAndBeauty.id }
                          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.healthAndBeauty.urduDescriptionTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.healthAndBeauty.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                                  name: 'CategoryList',
                                  params: { id: _vm.healthAndBeauty.categoryId }
                                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.healthAndBeauty.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.healthAndBeauty.numberofDays)+" days ago ")])]):(_vm.healthAndBeauty.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.healthAndBeauty.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e(),(_vm.urdudata == false)?_c('div',{staticClass:"col-lg-6"},[(_vm.isBeveragesAndDining)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-3"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('nav.Category.Beverages&Dining')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.beveragesAndDining.videoOrImage != '' && _vm.beveragesAndDining.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.beveragesAndDining.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.beveragesAndDining.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(_vm.beveragesAndDining.youtubeLink != '' && _vm.beveragesAndDining.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.beveragesAndDining.youtubeLink.substring(30, _vm.beveragesAndDining.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.beveragesAndDining.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.beveragesAndDining.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(2),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.beveragesAndDining.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                              name: 'CategoryList',
                              params: { id: _vm.beveragesAndDining.categoryId }
                            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.beveragesAndDining.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
                            name: 'PostDetail',
                            params: { id: _vm.beveragesAndDining.id }
                          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.beveragesAndDining.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.beveragesAndDining.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                                  name: 'CategoryList',
                                  params: { id: _vm.beveragesAndDining.categoryId }
                                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.beveragesAndDining.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.beveragesAndDining.numberofDays)+" days ago ")])]):(_vm.beveragesAndDining.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.beveragesAndDining.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-lg-6"},[(_vm.isBeveragesAndDining)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-3"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.$t('nav.Category.Beverages&Dining')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.beveragesAndDining.videoOrImage != '' && _vm.beveragesAndDining.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.beveragesAndDining.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.beveragesAndDining.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(_vm.beveragesAndDining.youtubeLink != '' && _vm.beveragesAndDining.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.beveragesAndDining.youtubeLink.substring(30, _vm.beveragesAndDining.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.beveragesAndDining.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.beveragesAndDining.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(3),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.beveragesAndDining.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                              name: 'CategoryList',
                              params: { id: _vm.beveragesAndDining.categoryId }
                            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.beveragesAndDining.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
                            name: 'PostDetail',
                            params: { id: _vm.beveragesAndDining.id }
                          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.beveragesAndDining.urduDescriptionTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.beveragesAndDining.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                                  name: 'CategoryList',
                                  params: { id: _vm.beveragesAndDining.categoryId }
                                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.beveragesAndDining.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.beveragesAndDining.numberofDays)+" days ago ")])]):(_vm.beveragesAndDining.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.beveragesAndDining.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"custom-bottom-border-separator"},[_c('div',{staticClass:"row no-gutters"},[(_vm.urdudata == false)?_c('div',{staticClass:"col-lg-6"},[(_vm.isSportsAndLeisure)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-2"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('Home.SPORTS&LEISURE')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.sportsAndLeisure.videoOrImage != '' && _vm.sportsAndLeisure.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.sportsAndLeisure.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.sportsAndLeisure.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(_vm.sportsAndLeisure.youtubeLink != '' && _vm.sportsAndLeisure.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.sportsAndLeisure.youtubeLink.substring(30, _vm.sportsAndLeisure.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.sportsAndLeisure.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.sportsAndLeisure.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(4),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.sportsAndLeisure.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                              name: 'CategoryList',
                              params: { id: _vm.sportsAndLeisure.categoryId }
                            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
                            name: 'PostDetail',
                            params: { id: _vm.sportsAndLeisure.id }
                          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.sportsAndLeisure.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                                  name: 'CategoryList',
                                  params: { id: _vm.sportsAndLeisure.categoryId }
                                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.sportsAndLeisure.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.numberofDays)+" days ago ")])]):(_vm.sportsAndLeisure.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-lg-6"},[(_vm.isSportsAndLeisure)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-2"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.$t('Home.SPORTS&LEISURE')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.sportsAndLeisure.videoOrImage != '' && _vm.sportsAndLeisure.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.sportsAndLeisure.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
              name: 'PostDetail',
              params: { id: _vm.sportsAndLeisure.id }
            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(_vm.sportsAndLeisure.youtubeLink != '' && _vm.sportsAndLeisure.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.sportsAndLeisure.youtubeLink.substring(30, _vm.sportsAndLeisure.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.sportsAndLeisure.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.sportsAndLeisure.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(5),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
              name: 'PostDetail',
              params: { id: _vm.sportsAndLeisure.id }
            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
              name: 'CategoryList',
              params: { id: _vm.sportsAndLeisure.categoryId }
            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
            name: 'PostDetail',
            params: { id: _vm.sportsAndLeisure.id }
          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.urduDescriptionTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.sportsAndLeisure.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                  name: 'CategoryList',
                  params: { id: _vm.sportsAndLeisure.categoryId }
                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.sportsAndLeisure.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.numberofDays)+" days ago ")])]):(_vm.sportsAndLeisure.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.sportsAndLeisure.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e(),(_vm.urdudata == false)?_c('div',{staticClass:"col-lg-6"},[(_vm.isHotelsAndStaycations)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-4"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('Home.HOTELS&STAYCATIONS')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.hotelsAndStaycations.videoOrImage != '' && _vm.hotelsAndStaycations.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.hotelsAndStaycations.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.hotelsAndStaycations.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(_vm.hotelsAndStaycations.youtubeLink != '' && _vm.hotelsAndStaycations.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.sportsAndLeisure.youtubeLink.substring(30, _vm.sportsAndLeisure.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.hotelsAndStaycations.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.hotelsAndStaycations.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(6),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                              name: 'PostDetail',
                              params: { id: _vm.hotelsAndStaycations.id }
                            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                              name: 'CategoryList',
                              params: { id: _vm.hotelsAndStaycations.categoryId }
                            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
                            name: 'PostDetail',
                            params: { id: _vm.hotelsAndStaycations.id }
                          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.hotelsAndStaycations.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                                  name: 'CategoryList',
                                  params: { id: _vm.hotelsAndStaycations.categoryId }
                                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.hotelsAndStaycations.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.numberofDays)+" days ago ")])]):(_vm.hotelsAndStaycations.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-lg-6"},[(_vm.isHotelsAndStaycations)?_c('article',{staticClass:"mb-5",attrs:{"id":"post-4"}},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.$t('Home.HOTELS&STAYCATIONS')))])]),_c('div',{staticClass:"pl-5 pr-5"},[(_vm.hotelsAndStaycations.videoOrImage != '' && _vm.hotelsAndStaycations.videoOrImage != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":_vm.url + _vm.hotelsAndStaycations.videoOrImage}})])])]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
              name: 'PostDetail',
              params: { id: _vm.hotelsAndStaycations.id }
            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(_vm.hotelsAndStaycations.youtubeLink != '' && _vm.hotelsAndStaycations.youtubeLink != null)?_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + _vm.sportsAndLeisure.youtubeLink.substring(30, _vm.sportsAndLeisure.youtubeLink.length) + '/0.jpg'}})])])]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"data-toggle":"modal","data-target":"#videoModal","title":_vm.hotelsAndStaycations.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(_vm.hotelsAndStaycations.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_c('div',{staticClass:"post-thumbnail mt-4"},[_vm._m(7),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
              name: 'PostDetail',
              params: { id: _vm.hotelsAndStaycations.id }
            }}},[_c('div',{staticClass:"post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]),_c('div',{staticClass:"post-content-container"},[_c('div',{staticClass:"post-categories-container text-center mt-1"},[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
              name: 'CategoryList',
              params: { id: _vm.hotelsAndStaycations.categoryId }
            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.category)+" ")])])],1),_c('router-link',{staticClass:"post-title text-capitalize",attrs:{"to":{
            name: 'PostDetail',
            params: { id: _vm.hotelsAndStaycations.id }
          }}},[_c('h2',[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.urduDescriptionTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.hotelsAndStaycations.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                  name: 'CategoryList',
                  params: { id: _vm.hotelsAndStaycations.categoryId }
                }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-meta-content"},[_c('span',{staticClass:"post-auhor-date"},[(_vm.hotelsAndStaycations.numberofDays > 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.numberofDays)+" days ago ")])]):(_vm.hotelsAndStaycations.numberofDays == 1)?_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" "+_vm._s(_vm.hotelsAndStaycations.numberofDays)+" day ago ")])]):_c('span',[_c('i',{staticClass:"post-date"},[_vm._v(" today ")])])])])])],1)])])])]):_vm._e()]):_vm._e()]),(_vm.urdudata == false)?_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('Home.LATESTARTICLES')))])]),_c('div',[_c('div',{key:_vm.postscomponentKey},[_c('div',[_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.latestPosts),function(post,index){return _c('div',{key:post.id,staticClass:"col-lg-6 mt-1 mb-1"},[_c('div',{staticClass:"post-thumbnail blog-mini-post blog-mini-post-large ml-2 mr-2",attrs:{"id":"my-latestPosts"}},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":_vm.url + post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                                name: 'PostDetail',
                                params: { id: post.id }
                              }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModal","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(8,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{
                                name: 'PostDetail',
                                params: { id: post.id }
                              }}},[_c('h4',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(post.categoryTitle)+" ")])]),_c('router-link',{attrs:{"to":{
                                name: 'CategoryList',
                                params: { id: post.categoryId }
                              }}},[_c('h5',{staticClass:"post-title font-italic"},[_vm._v(" "+_vm._s(post.category)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                                      name: 'CategoryList',
                                      params: { id: post.categoryId }
                                    }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])])],1)])])])}),0)]),_c('div',{staticClass:"mt-4"},[_c('b-pagination',{staticClass:"custom-b-pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.pageSIZE,"aria-controls":"my-latestPosts"},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-2 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.$t('Home.LATESTARTICLES')))])]),_c('div',[_c('div',{key:_vm.postscomponentKey},[_c('div',[_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.latestPosts),function(post,index){return _c('div',{key:post.id,staticClass:"col-lg-6 mt-1 mb-1"},[_c('div',{staticClass:"post-thumbnail blog-mini-post blog-mini-post-large ml-2 mr-2",attrs:{"id":"my-latestPosts"}},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":_vm.url + post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                  name: 'PostDetail',
                  params: { id: post.id }
                }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModal","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(9,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{
                  name: 'PostDetail',
                  params: { id: post.id }
                }}},[_c('h4',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(post.urduDescriptionTitle)+" ")])]),_c('router-link',{attrs:{"to":{
                  name: 'CategoryList',
                  params: { id: post.categoryId }
                }}},[_c('h5',{staticClass:"post-title font-italic"},[_vm._v(" "+_vm._s(post.category)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{
                        name: 'CategoryList',
                        params: { id: post.categoryId }
                      }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])])],1)])])])}),0)]),_c('div',{staticClass:"mt-4"},[_c('b-pagination',{staticClass:"custom-b-pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.pageSIZE,"aria-controls":"my-latestPosts"},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])]):_vm._e()])]),(_vm.urdudata == false)?_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"ml-1 mr-1"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h2',{staticClass:"widget-title"},[_vm._v(_vm._s(_vm.$t('Home.FEATUREDPOSTS')))])]),_c('div',{staticClass:"border-bottom",staticStyle:{"max-height":"630px","overflow":"auto"}},_vm._l((_vm.featuredPosts),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-large"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":_vm.url + post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModal","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(10,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{
                        name: 'CategoryList',
                        params: { id: post.categoryId }
                      }}},[_c('h4',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(post.categoryTitle)+" ")])]),_c('h5',{staticClass:"post-title font-italic"},[_vm._v(" "+_vm._s(post.category)+" ")])],1)])])}),0)]),_c('div',[_c('div',{staticClass:"w-100"},[(_vm.HomeRightSideAdd != [])?_c('div',{staticClass:"w-100 text-center"},[_c('a',{staticClass:"navbar-brand mr-0",attrs:{"href":_vm.HomeRightSideAdd[0].url,"title":_vm.HomeRightSideAdd[0].url,"target":"_blank"}},[_c('img',{staticClass:"addimagestyling",attrs:{"src":_vm.url + _vm.HomeRightSideAdd[0].image}})])]):_c('div',{staticClass:"w-100 h-100 text-center"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/images/large-ad.jpg")}})])])])])]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"ml-1 mr-1"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h2',{staticClass:"widget-title",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.$t('Home.FEATUREDPOSTS')))])]),_c('div',{staticClass:"border-bottom",staticStyle:{"max-height":"630px","overflow":"auto"}},_vm._l((_vm.featuredPosts),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-large"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":_vm.url + post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModal","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrc(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(11,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{
                        name: 'CategoryList',
                        params: { id: post.categoryId }
                      }}},[_c('h4',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(post.urduDescriptionTitle)+" ")])]),_c('h5',{staticClass:"post-title font-italic"},[_vm._v(" "+_vm._s(post.category)+" ")])],1)])])}),0)]),_c('div',[_c('div',{staticClass:"w-100"},[(_vm.HomeRightSideAdd != [])?_c('div',{staticClass:"w-100 text-center"},[_c('a',{staticClass:"navbar-brand mr-0",attrs:{"href":_vm.HomeRightSideAdd[0].url,"title":_vm.HomeRightSideAdd[0].url,"target":"_blank"}},[_c('img',{staticClass:"addimagestyling",attrs:{"src":_vm.url + _vm.HomeRightSideAdd[0].image}})])]):_c('div',{staticClass:"w-100 h-100 text-center"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/images/large-ad.jpg")}})])])])])]):_vm._e()])]),_c('div',{staticClass:"modal fade",attrs:{"id":"videoModal","tabindex":"-1","role":"dialog","aria-labelledby":"videoModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('button',{staticClass:"close custom-close-btn",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[(_vm.isVideoAvailable)?_c('iframe',{staticClass:"embed-responsive-item",attrs:{"width":"1280","height":"720","src":_vm.videoUrl,"frameborder":"0","allowfullscreen":""}}):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image"},[_c('img',{attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
}]

export { render, staticRenderFns }