import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import "./plugins/custom";
import App from './App.vue'
import VueRouter from "vue-router"
import router from './router'
import store from './store'
// import i18n from '../src/plugins/i18n'

import axios from "axios";
import VueAxios from "vue-axios";
import "es6-promise/auto";
import VueCarousel from 'vue-carousel';

import moment from "moment";
import i18n from './i18n'


Vue.config.productionTip = false

Vue.filter('formatDateAndTime', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY')
  }
});
// router.beforeEach((to, from, next) => {

//   // use the language from the routing param or default language
//   let language = to.params.lang;
//   if (!language) {
//     language = 'en'
//   }

//   // set the current language for i18n.
//   i18n.locale = language
//   next()
// });



Vue.router = router;
Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(VueAxios, axios);

//axios.defaults.baseURL = "http://localhost:57589";
axios.defaults.baseURL = "https://smw-live.azurewebsites.net";
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
