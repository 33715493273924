<template>
  <div id="main-container" :key="componentKey">





    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">
        <div class="col-lg-9">

          <div class="log-lg-12" style="margin-left: 3%; margin-top: 5%;">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">{{ $t('orderform.FirstName') }}<span style="color:red">*</span></label>
                  <input type="text" class="form-control" v-model="model.Firstname" id="inputFirstName"
                     required>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Last Name<span style="color:red">*</span></label>
                  <input type="text" v-model="model.Lastname" class="form-control" id="inputLastName"
                     required>
                </div>
              </div>
              <div class="form-group">
                <label for="inputAddress">Email<span style="color:red">*</span></label>
                <input type="email" v-model="model.email" class="form-control" id="inputAddress"
                  placeholder="Email@gmail.com" required>
              </div>
              <div class="form-group">
                <label for="inputAddress2">Phone Number<span style="color:red">*</span></label>
                <input type="phonenumber" v-model="model.phoneNumber" class="form-control" id="inputAddress2"
                  placeholder="(+92) 320 0000 000" required>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputCity">City<span style="color:red">*</span></label>
                  <input type="text" class="form-control" v-model="model.city" id="inputCity" placeholder="City" required>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputCountry">Country<span style="color:red">*</span></label>
                  <select id="inputCountry" v-model="model.country" class="form-control" required>
                    <option selected>Choose...</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="UnitedStates">United States</option>
                    <option value="SaudiArabia">Saudi Arabia</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Germany">Germany</option>
                    <option value="Canada">Canada</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Japan">Japan</option>
                    <option value="Australia">Australia</option>
                    <option value="UnitedKingdom">United Kingdom</option>
                    <option value="France">France</option>
                    <option value="China">China</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Qatar">Qatar</option>
                    <option value="India">India</option>
                    <option value="Poland">Poland</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Egypt">Egypt</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Russia">Russia</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="UnitedArabEmirates(UAE)">United Arab Emirates (UAE)</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputZip">Package<span style="color:red">*</span></label>
                  <select v-model="model.selectedPackage" class="form-control custom-select-option" required>
                    <option value="0">Select Package</option>
                    <option v-for="option in Types" :key="option.value" :value="option.value">
                      {{ option.text }}
                    </option>
                  </select>

                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputEmail4">Note</label>
                  <textarea type="text" v-model="model.Note" class="form-control" id="inputNote"
                    placeholder="Enter a message (optional)"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <!--  Load more    -->
                <div>
                  <div class=" text-center">
                    <button @click="Submit" type="submit" style="margin-top: 1%;"
                      class="btn btn-outline-secondary btn-theme">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12" >

  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span @click="closeModal" aria-hidden="true">&times;</span>
        </button>
      </div>
<!-- "orderform":{
   "FirstName":"First Name",
   "LastName":"Last Name",
   "Email":"Email",
   "PhoneNumber":"Phone Number",
   "City":"City",
   "Country":"Country",
   "Package":"Package",
   "Note":"Note",
   "Submit":"Submit"
 } -->
      <div class="modal-body">
        Thank You! Your response is submitted successfully Please click on Proceed to generate invoice. 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-theme" @click="closeModal" data-dismiss="modal">Close</button>
        <router-link :to="{
                      name: 'PackageInvoice',
                      params: { id: postid }
                    }" class="btn btn-outline-secondary btn-theme"
                    >

                    Proceed
                  </router-link>
      </div>
    </div>
  </div>
</div>
                <!--  Load more  style="margin-left: 49rem;margin-top: -5rem;"  -->
                <div>
                

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

// optional style for arrows & dots
import Vue from 'vue'
import axios from 'axios'
import { UsersIcon } from 'vue-feather-icons'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);
export default {
  name: 'PostDetail',
  components: {
    UsersIcon
  },
  data() {
    return {
      jobUrl: this.$route.fullPath,
      domainUrl: "https://harshehar.com",

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: [],
      PageOfItems: [],
      info: [],
      option: 0,

      isVideoAvailable: false,
      //Video Source
      videoUrl: "",
      //posts

      postList: [],
      featuredPosts: [],

      model: {

        Firstname: "",
        Lastname: "",
        email: "",
        phoneNumber: "",
        city: "",
        country: "",
        selectedPackage: 0,
        Note: "",

      },
      invoice: false,
      postid: 0,
      Types: [
        { value: 1, text: "Basic - PKR 9,999/-" },
        { value: 2, text: "LITE - PKR 2,500/-" },
        { value: 3, text: "STANDARD - PKR 15,000/-" },
        { value: 4, text: "PREMIUM - PKR 25,000/-" },
        { value: 5, text: "PRO - PKR 35,000/-" }
      ],
      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    Submit() {
      // this.rerender()

      this.list = [];
      let req = {
        ID: 0,
        FirstName: this.model.Firstname,
        LastName: this.model.Lastname,
        Email: this.model.email,
        Phoneno: this.model.phoneNumber,
        City: this.model.city,
        Country: this.model.country,
        Package: parseInt(this.model.selectedPackage),
        Note: this.model.Note,
        price: 0
      };
      console.log(req);
      debugger
      axios.post('/api/Shehri/PackageOrder', req)
        .then(res => {
          this.model.Firstname = "";
          this.model.Lastname = "";
          this.model.email = "";
          this.model.phoneNumber = "";
          this.model.city = "";
          this.model.country = "";
          this.model.selectedPackage = 0;
          this.model.Note = "";
          this.invoice = true;
          this.postid = res.data.data;
          debugger
          this.showModal();
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
          }
        })
    },
    showModal() {
      // let element = this.$refs.modal.$el
      debugger
      // Get the modal element
var modal = document.getElementById("myModal");

// Show the modal by adding the "show" class to its HTML element
modal.classList.add("show");

// Add the "display: block" style to the modal's HTML element to make it visible
modal.style.display = "block";
    },
    getPostByCategory(id) {
      this.postList = []
      console.log("category id : ", id)
      let model = {
        categoryid: id
      }

      axios
        .post('/api/Shehri/GetPost', model)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('category Post List : ', list)
          this.postList = list.slice(-4).reverse();


          for (let post in this.postList) {

            if (this.postList[post].postType === "FeaturedPost") {
              this.featuredPosts.push(this.postList[post]);
            }

          }


          console.log('this.featuredPosts : ', this.featuredPosts)


        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')

          } else {

          }
        })
    },

    getVideoSrc(vSrc) {
      this.videoUrl = vSrc;
      console.log("videoUrl : ", this.videoUrl)

      this.isVideoAvailable = true;
    },

    closeModal() {
     // Get the modal element
var modal = document.getElementById("myModal");

// Hide the modal by removing the "show" class from its HTML element
modal.classList.remove("show");

// Set the "display" style property to "none" to hide the modal's HTML element
modal.style.display = "none";
    },

    //Rerender Component
    rerender() {
      this.componentKey += 1
    },
  },
  beforeMount() {
    this.model.selectedPackage = this.$route.params.id;
    console.log("selectedpackage" + this.model.selectedPackage);
  },
  mounted() {

  }
}
</script>
  