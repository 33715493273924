var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,attrs:{"id":"main-container"}},[_c('div',{staticClass:"custom-row-element-wrap mt-5 mb-5"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-sm-12"},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t('Packages.Packages')))]),_c('section',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"fa fa-paper-plane",attrs:{"aria-hidden":"true"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('Packages.Basic.Basic'))+" ")])]),_vm._m(0),_c('div',{staticClass:"option",staticStyle:{"margin-bottom":"18.5rem","margin-top":"2rem"}},[_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.Basic.1Articlewill')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.Basic.FreeAdvertisement')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.Basic.ListingofBusiness')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.Basic.FreeUse')))])])]),_c('small',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.$t('Packages.Basic.OneMonth')))]),_c('router-link',{attrs:{"to":{
                          name: 'orderform',
                          params: { id: 1 }
                        }}},[_vm._v(_vm._s(_vm.$t('Packages.Pay')))])],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"fa fa-plane",attrs:{"aria-hidden":"true"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('Packages.LITE.LITE')))])]),_vm._m(1),_c('div',{staticClass:"option",staticStyle:{"margin-bottom":"20rem","margin-top":"2rem"}},[_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.LITE.2Articleswill')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.LITE.FreeAdvertisement')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.LITE.ListingofBusiness')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.LITE.FreeUse')))])])]),_c('small',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.$t('Packages.LITE.PerMonth')))]),_c('router-link',{attrs:{"to":{
                          name: 'orderform',
                          params: { id: 2 }
                        }}},[_vm._v(_vm._s(_vm.$t('Packages.Pay'))+"Pay")])],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"fa fa-rocket",attrs:{"aria-hidden":"true"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('Packages.STANDARD.STANDARD')))])]),_vm._m(2),_c('div',{staticClass:"option",staticStyle:{"margin-bottom":"9rem","margin-top":"2rem"}},[_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.STANDARD.4Articles')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.STANDARD.FreeAdvertisement')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.STANDARD.ListingofBusiness')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.STANDARD.AllupcomingEvents')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.STANDARD.FreeUse')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.STANDARD.Coursecanbe')))])])]),_c('small',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.$t('Packages.STANDARD.PerMonth')))]),_c('router-link',{attrs:{"to":{
                          name: 'orderform',
                          params: { id: 3 }
                        }}},[_vm._v(_vm._s(_vm.$t('Packages.Pay')))])],1)])]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"5rem"}},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"fa fa-plane",attrs:{"aria-hidden":"true"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.PREMIUM')))])]),_vm._m(3),_c('div',{staticClass:"option",staticStyle:{"margin-bottom":"5.5rem"}},[_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.6Articleswill')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.2FreeAdvertisement')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.ListingofBusiness')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.AllupcomingEvents')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.FreeUse')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.Coursescan')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.YoutubeInterview')))])])]),_c('small',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.$t('Packages.PREMIUM.PerMonth')))]),_c('router-link',{attrs:{"to":{
                          name: 'orderform',
                          params: { id: 4 }
                        }}},[_vm._v(_vm._s(_vm.$t('Packages.Pay')))])],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"fa fa-paper-plane",attrs:{"aria-hidden":"true"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('Packages.PRO.PRO')))])]),_vm._m(4),_c('div',{staticClass:"option"},[_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.6Articles')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.2FreeAdvertisement')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.ListingofBusiness')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.AllupcomingEvents')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.FreeUse')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.Coursescan')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.YoutubeInterview')))]),_c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('Packages.PRO.CompanyLogoandName')))])])]),_c('small',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.$t('Packages.PRO.Permonth')))]),_c('router-link',{attrs:{"to":{
                          name: 'orderform',
                          params: { id: 5 }
                        }}},[_vm._v(_vm._s(_vm.$t('Packages.Pay')))])],1)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price",staticStyle:{"margin-top":"10rem"}},[_c('h3',{staticClass:"lftorder"},[_c('del',[_vm._v("PKR 15,000")])]),_c('h2',{staticClass:"lftorder",staticStyle:{"font-weight":"800"}},[_vm._v("PKR 9,999/- ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price",staticStyle:{"margin-top":"10rem"}},[_c('h3',{staticClass:"lftorder"},[_c('del',[_vm._v("PKR 4,000")])]),_c('h2',{staticClass:"lftorder",staticStyle:{"font-weight":"800"}},[_vm._v("PKR 2,500/- ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price",staticStyle:{"margin-top":"10rem"}},[_c('h3',{staticClass:"lftorder"},[_c('del',[_vm._v("PKR 25,000")])]),_c('h2',{staticClass:"lftorder",staticStyle:{"font-weight":"800"}},[_vm._v("PKR 15,000/- ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price",staticStyle:{"margin-top":"10rem"}},[_c('h3',{staticClass:"lftorder"},[_c('del',[_vm._v("PKR 35,000")])]),_c('h2',{staticClass:"lftorder",staticStyle:{"font-weight":"800"}},[_vm._v("PKR 25,000/- ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price",staticStyle:{"margin-top":"10rem"}},[_c('h3',{staticClass:"lftorder"},[_c('del',[_vm._v("PKR 45,000")])]),_c('h2',{staticClass:"lftorder",staticStyle:{"font-weight":"800"}},[_vm._v("PKR 35,000/- ")])])
}]

export { render, staticRenderFns }