var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"style-mustard-bg"},[_c('div',{staticClass:"container-fluid style-mustard-bg style-brand-spaces custom-bottom-border"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.urdudata == false)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ml-1 mr-1"},[_vm._m(0),_vm._l((_vm.news),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-small"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":_vm.url+post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModalInFooter","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrcInFooter(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(1,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }}},[_c('h4',{staticClass:"footer text-capitalize custom-footer-post-title"},[_vm._v(" "+_vm._s(post.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link post-categories-footer-link",attrs:{"to":{
                                      name: 'PostDetail',
                                      params: { id: post.id }
                                    }}},[_c('span',[_vm._v(" Read More ")])])],1)])])],1)])])}),_c('router-link',{staticClass:"btn btn-danger",staticStyle:{"margin":"0rem 0 0 12rem"},attrs:{"to":{
                          name: 'Archive',
                          params: { id: 1 }
                        }}},[_vm._v(" Archive ")])],2)]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ml-1 mr-1"},[_c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h3',{staticClass:"footer widget-title"},[_vm._v(" "+_vm._s(_vm.$t('footer.NewsoftheDay')))])]),_vm._l((_vm.news),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-small"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":_vm.url+post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModalInFooter","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrcInFooter(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(2,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }}},[_c('h4',{staticClass:"footer text-capitalize custom-footer-post-title"},[_vm._v(" "+_vm._s(post.urduDescriptionTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link post-categories-footer-link",attrs:{"to":{
                                      name: 'PostDetail',
                                      params: { id: post.id }
                                    }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])])],1)])])}),_c('router-link',{staticClass:"btn btn-danger",staticStyle:{"margin":"0rem 0 0 12rem"},attrs:{"to":{
                          name: 'Archive',
                          params: { id: 1 }
                        }}},[_vm._v(" Archive ")])],2)]):_vm._e(),(_vm.urdudata == false)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ml-1 mr-1"},[_vm._m(3),_vm._l((_vm.featuredStories),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-small"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":_vm.url+post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModalInFooter","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrcInFooter(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(4,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('h4',{staticClass:"footer text-capitalize custom-footer-post-title"},[_vm._v(" "+_vm._s(post.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link post-categories-footer-link",attrs:{"to":{ name: 'PostDetail',
                                    params: { id: post.id }
                                    }}},[_c('span',[_vm._v(" Read More ")])])],1)])])],1)])])}),_c('router-link',{staticClass:"btn btn-danger",staticStyle:{"margin":"10rem 0 0 12rem"},attrs:{"to":{
                          name: 'Archive',
                          params: { id: 2 }
                        }}},[_vm._v(" Archive ")])],2)]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ml-1 mr-1"},[_c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h3',{staticClass:"footer widget-title"},[_vm._v(_vm._s(_vm.$t('footer.FeaturedStories')))])]),_vm._l((_vm.featuredStories),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-small"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":_vm.url+post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModalInFooter","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrcInFooter(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(5,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('h4',{staticClass:"footer text-capitalize custom-footer-post-title"},[_vm._v(" "+_vm._s(post.urduDescriptionTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link post-categories-footer-link",attrs:{"to":{ name: 'PostDetail',
                                    params: { id: post.id }
                                    }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])])],1)])])}),_c('router-link',{staticClass:"btn btn-danger",staticStyle:{"margin":"10rem 0 0 12rem"},attrs:{"to":{
                          name: 'Archive',
                          params: { id: 2 }
                        }}},[_vm._v(" Archive ")])],2)]):_vm._e(),(_vm.urdudata == false)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ml-1 mr-1"},[_vm._m(6),_vm._l((_vm.articlesAndBlogs),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-small"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":_vm.url+post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModalInFooter","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrcInFooter(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(7,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('h4',{staticClass:"footer text-capitalize custom-footer-post-title"},[_vm._v(" "+_vm._s(post.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link post-categories-footer-link",attrs:{"to":{ name: 'PostDetail',
                                  params: { id: post.id }
                                  }}},[_c('span',[_vm._v(" Read More ")])])],1)])])],1)])])}),_c('router-link',{staticClass:"btn btn-danger",staticStyle:{"margin":"1rem 0 0 12rem"},attrs:{"to":{
                          name: 'Archive',
                          params: { id: 3 }
                        }}},[_vm._v(" Archive ")])],2)]):_vm._e(),(_vm.urdudata == true)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"ml-1 mr-1"},[_c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h3',{staticClass:"footer widget-title"},[_vm._v(_vm._s(_vm.$t('footer.Articles&Blogs')))])]),_vm._l((_vm.articlesAndBlogs),function(post,index){return _c('div',{key:post.id,staticClass:"post-thumbnail blog-mini-post blog-mini-post-small"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":_vm.url+post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1)]):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModalInFooter","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrcInFooter(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])])]):_c('div',[_vm._m(8,true)]),_c('div',{staticClass:"blog-mini-post-content"},[_c('div',{staticClass:"blog-mini-post-content-container"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{ name: 'PostDetail',
                              params: { id: post.id }
                            }}},[_c('h4',{staticClass:"footer text-capitalize custom-footer-post-title"},[_vm._v(" "+_vm._s(post.urduDescriptionTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.urduShortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link post-categories-footer-link",attrs:{"to":{ name: 'PostDetail',
                                  params: { id: post.id }
                                  }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Home.ReadMore'))+" ")])])],1)])])],1)])])}),_c('router-link',{staticClass:"btn btn-danger",staticStyle:{"margin":"1rem 0 0 12rem"},attrs:{"to":{
                          name: 'Archive',
                          params: { id: 3 }
                        }}},[_vm._v(" Archive ")])],2)]):_vm._e()])])]),_c('div',{staticClass:"container-fluid style-black-bg style-brand-spaces"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row style-light-grey"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"style-right-reserved"},[_c('p',[_vm._v("© "+_vm._s(new Date().getFullYear())+". All Rights Reserved.")])])]),_c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"style-up-icon text-lg-center"},[_c('a',{staticClass:"add-fix-top active",attrs:{"href":"javascript:void(0)","id":"back-to-top"},on:{"click":function($event){return _vm.scrollTo()}}},[_c('i',{staticClass:"fa fa-angle-up",attrs:{"aria-hidden":"true"}})])])]),_vm._m(9)])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"videoModalInFooter","tabindex":"-1","role":"dialog","aria-labelledby":"videoModalInFooterLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('button',{staticClass:"close custom-close-btn",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[(_vm.isVideoAvailableInFooter)?_c('iframe',{staticClass:"embed-responsive-item",attrs:{"width":"1280","height":"720","src":_vm.videoUrlInFooter,"allowfullscreen":""}}):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h3',{staticClass:"footer widget-title"},[_vm._v("News of the Day")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h3',{staticClass:"footer widget-title"},[_vm._v("Featured Stories")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-widget mt-2 mb-2"},[_c('h3',{staticClass:"footer widget-title"},[_vm._v("Articles & Blogs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticClass:"custom-mini-post-small-image",attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"social text-lg-right"},[_c('span',{staticClass:"style-social-icons"},[_c('a',{staticClass:"facebook",attrs:{"href":"https://www.facebook.com/Harshehar.Offical/","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook"})])]),_c('span',{staticClass:"style-social-icons"},[_c('a',{staticClass:"twitter",attrs:{"href":"https://twitter.com/har_shehar","target":"_blank"}},[_c('i',{staticClass:"fab fa-twitter"})])]),_c('span',{staticClass:"style-social-icons"},[_c('a',{staticClass:"youtube",attrs:{"href":"https://www.youtube.com/channel/UCetNHbRcnPDyPdPlKwhGPjw","target":"_blank"}},[_c('i',{staticClass:"fab fa-youtube"})])]),_c('span',{staticClass:"style-social-icons"},[_c('a',{staticClass:"linkedin",attrs:{"href":"https://www.linkedin.com/company/har-shehar","target":"_blank"}},[_c('i',{staticClass:"fab fa-linkedin"})])]),_c('span',{staticClass:"style-social-icons"},[_c('a',{staticClass:"instagram",attrs:{"href":"https://www.instagram.com/harshehar.offical/","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])])])])
}]

export { render, staticRenderFns }