
<template>
    <div id="main-container" :key="componentKey">

        <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">
      <div class="col-sm-12">
        <h1 style="text-align: center;">{{$t('Packages.Packages')}}</h1>
        <section>
    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <div class="card text-center">
              <div class="title">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                <h2>{{$t('Packages.Basic.Basic')}} </h2>
              </div>
              <div class="price" style="margin-top: 10rem;">
               <h3 class="lftorder"><del>PKR 15,000</del></h3>
                <h2 class="lftorder" style="font-weight: 800;">PKR 9,999/- </h2>
              </div>
              <div class="option" style="margin-bottom: 18.5rem; margin-top: 2rem;">
                <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.Basic.1Articlewill')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.Basic.FreeAdvertisement')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.Basic.ListingofBusiness')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.Basic.FreeUse')}}</li>
                </ul>
              </div>
              <small style="color: white;">{{$t('Packages.Basic.OneMonth')}}</small>
              <router-link :to="{
                          name: 'orderform',
                          params: { id: 1 }
                        }"
              
              >{{$t('Packages.Pay')}}</router-link>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card text-center">
              <div class="title">
                <i class="fa fa-plane" aria-hidden="true"></i>
                <h2>{{$t('Packages.LITE.LITE')}}</h2>
              </div>
              <div class="price" style="margin-top: 10rem;">
                <h3 class="lftorder"><del>PKR 4,000</del></h3>
                <h2 class="lftorder" style="font-weight: 800;">PKR 2,500/-  </h2>
              </div>
              <div class="option" style="margin-bottom: 20rem; margin-top: 2rem;" >
                <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.LITE.2Articleswill')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.LITE.FreeAdvertisement')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.LITE.ListingofBusiness')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.LITE.FreeUse')}}</li>
                  
                </ul>
              </div>
              <small style="color: white;">{{$t('Packages.LITE.PerMonth')}}</small>
              <router-link :to="{
                          name: 'orderform',
                          params: { id: 2 }
                        }"
              
              >{{$t('Packages.Pay')}}Pay</router-link>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card text-center">
              <div class="title">
                <i class="fa fa-rocket" aria-hidden="true"></i>
                <h2>{{$t('Packages.STANDARD.STANDARD')}}</h2>
              </div>
              <div class="price" style="margin-top: 10rem;">
                <h3 class="lftorder" ><del>PKR 25,000</del></h3>
                <h2 class="lftorder" style="font-weight: 800;">PKR 15,000/-  </h2>
              </div>
              <div class="option" style="margin-bottom: 9rem; margin-top: 2rem;">
                <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.STANDARD.4Articles')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.STANDARD.FreeAdvertisement')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.STANDARD.ListingofBusiness')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.STANDARD.AllupcomingEvents')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.STANDARD.FreeUse')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.STANDARD.Coursecanbe')}}</li>
                </ul>
              </div>
              <small style="color: white;">{{$t('Packages.STANDARD.PerMonth')}}</small>
              <router-link :to="{
                          name: 'orderform',
                          params: { id: 3 }
                        }"
              
              >{{$t('Packages.Pay')}}</router-link>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top:5rem;">
            <div class="col-sm-4">
            <div class="card text-center">
              <div class="title">
                <i class="fa fa-plane" aria-hidden="true"></i>
                <h2>{{$t('Packages.PREMIUM.PREMIUM')}}</h2>
              </div>
              <div class="price" style="margin-top: 10rem;">
                <h3 class="lftorder"><del>PKR 35,000</del></h3>
                <h2 class="lftorder" style="font-weight: 800;">PKR 25,000/-  </h2>
              </div>
              <div class="option" style="margin-bottom: 5.5rem;">
                <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PREMIUM.6Articleswill')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PREMIUM.2FreeAdvertisement')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PREMIUM.ListingofBusiness')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PREMIUM.AllupcomingEvents')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PREMIUM.FreeUse')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PREMIUM.Coursescan')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PREMIUM.YoutubeInterview')}}</li>
                </ul>
              </div>
              <small style="color: white;">{{$t('Packages.PREMIUM.PerMonth')}}</small>
              <router-link :to="{
                          name: 'orderform',
                          params: { id: 4 }
                        }"
              
              >{{$t('Packages.Pay')}}</router-link>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card text-center">
              <div class="title">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                <h2>{{$t('Packages.PRO.PRO')}}</h2>
              </div>
              <div class="price" style="margin-top: 10rem;">
                
                <h3 class="lftorder"><del>PKR 45,000</del></h3>
                <h2 class="lftorder" style="font-weight: 800;">PKR 35,000/- </h2>  
            </div>
              <div class="option" >
                <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.6Articles')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.2FreeAdvertisement')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.ListingofBusiness')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.AllupcomingEvents')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.FreeUse')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.Coursescan')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.YoutubeInterview')}}</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>{{$t('Packages.PRO.CompanyLogoandName')}}</li>
                </ul>
              </div>
              <small style="color: white;">{{$t('Packages.PRO.Permonth')}}</small>

              <router-link :to="{
                          name: 'orderform',
                          params: { id: 5 }
                        }"
              
              >{{$t('Packages.Pay')}}</router-link>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>
</div>
</div>
</div>
    </div>
  </template>
  
  <script>
  
  // optional style for arrows & dots
  import Vue from 'vue'
  import axios from 'axios'
  import { UsersIcon } from 'vue-feather-icons'
  import VueSocialSharing from 'vue-social-sharing'
  
  
  // import { RlCarouselSlide } from 'vue-renderless-carousel'
  Vue.use(VueSocialSharing);
  export default {
    name: 'PostDetail',
    components: {
      // SlidesComponent,
      // RlCarouselSlide
      UsersIcon
    },
    data () {
      return {
        jobUrl:this.$route.fullPath,
        domainUrl:"https://harshehar.com",
  
        url: axios.defaults.baseURL,
        currentPage: 1,
        total: 1,
  
        list: [],
        PageOfItems: [],
        info: [],
        option: 0,
  
        isVideoAvailable:false,
        //Video Source
        videoUrl:"",
        //posts
  
        postList:[],
        featuredPosts:[],
  
  
  
        loading: true,
        errored: false,
  
  
        componentKey: 0,
      }
    },
    //carousel_latest_jobs
    methods: {
      getAll() {
        this.rerender()
  
        this.list = [];
        console.log("id : ", this.$route.params.id)
  
        axios.get('/api/Shehri/Detail?id='+this.$route.params.id)
          .then(res => {
            this.list = res.data.data
            /*let list = [];
            list = res.data.data
  
            console.log('Add Response portion')
  
            console.log('list : ', list)
            this.list = list.slice().reverse();*/
  
  
            console.log('article Detail : ', this.list)
  
            this.getPostByCategory (this.list.categoryid);
            //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
  
  
  
  
          }).catch(error => {
          console.log(error)
          this.errored = true
        })
          .finally(() => {
            if (this.errored) {
  
              console.log('Error : Sorry there is no response')
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",
  
              //   solid: true,
              // });
            }
          })
      },
      getPostByCategory (id) {
        this.postList = []
        console.log("category id : ", id)
        let model = {
          categoryid: id
        }
  
        axios
          .post('/api/Shehri/GetPost', model)
          .then(res => {
            // code that we will 'try' to run
            let list = [];
            list = res.data.data
  
            console.log('Add Response portion')
  
            console.log('category Post List : ', list)
            this.postList = list.slice(-4).reverse();
  
  
            for (let post in this.postList){
  
              if(this.postList[post].postType === "FeaturedPost"){
                this.featuredPosts.push(this.postList[post]);
              }
  
            }
  
  
            console.log('this.featuredPosts : ', this.featuredPosts)
  
  
  
           /* console.log('healthAndBeauty : ', this.postList)
  
            if(this.postList.length != 0){
              this.isHealthAndBeauty = true;
  
              let sl = {
                categoryId: this.postList[0].categoryId,
                category: this.postList[0].category,
                categoryDescription: this.postList[0].categoryDescription,
                categoryTitle: this.postList[0].categoryTitle,
                id: this.postList[0].id,
                descriptionTitle: this.postList[0].descriptionTitle,
                numberofDays: this.postList[0].numberofDays,
                type: this.postList[0].type,
                videoOrImage: this.postList[0].videoOrImage
              }
              console.log("healthAndBeauty obj",sl)
              this.healthAndBeauty = sl;
            }*/
          })
          .catch(error => {
            console.log('Errorrrrrr : ', error)
            this.errored = true
          })
          .finally(() => {
            if (this.errored) {
  
              console.log('Error : Sorry there is no response')
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",
  
              //   solid: true,
              // });
            } else {
  
            }
          })
      },
  
      getVideoSrc(vSrc){
  
        // var video = document.getElementById("videoId");
        this.videoUrl = vSrc;
        console.log("videoUrl : ", this.videoUrl)
        /*video.src = this.videoUrl;
        video.play();*/
        this.isVideoAvailable=true;
      },
  
      closeModal(id){
        this.isVideoAvailable=false;
        /*var video = document.getElementById(id);
        video.pause();*/
        /*$('#videoModal').on('hidden.bs.modal', function (e) {
          $('#videoModal video').pause()
        })*/
      },
  
      //Rerender Component
      rerender () {
        this.componentKey += 1
      },
    },
  
    mounted () {
      this.getAll();
  
    }
  }
  </script>
  <style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto:300');



section {
    width: 100%;
   
    box-sizing: border-box;
    padding: 50px 0;
    
}
.lftorder{
  direction: ltr;
}
.card {
    position: relative;
    max-width: 300px;
    height: auto;
    background: linear-gradient(-45deg, #fe0847, #64b5f6);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin: 0 auto;
    padding: 40px 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    overflow: hidden;
}

.card:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

/* .col-sm-4:nth-child(1) .card,
.col-sm-4:nth-child(1) .card .title .fa {
    background: linear-gradient(-45deg, #f403d1, #64b5f6);
} */
/* 
.col-sm-4:nth-child(2) .card,
.col-sm-4:nth-child(2) .card .title .fa {
    background: linear-gradient(-45deg, #ffec61, #f321d7);
}

.col-sm-4:nth-child(3) .card,
.col-sm-4:nth-child(3) .card .title .fa {
    background: linear-gradient(-45deg, #24ff72, #9a4eff);
} */

.card:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: rgba(255, 255, 255, .1);
    z-index: 1;
    transform: skewY(-5deg) scale(1.5);
    -webkit-transform: skewY(-5deg) scale(1.5);
    -moz-transform: skewY(-5deg) scale(1.5);
    -ms-transform: skewY(-5deg) scale(1.5);
    -o-transform: skewY(-5deg) scale(1.5);
}

.title .fa {
    color: #fff;
    font-size: 60px;
    width: 100px;
    margin-left: 5rem;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    text-align: center;
    line-height: 100px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
}

.title h2 {
    position: relative;
    margin: 20px 0 0;
    padding: 0;
    margin-left: 5rem;
    color: #fff;
    font-size: 28px;
    z-index: 2;
}

.price {
    position: relative;
    z-index: 2;
}

.price h4 {
    margin: 0;
    padding: 20px 0;
    color: #fff;
    font-size: 20px;
}

.option {
    position: relative;
    z-index: 2;
}

.option ul {
    margin: 0;
    padding: 0;
}

.option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
}

.card a {
    position: relative;
    z-index: 2;
    background: #fff;
    color: #262626;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    display: block;
    text-align: center;
    margin: 20px auto 0;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.card a:hover {
    text-decoration: none;
}
</style>