<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
<!--    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <section class="webSection  custom-landing-banner-section1">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element ">
                  <div class="custom-row-element-wrap ">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index text-center ">
                        <div>
                          <h4><b-badge  class=" pt-1 pb-1 pl-3 pr-3 font-weight-normal" style="background-color: limegreen">Culture</b-badge></h4>
                        </div>
                        <div class="custom-banner-heading">
                          <span>Badshahi Mosque is an important remarkable of Mughal Architecture</span>
                        </div>

                        &lt;!&ndash;                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">

                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                                                </div>&ndash;&gt;
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section2">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                        <div class="custom-banner-heading">
                          <span>Best Place To <span class="custom-text-color">Grow</span></span><br>
                          <span>Your Career</span>
                        </div>
                        <div class="custom-banner-p">
                          <p>Find Jobs, Employment and Career Opportunities</p>
                        </div>
                        &lt;!&ndash;                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2 ">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                                                </div>&ndash;&gt;
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section3">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                        <div class="custom-banner-heading">
                          <span>Lets Start Your Job </span><br>
                          <span>With <span class="custom-text-color">SheMeanWork</span></span>
                        </div>
                        <div class="custom-banner-p">
                          <p>Find Jobs, Employment and Career Opportunities</p>
                        </div>
                        &lt;!&ndash;                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2 ">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>

                                                </div>&ndash;&gt;
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <a class="carousel-control-prev custom-carousel-prev" href="#carouselExampleControls" role="button"
         data-slide="prev">
        <span class="carousel-control-prev-icon " aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next custom-carousel-next" href="#carouselExampleControls" role="button"
         data-slide="next">
        <span class="carousel-control-next-icon " aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>-->

    <!--    <div style="height: 1000px">
          <SlidesComponent>
            <rl-carousel-slide class="w-full">
              <div
                class="border border-blue p-8 mx-8 mt-8 text-center text-3xl"

              >
                <img src="../assets/images/slider11.jpg" width="100%">
              </div>
            </rl-carousel-slide>
            <rl-carousel-slide class="w-full">
              <div
                class="border border-red p-8 mx-8 mt-8 text-center text-3xl"

              >
                <img src="../assets/images/slider12.jpg" width="100%">
              </div>
            </rl-carousel-slide>
            <rl-carousel-slide class="w-full">
              <div
                class="border border-green p-8 mx-8 mt-8 text-center text-3xl"

              >
                <img src="../assets/images/slider13.jpg" width="100%">
              </div>
            </rl-carousel-slide>
          </SlidesComponent>
        </div>-->


    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">

        <div class="col-lg-9" v-if="urdudata == false">

          <div class="post-container">
            <div class="custom-widget mt-2 mb-2 border-bottom ">

              <h2 v-if="$route.params.id != 0 && $route.params.id != undefined" v-for="destination in destinations" :key="destination.key"  class="widget-title text-uppercase">
                <span v-if="$route.params.id == destination.key" class="post-title font-italic">
                  {{ destination.value}}
                </span>
                <span v-else class="post-title font-italic"></span>
              </h2>

              <h2 v-else  class="widget-title text-uppercase">
                <span  class="post-title font-italic">
                  Searched Posts
                </span>

              </h2>

            </div>
            <div>
              <div id="my-Searched-Posts" v-for="(post, index) in jobsList" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-large">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrc(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModal" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">
                    </div>
                  </div>
                </div>
                <!--              <div class="blog-mini-post-thumb sh-post-review-mini">
                                <div>
                                  <img :src="url+'/'+post.videoOrImage" style="width: 108px; height: 75px"/>
                                </div>
                                <router-link
                                  :to="{ name: 'PostDetail',
                                              params: { id: post.id }
                                            }"
                                  class=" post-overlay-small"
                                >

                                  <div class="post-overlay-small"></div>
                                </router-link>
                              </div>-->

                <div class="blog-mini-post-content">
                  <div class="blog-mini-post-content-container">
                    <router-link :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }" class="post-title">
                      <h4 class="text-capitalize">
                        {{ post.categoryTitle }}
                      </h4>
                    </router-link>
                    <h5  class="post-title font-italic">
                      {{ post.category }}
                    </h5>
                    <!--                  <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                                        Beverages & Dining
                                      </h5>
                                      <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                                        Hotels & Staycations
                                      </h5>
                                      <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                                        Sports & Leisure
                                      </h5>
                                      <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                                        Kids & Family
                                      </h5>
                                      <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                                        Flowers & Gardening
                                      </h5>
                                      <span v-else class="post-title font-italic">

                                      </span>-->

                    <div class="post-content">
                      <p class="">
                        <span>{{ post.shortDescription}}</span>

                      </p>
                    </div>
                    <!--                  <div class="post-meta">
                                        <div class="post-meta-content">
                                        <span class="post-auhor-date">
                                          <span>
                                            <a
                                              href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                                              class="post-author">
                                              Gillion
                                            </a>
                                        </span>,
                                        <a
                                          href="https://gillion.shufflehound.com/home/2017/01/31/the-buddha-said-it-is-not-the-number-of-blog-tips-you-offer-but-the-quality/"
                                          class="post-date"> 5 years ago
                                        </a>
                                      </span>
                                          <span class="post-readtime">
                                            <i
                                              class="icon icon-clock">

                                            </i> 1 min
                                            <span>read</span>
                                        </span>
                                        </div>
                                      </div>-->
                  </div>
                </div>
              </div>
              <div class="mt-4 ">
                <b-pagination
                  class="custom-b-pagination"
                  v-model="currentPage"
                  :total-rows="searchedRows"
                  :per-page="pageSIZE"
                  aria-controls="my-Searched-Posts"
                  @change="onChangeSearchedJobs($event)"
                ></b-pagination>
              </div>
            </div>



          </div>
        </div>



        <div class="col-lg-9" v-if="urdudata == true">

<div class="post-container">
  <div class="custom-widget mt-2 mb-2 border-bottom ">

    <h2 v-if="$route.params.id != 0 && $route.params.id != undefined" v-for="destination in destinations1" :key="destination.key"  style="float: right;" class="widget-title text-uppercase">
      <span v-if="$route.params.id == destination.id" class="post-title font-italic">
        {{ destination.Urduvlaue}}
      </span>
      <span v-else class="post-title font-italic"></span>
    </h2>

    <h2 v-else  class="widget-title text-uppercase">
      <span  class="post-title font-italic">
        Searched Posts
      </span>

    </h2>

  </div>
  <div>
    <div id="my-Searched-Posts" v-for="(post, index) in jobsList" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-large">

      <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
        <div class="blog-mini-post-thumb sh-post-review-mini">
          <div class="">

            <img :src="url+post.videoOrImage" class="custom-post-small-image"/>

          </div>
          <router-link
            :to="{ name: 'PostDetail',
                    params: { id: post.id }
                  }"
            class="post-overlay text-decoration-none"
          >

            <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
          </router-link>
        </div>

      </div>
      <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
        <div class="blog-mini-post-thumb sh-post-review-mini">
          <div class="">
            <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-post-small-image">


          </div>
          <a
            href="javascript:void(0)"
            @click="getVideoSrc(post.youtubeLink)"
            data-toggle="modal" data-target="#videoModal" :title="post.youtubeLink"
            class="post-overlay text-decoration-none">
            <div class="post-categories-container custom-play-icon">
              <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                <b-icon icon="play-circle"></b-icon>
              </div>
            </div>
          </a>


        </div>
      </div>
      <div v-else>
        <div class="blog-mini-post-thumb sh-post-review-mini">
          <div class="">
            <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">
          </div>
        </div>
      </div>
      <div class="blog-mini-post-content">
        <div class="blog-mini-post-content-container">
          <router-link :to="{ name: 'PostDetail',
                    params: { id: post.id }
                  }" class="post-title">
            <h4 class="text-capitalize">
              {{ post.urduDescriptionTitle }}
            </h4>
          </router-link>
          <h5  class="post-title font-italic">
            {{ post.category }}
          </h5>
        
          <div class="post-content">
            <p class="">
              <span>{{ post.urduShortDescription}}</span>

            </p>
          </div>
           </div>
      </div>
    </div>
    <div class="mt-4" style="direction: ltr;">
      <b-pagination
        class="custom-b-pagination"
        v-model="currentPage"
        :total-rows="searchedRows"
        :per-page="pageSIZE"
        aria-controls="my-Searched-Posts"
        @change="onChangeSearchedJobs($event)"
      ></b-pagination>
    </div>
  </div>



</div>
</div>







        <div class="col-lg-3" v-if="urdudata == false">
          <div class="ml-1 mr-1">
            <div class="custom-widget mt-2 mb-2">
              <h2 class="widget-title">{{ $t('Home.FEATUREDPOSTS')}}</h2>
            </div>

            <div style="max-height: 630px; overflow: auto">
              <div v-for="(post, index) in featuredPosts" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-large">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrc(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModal" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">


                    </div>



                  </div>
                </div>
              

                <div class="blog-mini-post-content">
                  <div class="blog-mini-post-content-container">

                    <router-link :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }" class="post-title">
                      <h4 class="post-title text-capitalize">
                        {{ post.categoryTitle }}
                      </h4>
                    </router-link>
                    <h5  class="post-title font-italic">
                      {{ post.category }}
                    </h5>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="col-lg-3" v-if="urdudata == true">
          <div class="ml-1 mr-1">
            <div class="custom-widget mt-2 mb-2">
              <h2 style="float:right" class="widget-title">{{ $t('Home.FEATUREDPOSTS')}}</h2>
            </div>

            <div style="max-height: 630px; overflow: auto">
              <div v-for="(post, index) in featuredPosts" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-large">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrc(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModal" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">


                    </div>



                  </div>
                </div>
              

                <div class="blog-mini-post-content">
                  <div class="blog-mini-post-content-container">

                    <router-link :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }" class="post-title">
                      <h4 class="post-title text-capitalize">
                        {{ post.urduDescriptionTitle }}
                      </h4>
                    </router-link>
                    <h5  class="post-title font-italic">
                      {{ post.category }}
                    </h5>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>




    <!-- Video Modal-->




    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" @click="closeModal()" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="embed-responsive embed-responsive-16by9">

            <iframe v-if="isVideoAvailable" class="embed-responsive-item" width="1280" height="720" :src="videoUrl"  allowfullscreen></iframe>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'CategoryList',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {




      slides: [
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
      ],
      settingsForLatestOrFeaturedJobs: {

        infinite: true,
        slidesToShow: 2,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,

              infinite: true

            }
          }
        ]
      },
      settingsForTopEmployers: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 2,

              'infinite': true,

            }
          },
          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 1,

              'infinite': true,

            }
          }
        ]
      },

      settingsForThreeRows: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: false,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 2,

              'infinite': true,

            }
          },
          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 1,

              'infinite': true,

            }
          }
        ]
      },
      settingsForBrands: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 3,

              'infinite': true,

            }
          },
          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 2,

              'infinite': true,

            }
          }
        ]
      },
      settingsForTwoSlides: {
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [

          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 1,

              'infinite': true,

            }
          }
        ]
        /*focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 5,*/
      },

      fullPageslides: [
        {
          title: 'El Teide Volcano, Spain',
          content: 'Photo by Max Rive',
          // You can also provide a URL for the image.
          image: require('@/assets/images/slider11.jpg'),
          link:
            'https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk',
        },
        {
          title: 'El Teide Volcano, Spain',
          content: 'Photo by Max Rive',
          // You can also provide a URL for the image.
          image: require('@/assets/images/slider12.jpg'),
          link:
            'https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk',
        },
        {
          title: 'El Teide Volcano, Spain',
          content: 'Photo by Max Rive',
          // You can also provide a URL for the image.
          image: require('@/assets/images/slider13.jpg'),
          link:
            'https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk',
        },
      ],


      url: axios.defaults.baseURL,


      list: null,
      PageOfItems: [],
      info: [],
      option: 0,
      //Video Source
      isVideoAvailable:false,
      videoUrl:null,
      //posts

      modelFeaturePosts : {
        pageSize: 0,
        pageNumber: 1,
        text: "",
        categoryid: 0
      },


      featuredPosts: [],
      latestPosts: [],
      postType: null,

      //destinations
      destinations:[],
      urdudata:false,


      //pagination
      currentPage: 1,
      pageSIZE: 5,
      totalCount:0,

      //Searched Posts Model
      jobSearchByName : {
        categoryid:0,
          filter:{
            pageSize: this.pageSize,
            pageNumber: this.currentPage,
            text:null
          }

      },
      modelFeaturedPosts: {

        PageSize: 0,
        PageNumber: 1,
        Text: "",
        postType: "FeaturedPost"
      },

      //Posts by categories
      model : {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        text: null,
        categoryid: 0
      },


      postList:[],

      destinations1:[
    {id: 117,	value:"Health & Beauty",Urduvlaue:"تندرستی اور خوبصورتی"},
    {id: 118,	value:"Beverages & Dining",Urduvlaue:"مشروبات اور کھانے"},
    {id: 119,	value:"Hotels & Staycations",Urduvlaue:"ہوٹل اور قیام"},
    {id: 120,	value:"Sports & Leisure",Urduvlaue:"کھیل اور تفریح"},
    {id: 121,	value:"Kids & Family",Urduvlaue:"بچے اور خاندان"},
    {id: 122,	value:"Recipes",Urduvlaue:"ترکیبیں"},
    {id: 133,	value:"Trending Styles",Urduvlaue:"ٹرینڈنگ اسٹائلز"},
    {id: 245,	value:"Restaurants",Urduvlaue:"ریستوراں"}
      ],


      healthAndBeauty: {},
      beveragesAndDining: {},
      hotelsAndStaycations: {},
      sportsAndLeisure: {},
      kidsAndFamily: {},
      flowersAndGardening: {},



      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  computed: {
    rows() {
      return this.totalCount
    },
    jobsList(){

      console.log("joblist : ", this.$store.getters.showAllJobs.data)

      return this.$store.getters.showAllJobs.data;
    },
    searchedRows() {
      console.log("totalCOunt : ", this.$store.getters.showAllJobs.totalCount)

      return this.$store.getters.showAllJobs.totalCount;
    },
    getSearchedString(){
      console.log("model : ", this.$store.getters.availableString)

      return this.$store.getters.availableString;
    }
  },
  created () {

    if(this.$route.params.id != 0 && this.$route.params.id != undefined){

      this.getCMSCategoryList();
      // this.getPostByCategoryId();
      this.getFeaturedPostByCategoryId();
    }
    else{

      this.getFeaturedPosts();
    }
    this.getAll();
  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  methods: {
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      debugger
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    getAll() {
      //this.rerender()

      this.postList = [];


      this.jobSearchByName.filter.pageNumber = this.currentPage;

      // this.modelSearchedPosts.totalCount = this.totalCount;
      this.jobSearchByName.filter.pageSize = this.pageSIZE;
      this.jobSearchByName.categoryid = Number(this.$route.params.id)
      console.log("getSearchedString : ", this.getSearchedString)
      if(this.getSearchedString){
        this.jobSearchByName.filter.text = this.getSearchedString;
      }
      else {
        this.jobSearchByName.filter.text = "";
      }


      this.$store.dispatch("getJobsByName", this.jobSearchByName);
      /*axios.post('/api/Shehri/GetAllPost', this.modelSearchedPosts)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }
          this.totalCount = res.data.totalCount;
          let list = res.data.data;

          this.postList = list
          console.log('categorylist : ', this.postList)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')




        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })*/
    },
    getFeaturedPosts(){
      this.rerender()
      //this.info = [];
      this.featuredPosts = [];



      axios.post('/api/Shehri/GetPostType',this.modelFeaturedPosts)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }


          /*let list = res.data.data;
          this.totalCount = list.length;
          console.log("totalCount:" + this.totalCount);
          this.total = Math.ceil(this.totalCount / this.pageSIZE);
          console.log("totlapages:" + this.total);*/
          let list = res.data.data;
          console.log('list : ', list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')


          this.featuredPosts = list.slice().reverse();

          /* for (let post in this.info){

             if(this.info[post].postType === "FeaturedPost"){
               this.featuredPosts.push(this.info[post]);
             }

           }*/
          console.log("Featured Jobs : ", this.featuredPosts);



        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    getPostByCategoryId () {
      console.log("id : ",this.$route.params.id)
      this.postList = []

      this.jobSearchByName.filter.pageNumber = this.currentPage;

      // this.modelSearchedPosts.totalCount = this.totalCount;
      this.jobSearchByName.filter.pageSize = this.pageSIZE;
      this.jobSearchByName.categoryId = Number(this.$route.params.id)
      console.log("getSearchedString : ", this.getSearchedString)
      if(this.getSearchedString){
        this.jobSearchByName.filter.text = this.getSearchedString;
      }
      else {
        this.jobSearchByName.filter.text = "";
      }


      this.$store.dispatch("getJobsByName", this.jobSearchByName);


      /*axios
        .post('/api/Shehri/GetPost', this.model)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data
          this.totalCount = res.data.totalCount;
          console.log("totalCount:" + this.totalCount);
          this.postList = list
          console.log('Add Response portion')

          console.log('postList : ', this.postList)


        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })*/
    },
    getFeaturedPostByCategoryId () {
      console.log("id : ",this.$route.params.id)

      this.list = [];


      this.modelFeaturePosts.categoryid = Number(this.$route.params.id)



      axios
        .post('/api/Shehri/GetPost', this.modelFeaturePosts)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data
          this.totalCount = res.data.totalCount;
          console.log("totalCount:" + this.totalCount);
          this.list = list.slice().reverse();
          console.log('Add Response portion')

          console.log('list : ', this.list)

          for (let post in this.list){

            if(this.list[post].postType === "FeaturedPost"){
              this.featuredPosts.push(this.list[post]);
            }

          }


          console.log('this.featuredPosts : ', this.featuredPosts)


        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })
    },
    //Get Dropdowns List
    getCMSCategoryList(){
      this.destinations = null;
      let model = {
        type: "CmsCategory"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.destinations = res.data.data;

          console.log("Add Response portion");

          console.log("destinations : ", this.destinations);
        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },
    getVideoSrc(vSrc){



      /*this.isVideoAvailable = true;
      var video = document.getElementById("videoId");*/
      this.videoUrl = vSrc;
      console.log("videoUrl : ", this.videoUrl)
      this.isVideoAvailable = true;
      /*video.src = this.videoUrl;
      video.play();*/
    },
    closeModal(id){
      this.isVideoAvailable = false;
      this.videoUrl = "";
      console.log("vidurl", this.videoUrl)
     /* var video = document.getElementById(id);
      video.pause();*/
      /*$('#videoModal').on('hidden.bs.modal', function (e) {
        $('#videoModal video').pause()
      })*/
    },


    onChange(e){
      console.log("Page Event : ", e)
      this.currentPage = e;
      this.getPostByCategoryId();
    },
    onChangeSearchedJobs(e){
      console.log("Page Event : ", e)
      this.currentPage = e;
      this.getAll();
    },
    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {
    console.log("id : ",this.$route.params.id)

    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }



    //console.log("record : ", this.$route.params)
  }
}
</script>
