<template>
    <div id="main-container" :key="componentKey">
  
  
  
  
  
          <!--  Content Start  -->
          <div class="custom-row-element-wrap mt-5 mb-5" v-if="urdudata == false">
            <div class="row no-gutters">
              <div class="col-lg-9">
                <div class="p-3">

                  <div class="custom-row-section d-block">
                    <div class="custom-column-banner-heading  ">
  
                      <div class="custom-heading-without-background">
  
  
                          <h3 class="text-capitalize custom-text-color">{{ list.title }}</h3>
  
  
                      </div>
                      <div class="custom-widget mb-4 border-bottom">
                          <span class="mr-2">
                            <b-icon icon="clock-history"></b-icon>
                          </span>
                          <span class="custom-service-text text-muted">
                            <span style="font-weight: 600;">Event date:</span> {{ list.eventdate | formatDateAndTime }}
                          </span>
                        </div>
                      <div class="pt-2"></div>
                      <div v-if="list.image != '' && list.image != null">
                        <div class="w-100">
  
  
                            <img :src="url+list.image" class="w-100 custom-post-detail"/>
  
  
  
                        </div>
  
                      </div>
                     
                      <div v-else class="w-100">
  
  <!--                      <img src="../assets/images/whychooseus.jpg" class="w-100">-->
                        <b-alert show>Loading...</b-alert>
                      </div>
  
  
                    </div>
  
  
                  </div>
                  <div class="pt-5"></div>
                  <div class="text-justify" v-html="list.description">

                  </div>
  
                  <!--   Spacer   -->
                  <!-- <div class="pt-5"></div>
                  <div class=" ">
                    <div class="custom-bg pt-1 pb-3 pl-3 pr-3">
  
                        <div class="custom-widget mb-4 border-bottom">
                          <h2 class="widget-title">Related Posts</h2>
  
                        </div>
                        <ul class="custom-alphabet-list">
                          <li v-for="post in postList" :key="post.id">
                            <router-link
                            :to="{ name: 'PostDetail',
                            params: { id: post.id }
                              }" class="btn btn-outline-secondary custom-related-btn">{{ post.categoryTitle }}
                            </router-link>
                          </li>
                                      <li><button class="btn btn-outline-secondary">Information Technology</button></li>
                                        <li><button class="btn btn-outline-secondary">Python</button></li>
                                        <li><button class="btn btn-outline-secondary">Java Development</button></li>
                                        <li><button class="btn btn-outline-secondary ">Learn HTML5 & CSS</button></li>
                                        <li><button class="btn btn-outline-secondary ">ASP.net</button></li>
  
  
                        </ul>
                        <div class=" clearfix"></div>
  
                    </div>
  
  
                  </div> -->
                 
                  <!--   Spacer   -->
                  <div class="pt-5"></div>
                  <div class="custom-bg">
                    <div class="pt-1 pb-2">
                      <div class="mt-2 mb-3">
                        <div class="custom-widget mb-4 border-bottom">
                          <h2 class="widget-title text-center">Share This Post</h2>
  
                        </div>
                        <div class="custom-row justify-content-center ">
  
                          <ShareNetwork
                            class="custom-facebook-icon custom-icon-style mr-2 custom-social-link"
                              network="facebook"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                            <i class="fab fa-facebook-f"></i>
                          </ShareNetwork>
                          <ShareNetwork
                            class="custom-whatsapp-icon custom-icon-style mr-2 custom-social-link"
                            network="WhatsApp"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                            <i class="fab fa-whatsapp"></i>
                          </ShareNetwork>
                          <ShareNetwork
                            class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link"
                              network="LinkedIn"
                            :url="domainUrl+'/#'+jobUrl"
                              :title="list.title"
  
  
  
                          >
                            <i class="fab fa-linkedin-in"></i>
                          </ShareNetwork>
  
                          <ShareNetwork
                            class="custom-twitter-icon custom-icon-style mr-2 custom-social-link"
                            network="twitter"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                            <i class="fab fa-twitter"></i>
                          </ShareNetwork>
  
                          <!-- <ShareNetwork
                            class="custom-twitter-icon custom-icon-style  custom-social-link"
                            network="twitter"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                          <i class="bi bi-instagram"></i>
                          </ShareNetwork> -->
                        </div>
                      </div>
                    </div>
  
  
                  </div>
  
                </div>
  
              </div>

            </div>
  
  
          </div>
          <div class="custom-row-element-wrap mt-5 mb-5" v-if="urdudata == true">
            <div class="row no-gutters">
              <div class="col-lg-9">
                <div class="p-3">

                  <div class="custom-row-section d-block">
                    <div class="custom-column-banner-heading  ">
  
                      <div class="custom-heading-without-background">
  
  
                          <h3 class="text-capitalize custom-text-color">{{ list.urduTitle }}</h3>
  
  
                      </div>
                      <div class="custom-widget mb-4 border-bottom">
                          <span class="mr-2">
                            <b-icon icon="clock-history"></b-icon>
                          </span>
                          <span class="custom-service-text text-muted">
                            <span style="font-weight: 600;">Event date:</span> {{ list.eventdate | formatDateAndTime }}
                          </span>
                        </div>
                      <div class="pt-2"></div>
                      <div v-if="list.image != '' && list.image != null">
                        <div class="w-100">
  
  
                            <img :src="url+list.image" class="w-100 custom-post-detail"/>
  
  
  
                        </div>
  
                      </div>
                     
                      <div v-else class="w-100">
  
  <!--                      <img src="../assets/images/whychooseus.jpg" class="w-100">-->
                        <b-alert show>Loading...</b-alert>
                      </div>
  
  
                    </div>
  
  
                  </div>
                  <div class="pt-5"></div>
                  <div class="text-justify" v-html="list.urduDescription">

                  </div>
  
                  <!--   Spacer   -->
                  <!-- <div class="pt-5"></div>
                  <div class=" ">
                    <div class="custom-bg pt-1 pb-3 pl-3 pr-3">
  
                        <div class="custom-widget mb-4 border-bottom">
                          <h2 class="widget-title">Related Posts</h2>
  
                        </div>
                        <ul class="custom-alphabet-list">
                          <li v-for="post in postList" :key="post.id">
                            <router-link
                            :to="{ name: 'PostDetail',
                            params: { id: post.id }
                              }" class="btn btn-outline-secondary custom-related-btn">{{ post.categoryTitle }}
                            </router-link>
                          </li>
                                       <li><button class="btn btn-outline-secondary">Information Technology</button></li>
                                        <li><button class="btn btn-outline-secondary">Python</button></li>
                                        <li><button class="btn btn-outline-secondary">Java Development</button></li>
                                        <li><button class="btn btn-outline-secondary ">Learn HTML5 & CSS</button></li>
                                        <li><button class="btn btn-outline-secondary ">ASP.net</button></li>
  
  
                        </ul>
                        <div class=" clearfix"></div>
  
                    </div>
  
  
                  </div> -->
                 
                  <!--   Spacer   -->
                  <div class="pt-5"></div>
                  <div class="custom-bg">
                    <div class="pt-1 pb-2">
                      <div class="mt-2 mb-3">
                        <div class="custom-widget mb-4 border-bottom">
                          <h2 class="widget-title text-center">Share This Post</h2>
  
                        </div>
                        <div class="custom-row justify-content-center ">
  
                          <ShareNetwork
                            class="custom-facebook-icon custom-icon-style mr-2 custom-social-link"
                              network="facebook"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                            <i class="fab fa-facebook-f"></i>
                          </ShareNetwork>
                          <ShareNetwork
                            class="custom-whatsapp-icon custom-icon-style mr-2 custom-social-link"
                            network="WhatsApp"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                            <i class="fab fa-whatsapp"></i>
                          </ShareNetwork>
                          <ShareNetwork
                            class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link"
                              network="LinkedIn"
                            :url="domainUrl+'/#'+jobUrl"
                              :title="list.title"
  
  
  
                          >
                            <i class="fab fa-linkedin-in"></i>
                          </ShareNetwork>
  
                          <ShareNetwork
                            class="custom-twitter-icon custom-icon-style mr-2 custom-social-link"
                            network="twitter"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                            <i class="fab fa-twitter"></i>
                          </ShareNetwork>
  
                          <!-- <ShareNetwork
                            class="custom-twitter-icon custom-icon-style  custom-social-link"
                            network="twitter"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="list.title"
  
                          >
                          <i class="bi bi-instagram"></i>
                          </ShareNetwork> -->
                        </div>
                      </div>
                    </div>
  
  
                  </div>
  
                </div>
  
              </div>

            </div>
  
  
          </div>
  
  
      <!--   Spacer   -->
      <div class="pt-5"></div>
  
      <!-- Video Modal-->
  
      <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <button type="button" @click="closeModal()" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
  
            <div class="embed-responsive embed-responsive-16by9">
  
              <iframe v-if="isVideoAvailable" class="embed-responsive-item" width="1280" height="720" :src="videoUrl"  allowfullscreen></iframe>
            </div>
  <!--          <video id="videoId" width="100%" controls>
              <source :src="videoUrl" type="video/mp4">
  
              Your browser does not support HTML video.
            </video>-->
  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  // optional style for arrows & dots
  import Vue from 'vue'
  import axios from 'axios'
  import { UsersIcon } from 'vue-feather-icons'
  import VueSocialSharing from 'vue-social-sharing'
  
  
  // import { RlCarouselSlide } from 'vue-renderless-carousel'
  Vue.use(VueSocialSharing);
  export default {
    name: 'PostDetail',
    components: {
      // SlidesComponent,
      // RlCarouselSlide
      UsersIcon
    },
    data () {
      return {
        jobUrl:this.$route.fullPath,
        domainUrl:"https://harshehar.com",
  
        url: axios.defaults.baseURL,
        currentPage: 1,
        total: 1,
        urdudata:false,
        list: [],
        PageOfItems: [],
        info: [],
        option: 0,
  
        isVideoAvailable:false,
        //Video Source
        videoUrl:"",
        //posts
  
        postList:[],
        featuredPosts:[],
  
  
  
        loading: true,
        errored: false,
  
  
        componentKey: 0,
      }
    },
    watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
    //carousel_latest_jobs
    methods: {
      onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      debugger
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
      getAll() {
        this.rerender()
  
        this.list = [];
        console.log("id : ", this.$route.params.id)
  
        axios.get('/api/Shehri/EventDetail?id='+this.$route.params.id)
          .then(res => {
            this.list = res.data.data
            /*let list = [];
            list = res.data.data
  
            console.log('Add Response portion')
  
            console.log('list : ', list)
            this.list = list.slice().reverse();*/
  
  
            console.log('article Detail : ', this.list)
  
            this.getPostByCategory (this.list.categoryid);
            //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
  
  
  
  
          }).catch(error => {
          console.log(error)
          this.errored = true
        })
          .finally(() => {
            if (this.errored) {
  
              console.log('Error : Sorry there is no response')
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",
  
              //   solid: true,
              // });
            }
          })
      },
      getPostByCategory (id) {
        this.postList = []
        console.log("category id : ", id)
        let model = {
          categoryid: id
        }
  
        axios
          .post('/api/Shehri/GetPost', model)
          .then(res => {
            // code that we will 'try' to run
            let list = [];
            list = res.data.data
  
            console.log('Add Response portion')
  
            console.log('category Post List : ', list)
            this.postList = list.slice(-4).reverse();
  
  
            for (let post in this.postList){
  
              if(this.postList[post].postType === "FeaturedPost"){
                this.featuredPosts.push(this.postList[post]);
              }
  
            }
  
  
            console.log('this.featuredPosts : ', this.featuredPosts)
  
  
  
           /* console.log('healthAndBeauty : ', this.postList)
  
            if(this.postList.length != 0){
              this.isHealthAndBeauty = true;
  
              let sl = {
                categoryId: this.postList[0].categoryId,
                category: this.postList[0].category,
                categoryDescription: this.postList[0].categoryDescription,
                categoryTitle: this.postList[0].categoryTitle,
                id: this.postList[0].id,
                descriptionTitle: this.postList[0].descriptionTitle,
                numberofDays: this.postList[0].numberofDays,
                type: this.postList[0].type,
                videoOrImage: this.postList[0].videoOrImage
              }
              console.log("healthAndBeauty obj",sl)
              this.healthAndBeauty = sl;
            }*/
          })
          .catch(error => {
            console.log('Errorrrrrr : ', error)
            this.errored = true
          })
          .finally(() => {
            if (this.errored) {
  
              console.log('Error : Sorry there is no response')
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",
  
              //   solid: true,
              // });
            } else {
  
            }
          })
      },
  
      getVideoSrc(vSrc){
  
        // var video = document.getElementById("videoId");
        this.videoUrl = vSrc;
        console.log("videoUrl : ", this.videoUrl)
        /*video.src = this.videoUrl;
        video.play();*/
        this.isVideoAvailable=true;
      },
  
      closeModal(id){
        this.isVideoAvailable=false;
        /*var video = document.getElementById(id);
        video.pause();*/
        /*$('#videoModal').on('hidden.bs.modal', function (e) {
          $('#videoModal video').pause()
        })*/
      },
  
      //Rerender Component
      rerender () {
        this.componentKey += 1
      },
    },
  
    mounted () {
      this.getAll();
      if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
    }
  }
  </script>
  