<template>
  <div  id="main-container" :key="componentKey">
    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5" style="direction: ltr;">
      <div class="row no-gutters">
        <div class="col-lg-9">
          <div class="post-container">
          
            
            
            
            <div class="custom-widget mt-2 mb-2 border-bottom">
              <form class="mb-2">
                <div class="row">
                  <div class="col-md-5 mt-1 mb-1">
                    <div class="">
                      <!--                <label  class="sr-only">Email</label>-->
                      <input
                        v-model="company.CompanyName"
                        @keydown.enter.prevent="getAll('', null, selectedCity)"
                        type="text"
                        class="
                          form-control
                          custom-input-gotopage custom-job-search-input
                        "
                        placeholder="Company Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mt-1 mb-1">
                    <div class="">
                      <select
                        id="city_id"
                        class="
                          custom-select-option custom-select-city-option
                          form-control
                        "
                        v-model="selectedCity"
                        required
                      >
                        <option value="">Select City</option>
                        <option
                          v-for="option in filteredCities"
                          :key="option.key"
                          :value="option.key"
                        >
                          {{ option.value }}
                        </option>
                      </select>
                      <!--                      <select id="companyCategory_id"
                              class="custom-select-option custom-select-city-option form-control"
                              v-model="selectedCompanyCategory"
                      >
                        <option value=null >Select Category</option>
                        <option
                          v-for="option in companyCategories"
                          :key="option.id"
                          :value="option.id"
                        >
                          {{ option.name }}
                        </option>
                      </select>-->
                    </div>
                  </div>
                  <div class="col-md-3 mt-1 mb-1">
                    <div class="">
                      <button
                        type="button"
                        @click="getAll('', null, selectedCity)"
                        class="
                          btn btn-primary
                          custom-employer-btn
                          custom-btn-apply-now
                          custom-btn-search
                        "
                      >
                        Search
                        <!--                      <b-icon icon="search"></b-icon>-->
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--              <h2 class="widget-title text-uppercase">Latest Articles</h2>-->
            </div>
            <div class="custom-widget mt-2 mb-2">
              <div class="mb-2">
                <h5 class="p-3 bg-light custom-info-text">
              Please click on the alphabet to browse through our category 
            </h5>
            <!-- <img src="../../assets/images/pointing.png" height="200px" width="600px"/> -->
                <ul class="custom-alphabet-list">
                  <li v-for="alphabet in alphabets" :key="alphabet.name">
                    <button
                      type="button"
                      class="
                        btn btn-primary
                        custom-employer-btn
                        custom-btn-apply-now
                        custom-btn-bookmark
                      "
                      @click="getAll(alphabet.name, null, null)"
                    >
                      {{ alphabet.name }}
                    </button>
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
            </div>

            <b-alert
              :show="dismissCountDown"
              dismissible
              fade
              variant="info"
              @dismiss-count-down="countDownChanged"
            >
              No Data Found... {{ dismissCountDown }} seconds...
            </b-alert>

            <div v-if="isCategoryListVisible">
              <b-skeleton-wrapper :loading="loading">
                <template #loading>
                  <div class="custom-widget mt-2 mb-2 border-bottom">
                    <h2 class="widget-title text-uppercase">
                      <b-skeleton width="20%"></b-skeleton>
                    </h2>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <b-skeleton width="85%"></b-skeleton>
                      <b-skeleton width="55%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                      <b-skeleton width="85%"></b-skeleton>
                      <b-skeleton width="55%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                      <b-skeleton width="85%"></b-skeleton>
                      <b-skeleton width="55%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </div>
                    <div class="col-lg-6">
                      <b-skeleton width="85%"></b-skeleton>
                      <b-skeleton width="55%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                      <b-skeleton width="85%"></b-skeleton>
                      <b-skeleton width="55%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                      <b-skeleton width="85%"></b-skeleton>
                      <b-skeleton width="55%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </div>
                  </div>
                </template>
                <div class="custom-widget mt-2 mb-2 border-bottom">
                  <h2 class="widget-title text-uppercase">
                    <span v-if="this.list.length > 1"> Categories </span>
                    <span v-else-if="this.list.length == 1"> Category </span>
                    <span v-else> </span>
                  </h2>
                </div>
                <div class="row">
                  <div
                    class="col-lg-6"
                    v-for="(post, index) in companyCategories"
                    :key="post.key"
                  >
                    <a
                      href="javascript:void(0)"
                      class="text-decoration-none mt-1 mb-1"
                      @click="getAll('', post.key, null)"
                    >
                      {{ post.value }}
                    </a>
                  </div>
                </div>
              </b-skeleton-wrapper>
            </div>

            <div v-if="isCompanyListVisible">
              <b-skeleton-wrapper :loading="loading">
                <template #loading>
                  <div class="custom-widget mt-2 mb-2 border-bottom">
                    <h2 class="widget-title text-uppercase">
                      <b-skeleton width="20%"></b-skeleton>
                    </h2>
                  </div>
                  <div class="blog-mini-post blog-mini-post-large">
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div>
                        <b-skeleton-img
                          card-img="right"
                          width="108px"
                          height="108px"
                        ></b-skeleton-img>
                      </div>
                      <!--                <router-link
                                        to="/"
                                        class=" post-overlay-small"
                                      >

                                        <div class="post-overlay-small"></div>
                                      </router-link>-->
                    </div>

                    <div class="blog-mini-post-content">
                      <div class="blog-mini-post-content-container">
                        <h6 class="post-title font-italic">
                          <b-skeleton width="85%"></b-skeleton>
                        </h6>

                        <h4 class="post-title">
                          <b-skeleton width="55%"></b-skeleton>
                        </h4>
                        <h6 class="">
                          <b-skeleton width="70%"></b-skeleton>
                        </h6>
                        <div class="post-meta">
                          <b-skeleton width="70%"></b-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="custom-widget mt-2 mb-2 border-bottom">
                  <h2 class="widget-title text-uppercase">
                    <span v-if="this.list.length > 1"> Companies </span>
                    <span v-else-if="this.list.length == 1"> Company </span>
                    <span v-else> </span>
                  </h2>
                </div>
                <div
                  v-for="(post, index) in list"
                  :key="index"
                  class="blog-mini-post blog-mini-post-large"
                >
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div>
                      <img
                        :src="url + post.companyLogo"
                        class="custom-post-small-image"
                      />
                    </div>
                    <!--                <router-link
                                      to="/"
                                      class=" post-overlay-small"
                                    >

                                      <div class="post-overlay-small"></div>
                                    </router-link>-->
                  </div>

                  <div class="blog-mini-post-content">
                    <div class="blog-mini-post-content-container">
                      <div class="row no-gutters align-items-center">
                        <div class="col-lg-6">
                          <div class="ml-2 mr-2">
                            <!--                  <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                                               Beverages & Dining
                                             </h5>
                                             <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                                               Hotels & Staycations
                                             </h5>
                                             <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                                               Sports & Leisure
                                             </h5>
                                             <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                                               Kids & Family
                                             </h5>
                                             <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                                               Flowers & Gardening
                                             </h5>
                                             <span v-else class="post-title font-italic">

                                             </span>-->

                            <h4 class="text-capitalize post-title">
                              {{ post.companyName }}
                            </h4>
                            <h6 class="text-capitalize">
                              {{ post.name }}
                            </h6>
                            <div class="custom-post-title">
                              <span class="font-weight-bold">Category: </span>
                              <span class="font-italic">
                                {{ post.companyCategory }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="post-meta">
                            <div class="post-meta-content">
                              <span class="post-auhor-date custom-post-meta">
                                <div>
                                  <span class="post-date text-danger">
                                    <span class="mr-1">
                                      <b-icon icon="clock-history"></b-icon>
                                    </span>
                                    <span>
                                      {{ post.expireDate | formatDateAndTime }}
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  <span class="mr-1">
                                    <b-icon icon="globe2"></b-icon>
                                  </span>
                                  <span>
                                    {{ post.website }}
                                  </span>
                                </div>

                                <div>
                                  <span class="mr-1">
                                    <b-icon icon="envelope-fill"></b-icon>
                                  </span>
                                  <span>
                                    {{ post.email }}
                                  </span>
                                </div>
                                <div>
                                  <span class="mr-1">
                                    <b-icon icon="telephone-fill"></b-icon>
                                  </span>
                                  <span>
                                    {{ post.contactNo }}
                                  </span>
                                </div>

                                <div>
                                  <a 
                                  class="custom-text-color"
                                  target="_blank" 
                                  :title="post.googleMap" 
                                  :href="post.googleMap">
                                    <span class="mr-1">
                                      <b-icon icon="geo-alt-fill"></b-icon>
                                    </span>
                                    <span> {{ post.cityName }}, </span>
                                    <!-- @click="getGoogleMapSrc(post.googleMap)"
                                                                       data-toggle="modal"
                                                                      data-target="#googleMapModal"
                                                                      href=""
                                                                      -->
                                    <span>
                                      {{ post.address }}
                                    </span>
                                  </a>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="ml-1 mr-1">
            <div class="w-100 h-100">
              <div
                v-if="directoryRightSideAdd != []"
                class="w-100 h-100 text-center"
              >
                <a
                  :href="directoryRightSideAdd[0].url"
                  :title="directoryRightSideAdd[0].url"
                  target="_blank"
                  class="navbar-brand mr-0"
                >
                  <img
                    :src="url + directoryRightSideAdd[0].image"
                    class="w-100 h-100"
                  />
                </a>
              </div>
              <div v-else class="w-100 h-100 text-center">
                <img
                  src="../../assets/images/large-ad.jpg"
                  class="w-100 h-100"
                />
              </div>
            </div>
            <div class="custom-post-container">
              <label class="custom-contactUs-label">
                To register your business in Social Register contact us at:
              </label>

              <p class="custom-service-text">
                info@harshehar.com<br />
                (+92) 332 9777738<br />
                (+92) 423 7243810
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Google Map Modal-->
    <div class="">
      <div
        class="modal fade"
        id="googleMapModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="googleMapModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <button
              type="button"
              @click="closeModal"
              class="close custom-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="embed-responsive embed-responsive-16by9">
              <div v-html="googleMap"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'
import moment from 'moment'
import _ from "underscore";
// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'DirectoryList',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {







      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: [],
      PageOfItems: [],
      info: [],
      option: 0,


      //Bool
      isCategoryListVisible:false,
      isCompanyListVisible:false,




      company:{
        CityId:null,
        Categoryid: null,
        CompanyName:"",
        Alphabet:""
      },
      selectedCity:"",
      filteredCities:[],
      selectedCompanyCategory:null,



      modelFilteredCategories : {
        type: "CmsCompanyCategory",
        text:""
      },

      companyCategories:[],

      googleMap:"",
      alphabets:[
        { name: 'A' },
        { name: 'B' },
        { name: 'C' },
        { name: 'D' },
        { name: 'E' },
        { name: 'F' },
        { name: 'G' },
        { name: 'H' },
        { name: 'I' },
        { name: 'J' },
        { name: 'K' },
        { name: 'L' },
        { name: 'M' },
        { name: 'N' },
        { name: 'O' },
        { name: 'P' },
        { name: 'Q' },
        { name: 'R' },
        { name: 'S' },
        { name: 'T' },
        { name: 'U' },
        { name: 'V' },
        { name: 'W' },
        { name: 'X' },
        { name: 'Y' },
        { name: 'Z' }
      ],


      //alert msg
      dismissSecs: 5,
      dismissCountDown: 0,


      loading: false,
      errored: false,


      componentKey: 0,
    }
  },


  computed:{
    directoryRightSideAdd(){
      console.log("availableDirectoryRightSide : ", this.$store.getters.availableDirectoryRightSide)
      return this.$store.getters.availableDirectoryRightSide
    },
  },
  methods: {
    getAdds(){
      this.$store.dispatch("fetchAdds");
    },
    getAll(alphabet, catId, cityId) {
      this.rerender()
      this.loading = true;
      this.list = [];
      this.companyCategories = [];
      //Runs When Alphabet is pressed
      if(alphabet != ""){
        console.log("in alphanotnull")
        this.isCategoryListVisible = true;
        this.isCompanyListVisible = false;
        //this.company.Alphabet = alphabet;
        this.company.CompanyName = "";

        this.getFilteredCategories(alphabet);

        // this.selectedCity = cityId;
        this.company.CityId = (this.selectedCity == "" || this.selectedCity == null )? null : Number(this.selectedCity)
        // this.selectedCompanyCategory = null;
        this.company.Categoryid = catId;
      }
      //Runs When searched Category is Pressed
      else if(catId != null){

        console.log("in catnotnull")
        this.isCategoryListVisible = false;
        this.isCompanyListVisible = true;
        //this.company.Alphabet = alphabet;
        this.company.CompanyName = "";
        // this.selectedCity = cityId;
        this.company.CityId = (this.selectedCity == "" || this.selectedCity == null )? null : Number(this.selectedCity)
        // this.selectedCompanyCategory = null;
        this.company.Categoryid = Number(catId);

      }
      else {
        console.log("in else")
        if(this.selectedCity != "" && this.company.CompanyName == ""){
          console.log("this.selectedCity != null && this.company.CompanyName == ''", this.selectedCity)
          this.company.CityId = (this.selectedCity == "" || this.selectedCity == null )? null : Number(this.selectedCity)

          this.isCategoryListVisible = false;
          this.isCompanyListVisible = true;
          /*if( this.company.Alphabet != '' && this.company.Categoryid == null){
            this.isCategoryListVisible = true;
            this.isCompanyListVisible = false;
          }
          else
          {
            this.isCategoryListVisible = false;
            this.isCompanyListVisible = true;
          }*/
        }
        //Runs when Search button is pressed
        else if(this.selectedCity != "" && this.company.CompanyName != ""){
          //this.company.Alphabet = alphabet;
          this.company.Categoryid = catId;
          this.company.CityId = (this.selectedCity == "" || this.selectedCity == null )? null : Number(this.selectedCity)
            this.isCategoryListVisible = false;
            this.isCompanyListVisible = true;



        }
        else if(this.company.CompanyName != ""){
          //this.company.Alphabet = alphabet;
          this.company.Categoryid = catId;
          this.company.CityId = null
          this.isCategoryListVisible = false;
          this.isCompanyListVisible = true;
        }
        else if(this.selectedCity == "" && this.company.CompanyName == ""){

          //this.company.Alphabet = '';
          this.company.Categoryid = null;
          this.company.CompanyName = "";
          this.company.CityId = null;
          this.isCategoryListVisible = false;
          this.isCompanyListVisible = false;


          this.showAlert();
          /*this.$bvToast.toast("No Data Found ", {
            toaster: "b-toaster-top-right",
            variant: "info",

            solid: true,
          });*/
        }



      }


      console.log("event generated: ",alphabet)
      console.log("catId: ",catId)








      axios.post('/api/Shehri/GetCompany', this.company)
        .then(res => {


          let list = []
          if(res.data.totalCount == 0){
            this.showAlert();
            this.loading = false;
            /*this.$bvToast.toast("No Data Found ", {
              toaster: "b-toaster-top-right",
              variant: "info",

              solid: true,
            });*/



          }
          else {
            list = res.data.data

            let companiesList = list.slice().reverse();


            let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
            for(let data in companiesList){
              if(moment(String(companiesList[data].expireDate)).format('YYYY-MM-DD') >= currentDate){
                this.list.push(companiesList[data]);
              }
            }


            console.log('companylist : ', this.list)
            //this.getCompanyCategoryList(this.list);
            //this.getFilteredCities(this.list);






            this.loading = false;
            if(this.list.length == 0){
              this.showAlert();
            }
          }

          //setTimeout(this.timeOut,1000);
          // this.loading = false;
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

          /*for (let post in this.list){
            console.log("this.list[post]: ",this.list[post])
            if(this.list[post].postType === 1){
              this.featuredPosts.push(this.list[post]);
            }

          }

          console.log("Featured Jobs : ", this.featuredPosts);
          for (let lpost in this.list){
            if(this.list[lpost].postType === 2){
              this.latestPosts.push(this.list[lpost]);
            }
          }
          console.log("latestJobs Jobs : ", this.latestPosts);*/


        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    timeOut(){
      this.loading = false;
    },



    getFilteredCities() {

      this.filteredCities = null;
      let model = {
        type: "City"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          let rawCities = res.data.data;



          this.filteredCities = _.sortBy(rawCities, 'value');

          console.log("Add Response portion");

          console.log("filteredCities : ", this.filteredCities);
        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
      /*this.filteredCities = [];
      const map = new Map();
      for (const item of jobRecord) {
        if(!map.has(item.cityId)){
          map.set(item.cityId, true);    // set any value to Map
          this.filteredCities.push({
            cid: item.cityId,
            name: item.cityName
          });
        }
      }
      console.log("this.filteredCities in getall : ",this.filteredCities);*/


    },

    getFilteredCategories(alphabet) {

      this.companyCategories = [];
      this.modelFilteredCategories.text = alphabet;

      axios
        .post("/api/KeyValuePair/SearchKeyType", this.modelFilteredCategories)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data;
          console.log("Company Categories list : ", list);
          for (let cat in list){
              if(list[cat].isDeleted == false){
                this.companyCategories.push(list[cat]);
              }

            }
    

          console.log("Add Response portion");

          console.log("companyCategories : ", this.companyCategories);
        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
      /*this.filteredCities = [];
      const map = new Map();
      for (const item of jobRecord) {
        if(!map.has(item.cityId)){
          map.set(item.cityId, true);    // set any value to Map
          this.filteredCities.push({
            cid: item.cityId,
            name: item.cityName
          });
        }
      }
      console.log("this.filteredCities in getall : ",this.filteredCities);*/


    },
    getGoogleMapSrc(vSrc){



      /*this.isVideoAvailable = true;
      var video = document.getElementById("videoId");*/
      this.googleMap = vSrc;
      console.log("videoUrl : ", this.videoUrl)
      // this.isVideoAvailable = true;
      /*video.src = this.videoUrl;
      video.play();*/
    },
    closeModal(){
      // this.isVideoAvailable = false;
      this.googleMap = "";
      console.log("vidurl", this.videoUrl)
      /* var video = document.getElementById(id);
       video.pause();*/
      /*$('#videoModal').on('hidden.bs.modal', function (e) {
        $('#videoModal video').pause()
      })*/
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },


    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {
    this.getAdds();
    this.getAll("", null, null);
    this.getFilteredCities();
    //this.getCompanyCategoryList();

  }
}
</script>
