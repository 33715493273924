<template>
    
    <div id="main-container" :key="componentKey">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
        <section class="ftco-section bg-light" style="padding:5rem">
    <div class="container">
      <div class="row">

                    <div class="col-lg-6">
                        <h2>Invoice No # {{ invoiceno }}</h2>
                        <h3 style="padding-top: 9rem;">Please send us your Invoice No <span style="font-weight:700">" {{ invoiceno }} "</span><br>qnd proof of transaction to: <br>
                            <a href="mailto:info@shemeanswork.com"><span style="font-weight:700"> info@shemeanswork.com</span> </a><br>OR
                            <br><a href="https://api.whatsapp.com/send?phone=+923329777738&text=hello"><i style="font-size:24px" class="fa">&#xf232;</i><span style="font-weight:700"> +92 332 977 7738</span></a></h3>
                    </div>
                      <!-- <div class="img" style="background-image: url(images/person_1.jpg);"></div> -->
                      <div class="col-lg-6">
                          <h3><a style="margin-left: 3rem;" href="#">Bank Details</a></h3>
                          <span class="position"></span>
                          <div class="text">
                  
                  
                              <p> <span>Account Title</span> : <br><a >LBS SMC Pvt LTD</a></p>
                  <p> <span>Account Number</span> : <br><a >0331145900233810</a></p>
                  <p> <span>IBAN </span> : <br><a >PK32KAYS0331145900233810</a></p>
                  <p> <span>Swift Code</span> : <br><a >FAYSPKKA</a></p>
                  <p> <span>Branch Code</span> : <br><a >3549</a></p>
                  <p> <span>Bank Name and Address</span> : <br><a >FAYSAL BANK LIMITED 310 UPPER MALL LAHORE</a></p>
                          </div>
                      </div>

     

    </div>
    </div>
  </section>

    </div>
  </template>
  
  <script>
  
  // optional style for arrows & dots
  import Vue from 'vue'
  import axios from 'axios'
  import { UsersIcon } from 'vue-feather-icons'
  import VueSocialSharing from 'vue-social-sharing'
  
  
  // import { RlCarouselSlide } from 'vue-renderless-carousel'
  Vue.use(VueSocialSharing);
  export default {
    name: 'PostDetail',
    components: {
      // SlidesComponent,
      // RlCarouselSlide
      UsersIcon
    },
    data () {
      return {
        jobUrl:this.$route.fullPath,
        domainUrl:"https://harshehar.com",
  
        url: axios.defaults.baseURL,
        currentPage: 1,
        total: 1,
  
        list: [],
        PageOfItems: [],
        info: [],
        option: 0,
  
        isVideoAvailable:false,
        //Video Source
        videoUrl:"",
        //posts
  
        postList:[],
        featuredPosts:[],
        invoiceno: this.$route.params.id,
  
  
        loading: true,
        errored: false,
  
  
        componentKey: 0,
      }
    },
    //carousel_latest_jobs
    methods: {
      getAll() {
  
        this.list = [];
        console.log("id : ", this.$route.params.id)
  
        axios.post('/api/Shehri/PackageOrderDetail?id='+this.$route.params.id)
          .then(res => {
            this.list = res.data.data
          console.log(this.list);
          }).catch(error => {
          console.log(error)
          this.errored = true
        })
          .finally(() => {
            if (this.errored) {
  
              console.log('Error : Sorry there is no response')
            }
          })
      },

    },
  
    mounted () {
  
    }
  }
  </script>