<template>
    <div id="main-container" :key="componentKey">
  
  
  
  
  
          <!--  Content Start  -->
          <div class="custom-row-element-wrap mt-5 mb-5">
            <div class="row no-gutters">
              <div class="col-lg-12">

                <div v-for="(post, index) in this.list " :key="post.id" class="">
<div class="row" style="margin-top:2rem">
                    <div class="col-lg-3">
<div v-if="post.videoOrImage != '' && post.videoOrImage != null" >
  
    <div class="">

      <img :src="url+post.videoOrImage" style="width: 20rem; height: 11rem;"/>

    </div>
    <router-link
      :to="{ name: 'PostDetail',
              params: { id: post.id }
            }"
      class="post-overlay text-decoration-none"
    >

      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
    </router-link>
  </div>


<div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
  
    <div class="">
      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" style="width: 20rem;">


    </div>
    <a
      href="javascript:void(0)"
      @click="getVideoSrcInFooter(post.youtubeLink)"
      data-toggle="modal" data-target="#videoModalInFooter" :title="post.youtubeLink"
      class="post-overlay text-decoration-none">
      <div class="post-categories-container custom-play-icon">
        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
          <b-icon icon="play-circle"></b-icon>
        </div>
      </div>
    </a>


</div>
<div v-else>
  <div class="blog-mini-post-thumb sh-post-review-mini">
    <div class="">
      <img src="../assets/images/whychooseus.jpg" style="width: 20rem;">


    </div>



  </div>
</div>
</div>
<div class="col-lg-1"></div>
<div  class="col-lg-8">
  <div class="">
    <div class="">

      <router-link :to="{
        name: 'PostDetail',
        params: { id: post.id }
      }" class="post-title">
        <h4 class=" footer  text-capitalize custom-footer-post-title">
          {{ post.categoryTitle }}
        </h4>
      </router-link>
      <div class="post-content">
        <p class="">
          <span>{{ post.shortDescription }}</span>

        </p>
      </div>
      <div class="row no-gutters">
        <div class="col-md-8"></div>
        <div class="col-md-4">
                <span>
                  <router-link
                    :to="{
                      name: 'PostDetail',
                      params: { id: post.id }
                    }"
                    class="text-decoration-none post-categories-link post-categories-footer-link">
                    <span>
                      Read More
                    </span>
                  </router-link>
                </span>
        </div>
      </div>

    </div>
  </div>
</div>
</div>
</div>
              </div>

            </div>
          </div>
  
     <!--   Spacer   -->
      <div class="pt-5"></div>
      <!-- Video Modal-->
      <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <button type="button" @click="closeModal()" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
  
            <div class="embed-responsive embed-responsive-16by9">
  
              <iframe v-if="isVideoAvailable" class="embed-responsive-item" width="1280" height="720" :src="videoUrl"  allowfullscreen></iframe>
            </div>
  <!--          <video id="videoId" width="100%" controls>
              <source :src="videoUrl" type="video/mp4">
  
              Your browser does not support HTML video.
            </video>-->
  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  // optional style for arrows & dots
  import Vue from 'vue'
  import axios from 'axios'
  import { UsersIcon } from 'vue-feather-icons'
  import VueSocialSharing from 'vue-social-sharing'
  
  
  // import { RlCarouselSlide } from 'vue-renderless-carousel'
  Vue.use(VueSocialSharing);
  export default {
    name: 'PostDetail',
    components: {
      // SlidesComponent,
      // RlCarouselSlide
      UsersIcon
    },
    data () {
      return {
        jobUrl:this.$route.fullPath,
        domainUrl:"https://harshehar.com",
  
        url: axios.defaults.baseURL,
        currentPage: 1,
        total: 1,
  
        list: [],
        PageOfItems: [],
        info: [],
        option: 0,
  
        isVideoAvailable:false,
        //Video Source
        videoUrl:"",
        //posts
  
        postList:[],
        featuredPosts:[],
  
  
  
        loading: true,
        errored: false,
  
  
        componentKey: 0,
      }
    },
    //carousel_latest_jobs
    methods: {
      getAll() {
        this.rerender()
  
        this.list = [];
        console.log("id : ", this.$route.params.id)
  
        axios.get('/api/Shehri/GetArchivePosts?id='+this.$route.params.id)
          .then(res => {
            this.list = res.data.data
            /*let list = [];
            list = res.data.data
  
            console.log('Add Response portion')
  
            console.log('list : ', list)
            this.list = list.slice().reverse();*/
  
  
            console.log('article Detail : ', this.list)
  
            this.getPostByCategory (this.list.categoryid);
            //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
  
  
  
  
          }).catch(error => {
          console.log(error)
          this.errored = true
        })
          .finally(() => {
            if (this.errored) {
  
              console.log('Error : Sorry there is no response')
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",
  
              //   solid: true,
              // });
            }
          })
      },
      getPostByCategory (id) {
        this.postList = []
        console.log("category id : ", id)
        let model = {
          categoryid: id
        }
  
        axios
          .post('/api/Shehri/GetPost', model)
          .then(res => {
            // code that we will 'try' to run
            let list = [];
            list = res.data.data
  
            console.log('Add Response portion')
  
            console.log('category Post List : ', list)
            this.postList = list.slice(-4).reverse();
  
  
            for (let post in this.postList){
  
              if(this.postList[post].postType === "FeaturedPost"){
                this.featuredPosts.push(this.postList[post]);
              }
  
            }
  
  
            console.log('this.featuredPosts : ', this.featuredPosts)
  
  
  
           /* console.log('healthAndBeauty : ', this.postList)
  
            if(this.postList.length != 0){
              this.isHealthAndBeauty = true;
  
              let sl = {
                categoryId: this.postList[0].categoryId,
                category: this.postList[0].category,
                categoryDescription: this.postList[0].categoryDescription,
                categoryTitle: this.postList[0].categoryTitle,
                id: this.postList[0].id,
                descriptionTitle: this.postList[0].descriptionTitle,
                numberofDays: this.postList[0].numberofDays,
                type: this.postList[0].type,
                videoOrImage: this.postList[0].videoOrImage
              }
              console.log("healthAndBeauty obj",sl)
              this.healthAndBeauty = sl;
            }*/
          })
          .catch(error => {
            console.log('Errorrrrrr : ', error)
            this.errored = true
          })
          .finally(() => {
            if (this.errored) {
  
              console.log('Error : Sorry there is no response')
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",
  
              //   solid: true,
              // });
            } else {
  
            }
          })
      },
  
      getVideoSrc(vSrc){
  
        // var video = document.getElementById("videoId");
        this.videoUrl = vSrc;
        console.log("videoUrl : ", this.videoUrl)
        /*video.src = this.videoUrl;
        video.play();*/
        this.isVideoAvailable=true;
      },
  
      closeModal(id){
        this.isVideoAvailable=false;
        /*var video = document.getElementById(id);
        video.pause();*/
        /*$('#videoModal').on('hidden.bs.modal', function (e) {
          $('#videoModal video').pause()
        })*/
      },
  
      //Rerender Component
      rerender () {
        this.componentKey += 1
      },
    },
  
    mounted () {
      this.getAll();
  
    }
  }
  </script>
  