import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jobList:[],
    searchString:null,
    adds:[],
  },
  mutations: {
    setJobs(state, list){
      state.jobList = [];
      state.jobList = list;
    },
    setSearchString(state, searchString){
      state.searchString = searchString;
    },
    setAdds(state, adds){
      state.adds = adds;
    },
  },
  actions: {
    getJobsByName(context, searchFilter){
      console.log("search Filter : ", searchFilter)
      //this.rerender();
      let list = [];
      let model = {};
      if(searchFilter != {}){

        if(searchFilter.categoryid == 0){
          console.log("if execute in store")
          context.commit("setSearchString", searchFilter.filter.text)
          model = {
            pageSize: searchFilter.filter.pageSize,
            pageNumber: searchFilter.filter.pageNumber,
            text: searchFilter.filter.text

          }




          console.log("model in if", model)
          axios.post('/api/Shehri/GetAllPost', model)
            .then(res => {
              if (res.status != '200') {
                console.log('error.....')
                const error = new Error('Failed to fetch!')
                return error
              }
              //let totalCount = res.data.totalCount;

              list = res.data;


              console.log('categorylist : ', list)


              context.commit("setJobs", list)

              //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')




            }).catch(error => {
            console.log(error)
            this.errored = true
          })
            .finally(() => {
              if (this.errored) {

                console.log('Error : Sorry there is no response')
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              }
            })
        }
        else {

          console.log("else execute in store")
          context.commit("setSearchString", searchFilter.filter.text)
          model = {
            pageSize: searchFilter.filter.pageSize,
            pageNumber: searchFilter.filter.pageNumber,
            text: searchFilter.filter.text,
            categoryid: searchFilter.categoryid
          }

          console.log("model in else", model)

          axios
            .post('/api/Shehri/GetPost', model)
            .then(res => {
              // code that we will 'try' to run
              let list = [];
              list = res.data
              //this.totalCount = res.data.totalCount;
              //console.log("totalCount:" + this.totalCount);

              console.log('Add Response portion')

              console.log('postList : ', list)
              context.commit("setJobs", list)

            })
            .catch(error => {
              console.log('Errorrrrrr : ', error)
              this.errored = true
            })
            .finally(() => {
              if (this.errored) {

                console.log('Error : Sorry there is no response')
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {

              }
            })
        }


      }
      else {

        /*this.$route.params.jobTitle = undefined;
        this.$route.params.selectedCity = undefined;
        this.getAll();*/
      }
    },
    fetchAdds(context){
      let adds = [];

      let addsModel = {
        "pageSize": 0,
        "pageNumber": 1,
        "totalRecord": 0,
        "text": ""
      }

      axios
        .post("/api/Adds/GetAdd", addsModel)
        .then(res => {
          // code that we will 'try' to run

          let jobList = res.data.data;
          adds = jobList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", adds);
          context.commit("setAdds", adds)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    }

  },
  getters:{
    showAllJobs(state, getters){
      if(state.jobList != []){
        return state.jobList;
      }

    },
    availableString(state, getters){

      if(state.searchString != null){
        return state.searchString;
      }
      else {
        return false;
      }



    },

    //    available adds
    availableTopBarAdds(state, getters){
      let topbarAdd = []


      for (const item of state.adds) {
        if(item.title == "shehri/Topbar"){
          topbarAdd.push({
            id : item.addsId,
            title : item.title,
            image : item.addImage,
            url: item.bannerUrl
          });
        }
      }



      return topbarAdd.filter(item => item.id > 0)
    },
    availableHomeRightSide(state, getters){
      let shehriHomeRightSide = []


      for (const item of state.adds) {
        if(item.title == "Shehri/Home/Right"){
          shehriHomeRightSide.push({
            id : item.addsId,
            title : item.title,
            image : item.addImage,
            url: item.bannerUrl
          });
        }
      }



      return shehriHomeRightSide.filter(item => item.id > 0)
    },
    availableDirectoryRightSide(state, getters){
      let shehriDirectoryRightSide = []


      for (const item of state.adds) {
        if(item.title == "Shehri/Directory/ RightAd"){
          shehriDirectoryRightSide.push({
            id : item.addsId,
            title : item.title,
            image : item.addImage,
            url: item.bannerUrl
          });
        }
      }



      return shehriDirectoryRightSide.filter(item => item.id > 0)
    },
  },
  modules: {
  }
})
