<template>
  <div id="app">

    <Topbar class="mobileTopBar sticky-top" />
    <Sidebar  />
    <div id="topbar_id">
      <Topbar class="webTopBar" />

      <BannerHeader />

    </div>
    <MainMenu />

    <Content />
    <Footer  @scrollTo-top="scrollTo()" />


  </div>

</template>

<script>
import Content from '@/components/Content.vue'
import Sidebar from '@/components/Sidebar.vue'

import Topbar from './components/Topbar'
import BannerHeader from './components/BannerHeader'
import MainMenu from './components/MainMenu'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Content,
    Topbar,
    BannerHeader,
    MainMenu,
    Sidebar,
    Footer
  },
  data(){
    return{
      //topBarId:"topbar_id"
    };
  },
  methods:{
    scrollTo(){
      let yOffset = -245;
      /*if(id.match("accordion")){
        yOffset = -250;
      }*/

      let element = null;
      element = document.getElementById("app");
      console.log("element id : ",element)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      console.log("element position : ",y)
      //$("#content_id").animate({ scrollBottom: y }, "fast");
      window.scrollTo({top: y, behavior: 'smooth'});


    }
  }
}
</script>

<style>
</style>
