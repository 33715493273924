var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,attrs:{"id":"main-container"}},[_c('div',{staticClass:"custom-row-element-wrap mt-5 mb-5"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-12"},_vm._l((this.list),function(post,index){return _c('div',{key:post.id},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"2rem"}},[_c('div',{staticClass:"col-lg-3"},[(post.videoOrImage != '' && post.videoOrImage != null)?_c('div',[_c('div',{},[_c('img',{staticStyle:{"width":"20rem","height":"11rem"},attrs:{"src":_vm.url+post.videoOrImage}})]),_c('router-link',{staticClass:"post-overlay text-decoration-none",attrs:{"to":{ name: 'PostDetail',
              params: { id: post.id }
            }}},[_c('div',{staticClass:"post-overlay-content custom-mini-post-overlay-content"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1):(post.youtubeLink != '' && post.youtubeLink != null)?_c('div',[_c('div',{},[_c('img',{staticStyle:{"width":"20rem"},attrs:{"src":'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'}})]),_c('a',{staticClass:"post-overlay text-decoration-none",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#videoModalInFooter","title":post.youtubeLink},on:{"click":function($event){return _vm.getVideoSrcInFooter(post.youtubeLink)}}},[_c('div',{staticClass:"post-categories-container custom-play-icon"},[_c('div',{staticClass:"post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content"},[_c('b-icon',{attrs:{"icon":"play-circle"}})],1)])])]):_c('div',[_vm._m(0,true)])]),_c('div',{staticClass:"col-lg-1"}),_c('div',{staticClass:"col-lg-8"},[_c('div',{},[_c('div',{},[_c('router-link',{staticClass:"post-title",attrs:{"to":{
        name: 'PostDetail',
        params: { id: post.id }
      }}},[_c('h4',{staticClass:"footer text-capitalize custom-footer-post-title"},[_vm._v(" "+_vm._s(post.categoryTitle)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',{},[_c('span',[_vm._v(_vm._s(post.shortDescription))])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link post-categories-footer-link",attrs:{"to":{
                      name: 'PostDetail',
                      params: { id: post.id }
                    }}},[_c('span',[_vm._v(" Read More ")])])],1)])])],1)])])])])}),0)])]),_c('div',{staticClass:"pt-5"}),_c('div',{staticClass:"modal fade",attrs:{"id":"videoModal","tabindex":"-1","role":"dialog","aria-labelledby":"videoModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('button',{staticClass:"close custom-close-btn",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[(_vm.isVideoAvailable)?_c('iframe',{staticClass:"embed-responsive-item",attrs:{"width":"1280","height":"720","src":_vm.videoUrl,"allowfullscreen":""}}):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-mini-post-thumb sh-post-review-mini"},[_c('div',{},[_c('img',{staticStyle:{"width":"20rem"},attrs:{"src":require("../assets/images/whychooseus.jpg")}})])])
}]

export { render, staticRenderFns }