<template>
  <div
    class="custom-sidebar w3-sidebar w3-bar-block w3-animate-left"
    id="mySidebar"
  >
    <div
      id="sidebar-tabs"
      class="nav nav-tabs custom-sidebar-nav-tabs"
      role="tablist"
    >
      <router-link
        to="/"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="house-door"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Home</span>
            </div>
          </div>
        </div>
      </router-link>
      <a
        href="#"
        class="w3-bar-item w3-button custom-bar-item custom-active"
        data-toggle="collapse"
        data-target="#id-categories"
        aria-expanded="false"
        ariacontrols="id-categories"
      >
        <div class="custom-sidebar-heading">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="shop"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Categories</span>
            </div>
          </div>
          <div class="custom-sidebar-icons custom-add-btn">
            <a href="#" class="custom-sidebar-link">
              <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </a>
      <div
        id="id-categories"
        class="collapse custom-collapse"
        aria-labelledby="sidebar-tabs"
        data-parent="#sidebar-tabs"
      >
        <router-link
          v-for="destination in destinations" :key="destination.key"
          :to="{ name : 'CategoryList',
                    params : {id: destination.key}}"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>{{ destination.value }}</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <router-link
        to="/social-register"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="person-check"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Social Register</span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link
        to="/about-us"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="grid"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>About Us</span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link
          to="/contact-us"
          class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="grid"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Contact Us</span>
            </div>
          </div>
        </div>
      </router-link>




    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Sidebar",
  // mounted() {
  //   this.$nextTick(() => {
  //     $(document).ready(function() {
  //       $(".custom-active").click(function() {
  //         $(this).addClass("active");
  //         $(".custom-active")
  //           .not(this)
  //           .removeClass("active");
  //       });
  //     });
  //   });
  // },
  data(){
    return{
      destinations:[]
    };
  },
  methods:{
    //Get Dropdowns List
    getCMSCategoryList(){
      this.destinations = null;
      let model = {
        type: "CmsCategory"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.destinations = res.data.data;

          console.log("Add Response portion");

          console.log("destinations : ", this.destinations);
        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },
    w3_close() {
      document.getElementById("mySidebar").style.display = "none";

      document.getElementById("btn-open-sidebar").style.display = "block";
      document.getElementById("btn-close-sidebar").style.display = "none";
      console.log("clicked")
    },
  },
  mounted () {
    this.getCMSCategoryList();
  }
};
</script>

<style></style>
