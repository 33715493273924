<template>
  <div id="main-container" :key="componentKey">


    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">

        <div class="col-lg-9">
          <div class="custom-bottom-border-separator">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="custom-widget mt-2 mb-2 border-bottom ">
                  <h2 v-if="urdudata == false" class="widget-title text-uppercase">{{ $t('Home.1sttitle') }}</h2>
                  <h2 v-if="urdudata == true" style="float:right" class="widget-title text-uppercase">{{ $t('Home.1sttitle') }}</h2>
                </div>
                <div>
                  <div class="row" >
                    <div class="col-md-11">
                      <carousel :per-page="3" :mouseDrag="true" style="direction:ltr">
                        <slide :key="ix.id" v-for="(ix) in Upcomingevents">
                          <div class="style-brands bg-white ">

                            <div class="pl-3 pr-3">
                              <div class="post-thumbnail mt-4">
                                <div class="">
                                  <div class="sh-ratio">
                                    <div class="">


                                      <div class="custom-post-large-image">

                                        <img :src="url + ix.image" style="" class="post-thumbnail mt-3">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span v-if="urdudata == false">
                            <router-link :to="{
                              name: 'Eventdetails',
                              params: { id: ix.id }
                            }">
                              <h6
                                style="display: block;word-break: break-word;transition: all 0.3s ease-in-out;color: #000;">
                                {{ ix.title }}</h6>
                            </router-link>
                          </span>
                          <span v-if="urdudata == true">
                            <router-link :to="{
                              name: 'Eventdetails',
                              params: { id: ix.id }
                            }">
                              <h6
                              style="display: block;word-break: break-word;transition: all 0.3s ease-in-out;color: #000;">
                                {{ ix.urduTitle }}</h6>
                            </router-link>
                          </span>
                          



                          </div>
                        </slide>

                      </carousel>
                      <!-- <VueSlickCarousel v-bind="settings1" class="container">

                        <div class="style-brands bg-white " :key="ix.id" v-for="(ix) in Upcomingevents">
                          <router-link :to="{
                            name: 'Eventdetails',
                            params: { id: ix.id }
                          }">
                            <div class="pl-3 pr-3">
                              <div class="post-thumbnail mt-4">
                                <div class="">
                                  <div class="sh-ratio">
                                    <div class="">

                                                    
                                      <div class="custom-post-large-image">

                                        <img :src="url + ix.image" style="" class="post-thumbnail mt-3">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h6
                              style="display: block;word-break: break-word;transition: all 0.3s ease-in-out;color: #000;">
                              {{ ix.title }}</h6>
                          </router-link>




                        </div>
                      </VueSlickCarousel> -->
                    </div>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
          <div class="custom-bottom-border-separator">
            <div class="row no-gutters">
              <div class="col-lg-6" v-if="urdudata == false">
                <article id="post-1" class="mb-5 " v-if="isHealthAndBeauty">
                  <div class="post-container">
                    <div class="custom-post-container">
                      <div class="custom-widget mt-2 mb-2 border-bottom ">
                        <h2 class="widget-title text-uppercase">{{ $t('Home.HEALTH&BEAUTY') }}</h2>
                      </div>
                      <div class="pl-5 pr-5">

                        <div v-if="healthAndBeauty.videoOrImage != '' && healthAndBeauty.videoOrImage != null">
                          <!--                    v-if="healthAndBeauty.videoOrImage.search('.jpg') != -1 || healthAndBeauty.videoOrImage.search('.JPG') != -1 || healthAndBeauty.videoOrImage.search('.png') != -1 || healthAndBeauty.videoOrImage.search('.PNG') != -1"-->
                          <div class="post-thumbnail mt-4">
                            <div class="">
                              <div class="sh-ratio">
                                <!--                    sh-ratio-container-->
                                <div class="">
                                  <!--                      class="sh-ratio-content"

                                                       style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                  <div class="custom-post-large-image">

                                    <img :src="url + healthAndBeauty.videoOrImage" />
                                  </div>
                                </div>
                              </div>
                              <router-link :to="{
                                name: 'PostDetail',
                                params: { id: healthAndBeauty.id }
                              }" class="post-overlay text-decoration-none">
                                <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                              </router-link>
                            </div>
                          </div>


                        </div>
                        <div v-else-if="healthAndBeauty.youtubeLink != '' && healthAndBeauty.youtubeLink != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">

                              <div class="">
                                <!--                          <iframe width="1280" height="720" src="https://www.youtube.com/embed/x7Krla_UxRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                                <div class="custom-post-large-image">
                                  <img
                                    :src="'https://img.youtube.com/vi/' + healthAndBeauty.youtubeLink.substring(30, healthAndBeauty.youtubeLink.length) + '/0.jpg'">
                                </div>
                              </div>
                            </div>
                            <a @click="getVideoSrc(healthAndBeauty.youtubeLink)" data-toggle="modal"
                              data-target="#videoModal" :title="healthAndBeauty.youtubeLink"
                              class="post-overlay text-decoration-none">
                              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
                              <div class="post-categories-container custom-play-icon">
                                <div class="post-overlay-content custom-post-overlay-content">
                                  <b-icon icon="play-circle"></b-icon>
                                </div>

                              </div>
                            </a>


                          </div>
                        </div>
                        <div v-else>
                          <!--                    v-if="healthAndBeauty.videoOrImage.search('.jpg') != -1 || healthAndBeauty.videoOrImage.search('.JPG') != -1 || healthAndBeauty.videoOrImage.search('.png') != -1 || healthAndBeauty.videoOrImage.search('.PNG') != -1"-->
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img src="../assets/images/whychooseus.jpg">
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: healthAndBeauty.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>
                        <div class="post-content-container">
                          <div class="post-categories-container text-center mt-1">
                            <router-link :to="{
                              name: 'CategoryList',
                              params: { id: healthAndBeauty.categoryId }
                            }" class="text-decoration-none post-categories-link">
                              <span>
                                {{ healthAndBeauty.category }}
                                <!--                          Category-->
                              </span>
                            </router-link>
                          </div>
                          <router-link :to="{
                            name: 'PostDetail',
                            params: { id: healthAndBeauty.id }
                          }" class="post-title text-capitalize">
                            <h2>
                              {{ healthAndBeauty.categoryTitle }}
                              <!--                        Title-->
                            </h2>
                          </router-link>
                          <div class="post-content">
                            <p class="">
                              <span>{{ healthAndBeauty.shortDescription }}</span>

                            </p>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                              <span>
                                <router-link :to="{
                                  name: 'CategoryList',
                                  params: { id: healthAndBeauty.categoryId }
                                }" class="text-decoration-none post-categories-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                            </div>
                          </div>
                          <div class="post-meta">
                            <div class="post-meta-content">
                              <span class="post-auhor-date">
                                <!--                      <span>
                        <a
                          href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                          class="post-author">Gillion
                        </a>
                      </span>,-->


                                <span v-if="healthAndBeauty.numberofDays > 1">
                                  <i class="post-date">
                                    {{ healthAndBeauty.numberofDays }}
                                    days ago
                                  </i>
                                </span>
                                <span v-else-if="healthAndBeauty.numberofDays == 1">
                                  <i class="post-date">
                                    {{ healthAndBeauty.numberofDays }} day ago
                                  </i>
                                </span>
                                <span v-else>
                                  <i class="post-date">
                                    today
                                  </i>
                                </span>

                              </span>
                              <!--                        <a
                                                        href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                        class="post-comments">
                                                        <i class="icon icon-bubble"></i> 0
                                                      </a>
                                                      <span class="post-readtime">
                                                        <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                      </span>-->

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </article>

              </div>
              <div class="col-lg-6" v-if="urdudata == true">
                <article id="post-1" class="mb-5 " v-if="isHealthAndBeauty">
                  <div class="post-container">
                    <div class="custom-post-container">
                      <div class="custom-widget mt-2 mb-2 border-bottom ">
                        <h2 style="float:right" class="widget-title text-uppercase">{{ $t('Home.HEALTH&BEAUTY') }}</h2>
                      </div>
                      <div class="pl-5 pr-5">

                        <div v-if="healthAndBeauty.videoOrImage != '' && healthAndBeauty.videoOrImage != null">
                          <!--                    v-if="healthAndBeauty.videoOrImage.search('.jpg') != -1 || healthAndBeauty.videoOrImage.search('.JPG') != -1 || healthAndBeauty.videoOrImage.search('.png') != -1 || healthAndBeauty.videoOrImage.search('.PNG') != -1"-->
                          <div class="post-thumbnail mt-4">
                            <div class="">
                              <div class="sh-ratio">
                                <!--                    sh-ratio-container-->
                                <div class="">
                                  <!--                      class="sh-ratio-content"

                                                       style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                  <div class="custom-post-large-image">

                                    <img :src="url + healthAndBeauty.videoOrImage" />
                                  </div>
                                </div>
                              </div>
                              <router-link :to="{
                                name: 'PostDetail',
                                params: { id: healthAndBeauty.id }
                              }" class="post-overlay text-decoration-none">
                                <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                              </router-link>
                            </div>
                          </div>


                        </div>
                        <div v-else-if="healthAndBeauty.youtubeLink != '' && healthAndBeauty.youtubeLink != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">

                              <div class="">
                                <!--                          <iframe width="1280" height="720" src="https://www.youtube.com/embed/x7Krla_UxRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                                <div class="custom-post-large-image">
                                  <img
                                    :src="'https://img.youtube.com/vi/' + healthAndBeauty.youtubeLink.substring(30, healthAndBeauty.youtubeLink.length) + '/0.jpg'">
                                </div>
                              </div>
                            </div>
                            <a @click="getVideoSrc(healthAndBeauty.youtubeLink)" data-toggle="modal"
                              data-target="#videoModal" :title="healthAndBeauty.youtubeLink"
                              class="post-overlay text-decoration-none">
                              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
                              <div class="post-categories-container custom-play-icon">
                                <div class="post-overlay-content custom-post-overlay-content">
                                  <b-icon icon="play-circle"></b-icon>
                                </div>

                              </div>
                            </a>


                          </div>
                        </div>
                        <div v-else>
                          <!--                    v-if="healthAndBeauty.videoOrImage.search('.jpg') != -1 || healthAndBeauty.videoOrImage.search('.JPG') != -1 || healthAndBeauty.videoOrImage.search('.png') != -1 || healthAndBeauty.videoOrImage.search('.PNG') != -1"-->
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img src="../assets/images/whychooseus.jpg">
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: healthAndBeauty.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>
                        <div class="post-content-container">
                          <div class="post-categories-container text-center mt-1">
                            <router-link :to="{
                              name: 'CategoryList',
                              params: { id: healthAndBeauty.categoryId }
                            }" class="text-decoration-none post-categories-link">
                              <span>
                                {{ healthAndBeauty.category }}
                                <!--                          Category-->
                              </span>
                            </router-link>
                          </div>
                          <router-link :to="{
                            name: 'PostDetail',
                            params: { id: healthAndBeauty.id }
                          }" class="post-title text-capitalize">
                            <h2>
                              {{ healthAndBeauty.urduDescriptionTitle }}
                              <!--                        Title-->
                            </h2>
                          </router-link>
                          <div class="post-content">
                            <p class="">
                              <span>{{ healthAndBeauty.urduShortDescription }}</span>

                            </p>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                              <span>
                                <router-link :to="{
                                  name: 'CategoryList',
                                  params: { id: healthAndBeauty.categoryId }
                                }" class="text-decoration-none post-categories-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                            </div>
                          </div>
                          <div class="post-meta">
                            <div class="post-meta-content">
                              <span class="post-auhor-date">
                                <!--                      <span>
                        <a
                          href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                          class="post-author">Gillion
                        </a>
                      </span>,-->


                                <span v-if="healthAndBeauty.numberofDays > 1">
                                  <i class="post-date">
                                    {{ healthAndBeauty.numberofDays }}
                                    days ago
                                  </i>
                                </span>
                                <span v-else-if="healthAndBeauty.numberofDays == 1">
                                  <i class="post-date">
                                    {{ healthAndBeauty.numberofDays }} day ago
                                  </i>
                                </span>
                                <span v-else>
                                  <i class="post-date">
                                    today
                                  </i>
                                </span>

                              </span>
                              <!--                        <a
                                                        href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                        class="post-comments">
                                                        <i class="icon icon-bubble"></i> 0
                                                      </a>
                                                      <span class="post-readtime">
                                                        <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                      </span>-->

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </article>

              </div>
              <div class="col-lg-6" v-if="urdudata == false">
                <article id="post-3" class="mb-5" v-if="isBeveragesAndDining">
                  <div class="post-container">
                    <div class="custom-post-container">
                      <div class="custom-widget mt-2 mb-2 border-bottom ">
                        <h2 class="widget-title text-uppercase">{{ $t('nav.Category.Beverages&Dining') }}</h2>
                      </div>
                      <div class="pl-5 pr-5">
                        <div v-if="beveragesAndDining.videoOrImage != '' && beveragesAndDining.videoOrImage != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img :src="url + beveragesAndDining.videoOrImage" />
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: beveragesAndDining.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>

                        <div v-else-if="beveragesAndDining.youtubeLink != '' && beveragesAndDining.youtubeLink != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">

                              <div class="">

                                <div class="custom-post-large-image">
                                  <img
                                    :src="'https://img.youtube.com/vi/' + beveragesAndDining.youtubeLink.substring(30, beveragesAndDining.youtubeLink.length) + '/0.jpg'">
                                </div>
                              </div>
                            </div>
                            <a @click="getVideoSrc(beveragesAndDining.youtubeLink)" data-toggle="modal"
                              data-target="#videoModal" :title="beveragesAndDining.youtubeLink"
                              class="post-overlay text-decoration-none">
                              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
                              <div class="post-categories-container custom-play-icon">
                                <div class="post-overlay-content custom-post-overlay-content">
                                  <b-icon icon="play-circle"></b-icon>
                                </div>

                              </div>
                            </a>


                          </div>
                        </div>
                        <div v-else>
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img src="../assets/images/whychooseus.jpg" />
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: beveragesAndDining.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>

                        <div class="post-content-container">
                          <div class="post-categories-container text-center mt-1">
                            <router-link :to="{
                              name: 'CategoryList',
                              params: { id: beveragesAndDining.categoryId }
                            }" class="text-decoration-none post-categories-link">
                              <span>
                                {{ beveragesAndDining.category }}
                                <!--                          Category-->
                              </span>
                            </router-link>
                          </div>
                          <router-link :to="{
                            name: 'PostDetail',
                            params: { id: beveragesAndDining.id }
                          }" class="post-title text-capitalize">
                            <h2>
                              {{ beveragesAndDining.categoryTitle }}
                              <!--                        Title-->
                            </h2>
                          </router-link>
                          <div class="post-content">
                            <p class="">
                              <span>{{ beveragesAndDining.shortDescription }}</span>

                            </p>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                              <span>
                                <router-link :to="{
                                  name: 'CategoryList',
                                  params: { id: beveragesAndDining.categoryId }
                                }" class="text-decoration-none post-categories-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                            </div>
                          </div>
                          <div class="post-meta">
                            <div class="post-meta-content">
                              <span class="post-auhor-date">
                                <!-- <span>
                          <a
                            href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                            class="post-author">Gillion
                          </a>
                        </span>,-->

                                <span v-if="beveragesAndDining.numberofDays > 1">
                                  <i class="post-date">
                                    {{ beveragesAndDining.numberofDays }}
                                    days ago
                                  </i>
                                </span>
                                <span v-else-if="beveragesAndDining.numberofDays == 1">
                                  <i class="post-date">
                                    {{ beveragesAndDining.numberofDays }} day ago
                                  </i>
                                </span>
                                <span v-else>
                                  <i class="post-date">
                                    today
                                  </i>
                                </span>

                              </span>
                              <!--                        <a
                                                        href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                        class="post-comments">
                                                        <i class="icon icon-bubble"></i> 0
                                                      </a>
                                                      <span class="post-readtime">
                                                        <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                      </span>-->

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>

              </div>
              <div class="col-lg-6" v-if="urdudata == true">
                <article id="post-3" class="mb-5" v-if="isBeveragesAndDining">
                  <div class="post-container">
                    <div class="custom-post-container">
                      <div class="custom-widget mt-2 mb-2 border-bottom ">
                        <h2 style="float:right" class="widget-title text-uppercase">{{ $t('nav.Category.Beverages&Dining') }}</h2>
                      </div>
                      <div class="pl-5 pr-5">
                        <div v-if="beveragesAndDining.videoOrImage != '' && beveragesAndDining.videoOrImage != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img :src="url + beveragesAndDining.videoOrImage" />
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: beveragesAndDining.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>

                        <div v-else-if="beveragesAndDining.youtubeLink != '' && beveragesAndDining.youtubeLink != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">

                              <div class="">

                                <div class="custom-post-large-image">
                                  <img
                                    :src="'https://img.youtube.com/vi/' + beveragesAndDining.youtubeLink.substring(30, beveragesAndDining.youtubeLink.length) + '/0.jpg'">
                                </div>
                              </div>
                            </div>
                            <a @click="getVideoSrc(beveragesAndDining.youtubeLink)" data-toggle="modal"
                              data-target="#videoModal" :title="beveragesAndDining.youtubeLink"
                              class="post-overlay text-decoration-none">
                              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
                              <div class="post-categories-container custom-play-icon">
                                <div class="post-overlay-content custom-post-overlay-content">
                                  <b-icon icon="play-circle"></b-icon>
                                </div>

                              </div>
                            </a>


                          </div>
                        </div>
                        <div v-else>
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img src="../assets/images/whychooseus.jpg" />
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: beveragesAndDining.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>

                        <div class="post-content-container">
                          <div class="post-categories-container text-center mt-1">
                            <router-link :to="{
                              name: 'CategoryList',
                              params: { id: beveragesAndDining.categoryId }
                            }" class="text-decoration-none post-categories-link">
                              <span>
                                {{ beveragesAndDining.category }}
                                <!--                          Category-->
                              </span>
                            </router-link>
                          </div>
                          <router-link :to="{
                            name: 'PostDetail',
                            params: { id: beveragesAndDining.id }
                          }" class="post-title text-capitalize">
                            <h2>
                              {{ beveragesAndDining.urduDescriptionTitle }}
                              <!--                        Title-->
                            </h2>
                          </router-link>
                          <div class="post-content">
                            <p class="">
                              <span>{{ beveragesAndDining.urduShortDescription }}</span>

                            </p>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                              <span>
                                <router-link :to="{
                                  name: 'CategoryList',
                                  params: { id: beveragesAndDining.categoryId }
                                }" class="text-decoration-none post-categories-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                            </div>
                          </div>
                          <div class="post-meta">
                            <div class="post-meta-content">
                              <span class="post-auhor-date">
                                <!-- <span>
                          <a
                            href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                            class="post-author">Gillion
                          </a>
                        </span>,-->

                                <span v-if="beveragesAndDining.numberofDays > 1">
                                  <i class="post-date">
                                    {{ beveragesAndDining.numberofDays }}
                                    days ago
                                  </i>
                                </span>
                                <span v-else-if="beveragesAndDining.numberofDays == 1">
                                  <i class="post-date">
                                    {{ beveragesAndDining.numberofDays }} day ago
                                  </i>
                                </span>
                                <span v-else>
                                  <i class="post-date">
                                    today
                                  </i>
                                </span>

                              </span>
                              <!--                        <a
                                                        href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                        class="post-comments">
                                                        <i class="icon icon-bubble"></i> 0
                                                      </a>
                                                      <span class="post-readtime">
                                                        <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                      </span>-->

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>

              </div>
            </div>
          </div>
          <div class="custom-bottom-border-separator">
            <div class="row no-gutters">
              <div class="col-lg-6" v-if="urdudata == false">

                <article id="post-2" class="mb-5 " v-if="isSportsAndLeisure">
                  <div class="post-container">
                    <div class="custom-post-container">
                      <div class="custom-widget mt-2 mb-2 border-bottom ">
                        <h2 class="widget-title text-uppercase">{{ $t('Home.SPORTS&LEISURE') }}</h2>
                      </div>
                      <div class="pl-5 pr-5">
                        <div v-if="sportsAndLeisure.videoOrImage != '' && sportsAndLeisure.videoOrImage != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img :src="url + sportsAndLeisure.videoOrImage" />
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: sportsAndLeisure.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>

                        <div v-else-if="sportsAndLeisure.youtubeLink != '' && sportsAndLeisure.youtubeLink != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">

                              <div class="">

                                <div class="custom-post-large-image">
                                  <img
                                    :src="'https://img.youtube.com/vi/' + sportsAndLeisure.youtubeLink.substring(30, sportsAndLeisure.youtubeLink.length) + '/0.jpg'">
                                </div>
                              </div>
                            </div>
                            <a @click="getVideoSrc(sportsAndLeisure.youtubeLink)" data-toggle="modal"
                              data-target="#videoModal" :title="sportsAndLeisure.youtubeLink"
                              class="post-overlay text-decoration-none">
                              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
                              <div class="post-categories-container custom-play-icon">
                                <div class="post-overlay-content custom-post-overlay-content">
                                  <b-icon icon="play-circle"></b-icon>
                                </div>

                              </div>
                            </a>


                          </div>
                        </div>
                        <div v-else>
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <!--                      class="sh-ratio-content"

                                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                                <div class="custom-post-large-image">

                                  <img src="../assets/images/whychooseus.jpg" />
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: sportsAndLeisure.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>

                        <div class="post-content-container">
                          <div class="post-categories-container text-center mt-1">
                            <router-link :to="{
                              name: 'CategoryList',
                              params: { id: sportsAndLeisure.categoryId }
                            }" class="text-decoration-none post-categories-link">
                              <span>
                                {{ sportsAndLeisure.category }}
                                <!--                          Category-->
                              </span>
                            </router-link>
                          </div>
                          <router-link :to="{
                            name: 'PostDetail',
                            params: { id: sportsAndLeisure.id }
                          }" class="post-title text-capitalize">
                            <h2>
                              {{ sportsAndLeisure.categoryTitle }}
                              <!--                        Title-->
                            </h2>
                          </router-link>
                          <div class="post-content">
                            <p class="">
                              <span>{{ sportsAndLeisure.shortDescription }}</span>

                            </p>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                              <span>
                                <router-link :to="{
                                  name: 'CategoryList',
                                  params: { id: sportsAndLeisure.categoryId }
                                }" class="text-decoration-none post-categories-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                            </div>
                          </div>
                          <div class="post-meta">
                            <div class="post-meta-content">
                              <span class="post-auhor-date">
                                <!--                      <span>
                        <a
                          href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                          class="post-author">Gillion
                        </a>
                      </span>,-->

                                <span v-if="sportsAndLeisure.numberofDays > 1">
                                  <i class="post-date">
                                    {{ sportsAndLeisure.numberofDays }}
                                    days ago
                                  </i>
                                </span>
                                <span v-else-if="sportsAndLeisure.numberofDays == 1">
                                  <i class="post-date">
                                    {{ sportsAndLeisure.numberofDays }} day ago
                                  </i>
                                </span>
                                <span v-else>
                                  <i class="post-date">
                                    today
                                  </i>
                                </span>

                              </span>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </article>
              </div>
              <div class="col-lg-6" v-if="urdudata == true">

<article id="post-2" class="mb-5 " v-if="isSportsAndLeisure">
  <div class="post-container">
    <div class="custom-post-container">
      <div class="custom-widget mt-2 mb-2 border-bottom ">
        <h2 style="float:right" class="widget-title text-uppercase">{{ $t('Home.SPORTS&LEISURE') }}</h2>
      </div>
      <div class="pl-5 pr-5">
        <div v-if="sportsAndLeisure.videoOrImage != '' && sportsAndLeisure.videoOrImage != null">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              <!--                    sh-ratio-container-->
              <div class="">
                           <div class="custom-post-large-image">

                  <img :src="url + sportsAndLeisure.videoOrImage" />
                </div>
              </div>
            </div>
            <router-link :to="{
              name: 'PostDetail',
              params: { id: sportsAndLeisure.id }
            }" class="post-overlay text-decoration-none">
              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
          </div>

        </div>

        <div v-else-if="sportsAndLeisure.youtubeLink != '' && sportsAndLeisure.youtubeLink != null">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">

              <div class="">

                <div class="custom-post-large-image">
                  <img
                    :src="'https://img.youtube.com/vi/' + sportsAndLeisure.youtubeLink.substring(30, sportsAndLeisure.youtubeLink.length) + '/0.jpg'">
                </div>
              </div>
            </div>
            <a @click="getVideoSrc(sportsAndLeisure.youtubeLink)" data-toggle="modal"
              data-target="#videoModal" :title="sportsAndLeisure.youtubeLink"
              class="post-overlay text-decoration-none">
              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
              <div class="post-categories-container custom-play-icon">
                <div class="post-overlay-content custom-post-overlay-content">
                  <b-icon icon="play-circle"></b-icon>
                </div>

              </div>
            </a>


          </div>
        </div>
        <div v-else>
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              <!--                    sh-ratio-container-->
              <div class="">
                 <div class="custom-post-large-image">

                  <img src="../assets/images/whychooseus.jpg" />
                </div>
              </div>
            </div>
            <router-link :to="{
              name: 'PostDetail',
              params: { id: sportsAndLeisure.id }
            }" class="post-overlay text-decoration-none">
              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
          </div>

        </div>

        <div class="post-content-container">
          <div class="post-categories-container text-center mt-1">
            <router-link :to="{
              name: 'CategoryList',
              params: { id: sportsAndLeisure.categoryId }
            }" class="text-decoration-none post-categories-link">
              <span>
                {{ sportsAndLeisure.category }}
                <!--                          Category-->
              </span>
            </router-link>
          </div>
          <router-link :to="{
            name: 'PostDetail',
            params: { id: sportsAndLeisure.id }
          }" class="post-title text-capitalize">
            <h2>
              {{ sportsAndLeisure.urduDescriptionTitle }}
              <!--                        Title-->
            </h2>
          </router-link>
          <div class="post-content">
            <p class="">
              <span>{{ sportsAndLeisure.urduShortDescription }}</span>

            </p>
          </div>
          <div class="row no-gutters">
            <div class="col-md-9"></div>
            <div class="col-md-3">
              <span>
                <router-link :to="{
                  name: 'CategoryList',
                  params: { id: sportsAndLeisure.categoryId }
                }" class="text-decoration-none post-categories-link">
                  <span>
                    {{ $t('Home.ReadMore') }}
                    <!--                          Category-->
                  </span>
                </router-link>
              </span>
            </div>
          </div>
          <div class="post-meta">
            <div class="post-meta-content">
              <span class="post-auhor-date">
                <span v-if="sportsAndLeisure.numberofDays > 1">
                  <i class="post-date">
                    {{ sportsAndLeisure.numberofDays }}
                    days ago
                  </i>
                </span>
                <span v-else-if="sportsAndLeisure.numberofDays == 1">
                  <i class="post-date">
                    {{ sportsAndLeisure.numberofDays }} day ago
                  </i>
                </span>
                <span v-else>
                  <i class="post-date">
                    today
                  </i>
                </span>

              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</article>
</div>
              <div class="col-lg-6" v-if="urdudata == false">
                <article id="post-4" class="mb-5" v-if="isHotelsAndStaycations">
                  <div class="post-container">
                    <div class="custom-post-container">
                      <div class="custom-widget mt-2 mb-2 border-bottom">
                        <h2 class="widget-title text-uppercase">{{ $t('Home.HOTELS&STAYCATIONS') }}</h2>
                      </div>
                      <div class="pl-5 pr-5">
                        <div v-if="hotelsAndStaycations.videoOrImage != '' && hotelsAndStaycations.videoOrImage != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">
                              <!--                    sh-ratio-container-->
                              <div class="">
                                <div class="custom-post-large-image">

                                  <img :src="url + hotelsAndStaycations.videoOrImage" />
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: hotelsAndStaycations.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>
                          </div>

                        </div>
                        <div
                          v-else-if="hotelsAndStaycations.youtubeLink != '' && hotelsAndStaycations.youtubeLink != null">
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">

                              <div class="">

                                <div class="custom-post-large-image">
                                  <img
                                    :src="'https://img.youtube.com/vi/' + sportsAndLeisure.youtubeLink.substring(30, sportsAndLeisure.youtubeLink.length) + '/0.jpg'">
                                </div>
                              </div>
                            </div>
                            <a @click="getVideoSrc(hotelsAndStaycations.youtubeLink)" data-toggle="modal"
                              data-target="#videoModal" :title="hotelsAndStaycations.youtubeLink"
                              class="post-overlay text-decoration-none">
                              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
                              <div class="post-categories-container custom-play-icon">
                                <div class="post-overlay-content custom-post-overlay-content">
                                  <b-icon icon="play-circle"></b-icon>
                                </div>

                              </div>
                            </a>


                          </div>
                        </div>
                        <div v-else>
                          <div class="post-thumbnail mt-4">
                            <div class="sh-ratio">

                              <div class="">

                                <div class="custom-post-large-image">
                                  <img src="../assets/images/whychooseus.jpg">
                                </div>
                              </div>
                            </div>
                            <router-link :to="{
                              name: 'PostDetail',
                              params: { id: hotelsAndStaycations.id }
                            }" class="post-overlay text-decoration-none">
                              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                            </router-link>

                          </div>
                        </div>

                        <div class="post-content-container">
                          <div class="post-categories-container text-center mt-1">
                            <router-link :to="{
                              name: 'CategoryList',
                              params: { id: hotelsAndStaycations.categoryId }
                            }" class="text-decoration-none post-categories-link">
                              <span>
                                {{ hotelsAndStaycations.category }}
                                <!--                          Category-->
                              </span>
                            </router-link>
                          </div>
                          <router-link :to="{
                            name: 'PostDetail',
                            params: { id: hotelsAndStaycations.id }
                          }" class="post-title text-capitalize">
                            <h2>
                              {{ hotelsAndStaycations.categoryTitle }}
                              <!--                        Title-->
                            </h2>
                          </router-link>
                          <div class="post-content">
                            <p class="">
                              <span>{{ hotelsAndStaycations.shortDescription }}</span>

                            </p>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                              <span>
                                <router-link :to="{
                                  name: 'CategoryList',
                                  params: { id: hotelsAndStaycations.categoryId }
                                }" class="text-decoration-none post-categories-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                            </div>
                          </div>
                          <div class="post-meta">
                            <div class="post-meta-content">
                              <span class="post-auhor-date">
                                <span v-if="hotelsAndStaycations.numberofDays > 1">
                                  <i class="post-date">
                                    {{ hotelsAndStaycations.numberofDays }}
                                    days ago
                                  </i>
                                </span>
                                <span v-else-if="hotelsAndStaycations.numberofDays == 1">
                                  <i class="post-date">
                                    {{ hotelsAndStaycations.numberofDays }} day ago
                                  </i>
                                </span>
                                <span v-else>
                                  <i class="post-date">
                                    today
                                  </i>
                                </span>

                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div class="col-lg-6" v-if="urdudata == true">

<article id="post-4" class="mb-5" v-if="isHotelsAndStaycations">
  <div class="post-container">
    <div class="custom-post-container">
      <div class="custom-widget mt-2 mb-2 border-bottom">
        <h2 style="float:right" class="widget-title text-uppercase">{{ $t('Home.HOTELS&STAYCATIONS') }}</h2>
      </div>
      <div class="pl-5 pr-5">
        <div v-if="hotelsAndStaycations.videoOrImage != '' && hotelsAndStaycations.videoOrImage != null">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              <!--                    sh-ratio-container-->
              <div class="">
                <!--                      class="sh-ratio-content"

                                     style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                <div class="custom-post-large-image">

                  <img :src="url + hotelsAndStaycations.videoOrImage" />
                </div>
              </div>
            </div>
            <router-link :to="{
              name: 'PostDetail',
              params: { id: hotelsAndStaycations.id }
            }" class="post-overlay text-decoration-none">
              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
          </div>

        </div>
        <div
          v-else-if="hotelsAndStaycations.youtubeLink != '' && hotelsAndStaycations.youtubeLink != null">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">

              <div class="">

                <div class="custom-post-large-image">
                  <img
                    :src="'https://img.youtube.com/vi/' + sportsAndLeisure.youtubeLink.substring(30, sportsAndLeisure.youtubeLink.length) + '/0.jpg'">
                </div>
              </div>
            </div>
            <a @click="getVideoSrc(hotelsAndStaycations.youtubeLink)" data-toggle="modal"
              data-target="#videoModal" :title="hotelsAndStaycations.youtubeLink"
              class="post-overlay text-decoration-none">
              <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
              <div class="post-categories-container custom-play-icon">
                <div class="post-overlay-content custom-post-overlay-content">
                  <b-icon icon="play-circle"></b-icon>
                </div>

              </div>
            </a>


          </div>
        </div>
        <div v-else>
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">

              <div class="">

                <div class="custom-post-large-image">
                  <img src="../assets/images/whychooseus.jpg">
                </div>
              </div>
            </div>
            <router-link :to="{
              name: 'PostDetail',
              params: { id: hotelsAndStaycations.id }
            }" class="post-overlay text-decoration-none">
              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>

          </div>
        </div>

        <div class="post-content-container">
          <div class="post-categories-container text-center mt-1">
            <router-link :to="{
              name: 'CategoryList',
              params: { id: hotelsAndStaycations.categoryId }
            }" class="text-decoration-none post-categories-link">
              <span>
                {{ hotelsAndStaycations.category }}
                <!--                          Category-->
              </span>
            </router-link>
          </div>
          <router-link :to="{
            name: 'PostDetail',
            params: { id: hotelsAndStaycations.id }
          }" class="post-title text-capitalize">
            <h2>
              {{ hotelsAndStaycations.urduDescriptionTitle }}
            </h2>
          </router-link>
          <div class="post-content">
            <p class="">
              <span>{{ hotelsAndStaycations.urduShortDescription }}</span>

            </p>
          </div>
          <div class="row no-gutters">
            <div class="col-md-9"></div>
            <div class="col-md-3">
              <span>
                <router-link :to="{
                  name: 'CategoryList',
                  params: { id: hotelsAndStaycations.categoryId }
                }" class="text-decoration-none post-categories-link">
                  <span>
                    {{ $t('Home.ReadMore') }}
                    <!--                          Category-->
                  </span>
                </router-link>
              </span>
            </div>
          </div>
          <div class="post-meta">
            <div class="post-meta-content">
              <span class="post-auhor-date">
                <!--                      <span>
          <a
            href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
            class="post-author">Gillion
          </a>
        </span>,-->

                <span v-if="hotelsAndStaycations.numberofDays > 1">
                  <i class="post-date">
                    {{ hotelsAndStaycations.numberofDays }}
                    days ago
                  </i>
                </span>
                <span v-else-if="hotelsAndStaycations.numberofDays == 1">
                  <i class="post-date">
                    {{ hotelsAndStaycations.numberofDays }} day ago
                  </i>
                </span>
                <span v-else>
                  <i class="post-date">
                    today
                  </i>
                </span>

              </span>
              <!--                        <a
                                        href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                        class="post-comments">
                                        <i class="icon icon-bubble"></i> 0
                                      </a>
                                      <span class="post-readtime">
                                        <i class="icon icon-clock"></i> 1 min <span>read</span>
                                      </span>-->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>
</div>

            </div>
            <div class="post-container" v-if="urdudata == false">

              <div class="custom-widget mt-2 mb-2 border-bottom ">
                <h2 class="widget-title text-uppercase">{{ $t('Home.LATESTARTICLES') }}</h2>
              </div>

             

              <div>
                <div :key="postscomponentKey">
                  <div>
             
                    <div class="row no-gutters">
                      <div class="col-lg-6 mt-1 mb-1" v-for="(post, index) in latestPosts" :key="post.id">
                        <div id="my-latestPosts" class="post-thumbnail blog-mini-post blog-mini-post-large ml-2 mr-2">


                          <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                            <div class="blog-mini-post-thumb sh-post-review-mini">
                              <div class="">

                                <img :src="url + post.videoOrImage" class="custom-post-small-image" />

                              </div>
                              <router-link :to="{
                                name: 'PostDetail',
                                params: { id: post.id }
                              }" class="post-overlay text-decoration-none">

                                <div class="post-overlay-content custom-mini-post-overlay-content"><span></span>
                                  <span></span> <span></span>
                                </div>
                              </router-link>
                            </div>

                          </div>

                          <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                            <div class="blog-mini-post-thumb sh-post-review-mini">
                              <div class="">
                                <img
                                  :src="'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'"
                                  class="custom-post-small-image">


                              </div>
                              <a href="javascript:void(0)" @click="getVideoSrc(post.youtubeLink)" data-toggle="modal"
                                data-target="#videoModal" :title="post.youtubeLink"
                                class="post-overlay text-decoration-none">
                                <div class="post-categories-container custom-play-icon">
                                  <div
                                    class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                                    <b-icon icon="play-circle"></b-icon>
                                  </div>
                                </div>


                              </a>


                            </div>
                          </div>
                          <div v-else>
                            <div class="blog-mini-post-thumb sh-post-review-mini">
                              <div class="">
                                <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">


                              </div>



                            </div>
                          </div>
                          <!--              <div class="blog-mini-post-thumb sh-post-review-mini">
                                          <div>
                                            <img :src="url+post.videoOrImage" style="width: 108px; height: 75px"/>
                                          </div>
                                          <router-link
                                            :to="{ name: 'PostDetail',
                                                        params: { id: post.id }
                                                      }"
                                            class=" post-overlay-small"
                                          >

                                            <div class="post-overlay-small"></div>
                                          </router-link>
                                        </div>-->

                          <div class="blog-mini-post-content">
                            <div class="blog-mini-post-content-container">

                              <router-link :to="{
                                name: 'PostDetail',
                                params: { id: post.id }
                              }" class="post-title">
                                <h4 class="text-capitalize">
                                  {{ post.categoryTitle }}
                                </h4>
                              </router-link>
                              <router-link :to="{
                                name: 'CategoryList',
                                params: { id: post.categoryId }
                              }">
                                <h5 class="post-title font-italic">
                                  {{ post.category }}
                                </h5>
                              </router-link>
                              <div class="post-content">
                                <p class="">
                                  <span>{{ post.shortDescription }}</span>

                                </p>
                              </div>
                              <div class="row no-gutters">
                                <div class="col-md-9"></div>
                                <div class="col-md-3">
                                  <span>
                                    <router-link :to="{
                                      name: 'CategoryList',
                                      params: { id: post.categoryId }
                                    }" class="text-decoration-none post-categories-link">
                                      <span>
                                        {{ $t('Home.ReadMore') }}
                                        <!--                          Category-->
                                      </span>
                                    </router-link>
                                  </span>
                                </div>
                              </div>
                              <!--                          <div class="post-meta">
                                                          <div class="post-meta-content">
                                                  <span class="post-auhor-date">
                                                    <span v-if="post.numberofDays > 1">
                                                      <i class="post-date">
                                                        {{ post.numberofDays }}
                                                         days ago
                                                      </i>
                                                    </span>
                                                    <span v-else-if="post.numberofDays == 1">
                                                      <i class="post-date">
                                                        {{ post.numberofDays }} day ago
                                                      </i>
                                                    </span>
                                                    <span v-else>
                                                      <i class="post-date">
                                                        today
                                                      </i>
                                                    </span>

                                                  </span>
                                                            &lt;!&ndash;                        <a
                                                                                      href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                                                      class="post-comments">
                                                                                      <i class="icon icon-bubble"></i> 0
                                                                                    </a>
                                                                                    <span class="post-readtime">
                                                                                      <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                                                    </span>&ndash;&gt;

                                                          </div>
                                                        </div>-->


                              <!--                  <div class="post-meta">
                                                  <div class="post-meta-content">
                                                  <span class="post-auhor-date">
                                                    <span>
                                                      <a
                                                        href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                                                        class="post-author">
                                                        Gillion
                                                      </a>
                                                  </span>,
                                                  <a
                                                    href="https://gillion.shufflehound.com/home/2017/01/31/the-buddha-said-it-is-not-the-number-of-blog-tips-you-offer-but-the-quality/"
                                                    class="post-date"> 5 years ago
                                                  </a>
                                                </span>
                                                    <span class="post-readtime">
                                                      <i
                                                        class="icon icon-clock">

                                                      </i> 1 min
                                                      <span>read</span>
                                                  </span>
                                                  </div>
                                                </div>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div class="mt-4 ">
                    <b-pagination class="custom-b-pagination" v-model="currentPage" :total-rows="rows"
                      :per-page="pageSIZE" aria-controls="my-latestPosts" @change="onChange($event)"></b-pagination>
                  </div>




                </div>
              </div>
            </div>
            <div class="post-container" v-if="urdudata == true">

<div class="custom-widget mt-2 mb-2 border-bottom ">
  <h2 style="float:right" class="widget-title text-uppercase">{{ $t('Home.LATESTARTICLES') }}</h2>
</div>



<div>
  <div :key="postscomponentKey">
    <div>

      <div class="row no-gutters">
        <div class="col-lg-6 mt-1 mb-1" v-for="(post, index) in latestPosts" :key="post.id">
          <div id="my-latestPosts" class="post-thumbnail blog-mini-post blog-mini-post-large ml-2 mr-2">


            <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
              <div class="blog-mini-post-thumb sh-post-review-mini">
                <div class="">

                  <img :src="url + post.videoOrImage" class="custom-post-small-image" />

                </div>
                <router-link :to="{
                  name: 'PostDetail',
                  params: { id: post.id }
                }" class="post-overlay text-decoration-none">

                  <div class="post-overlay-content custom-mini-post-overlay-content"><span></span>
                    <span></span> <span></span>
                  </div>
                </router-link>
              </div>

            </div>

            <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
              <div class="blog-mini-post-thumb sh-post-review-mini">
                <div class="">
                  <img
                    :src="'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'"
                    class="custom-post-small-image">


                </div>
                <a href="javascript:void(0)" @click="getVideoSrc(post.youtubeLink)" data-toggle="modal"
                  data-target="#videoModal" :title="post.youtubeLink"
                  class="post-overlay text-decoration-none">
                  <div class="post-categories-container custom-play-icon">
                    <div
                      class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                      <b-icon icon="play-circle"></b-icon>
                    </div>
                  </div>


                </a>


              </div>
            </div>
            <div v-else>
              <div class="blog-mini-post-thumb sh-post-review-mini">
                <div class="">
                  <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">


                </div>



              </div>
            </div>
            <!--              <div class="blog-mini-post-thumb sh-post-review-mini">
                            <div>
                              <img :src="url+post.videoOrImage" style="width: 108px; height: 75px"/>
                            </div>
                            <router-link
                              :to="{ name: 'PostDetail',
                                          params: { id: post.id }
                                        }"
                              class=" post-overlay-small"
                            >

                              <div class="post-overlay-small"></div>
                            </router-link>
                          </div>-->

            <div class="blog-mini-post-content">
              <div class="blog-mini-post-content-container">

                <router-link :to="{
                  name: 'PostDetail',
                  params: { id: post.id }
                }" class="post-title">
                  <h4 class="text-capitalize">
                    {{ post.urduDescriptionTitle }}
                  </h4>
                </router-link>
                <router-link :to="{
                  name: 'CategoryList',
                  params: { id: post.categoryId }
                }">
                  <h5 class="post-title font-italic">
                    {{ post.category }}
                  </h5>
                </router-link>
                <div class="post-content">
                  <p class="">
                    <span>{{ post.urduShortDescription }}</span>

                  </p>
                </div>
                <div class="row no-gutters">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <span>
                      <router-link :to="{
                        name: 'CategoryList',
                        params: { id: post.categoryId }
                      }" class="text-decoration-none post-categories-link">
                        <span>
                          {{ $t('Home.ReadMore') }}
                          <!--                          Category-->
                        </span>
                      </router-link>
                    </span>
                  </div>
                </div>
                <!--                          <div class="post-meta">
                                            <div class="post-meta-content">
                                    <span class="post-auhor-date">
                                      <span v-if="post.numberofDays > 1">
                                        <i class="post-date">
                                          {{ post.numberofDays }}
                                           days ago
                                        </i>
                                      </span>
                                      <span v-else-if="post.numberofDays == 1">
                                        <i class="post-date">
                                          {{ post.numberofDays }} day ago
                                        </i>
                                      </span>
                                      <span v-else>
                                        <i class="post-date">
                                          today
                                        </i>
                                      </span>

                                    </span>
                                              &lt;!&ndash;                        <a
                                                                        href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                                        class="post-comments">
                                                                        <i class="icon icon-bubble"></i> 0
                                                                      </a>
                                                                      <span class="post-readtime">
                                                                        <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                                      </span>&ndash;&gt;

                                            </div>
                                          </div>-->


                <!--                  <div class="post-meta">
                                    <div class="post-meta-content">
                                    <span class="post-auhor-date">
                                      <span>
                                        <a
                                          href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                                          class="post-author">
                                          Gillion
                                        </a>
                                    </span>,
                                    <a
                                      href="https://gillion.shufflehound.com/home/2017/01/31/the-buddha-said-it-is-not-the-number-of-blog-tips-you-offer-but-the-quality/"
                                      class="post-date"> 5 years ago
                                    </a>
                                  </span>
                                      <span class="post-readtime">
                                        <i
                                          class="icon icon-clock">

                                        </i> 1 min
                                        <span>read</span>
                                    </span>
                                    </div>
                                  </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="mt-4 ">
      <b-pagination class="custom-b-pagination" v-model="currentPage" :total-rows="rows"
        :per-page="pageSIZE" aria-controls="my-latestPosts" @change="onChange($event)"></b-pagination>
    </div>




  </div>
</div>
</div>
          </div>


        </div>
        <div class="col-lg-3" v-if="urdudata == false">
          <div class="ml-1 mr-1">
            <div class="mb-5">
              <div class="custom-widget mt-2 mb-2">
                <h2 class="widget-title">{{ $t('Home.FEATUREDPOSTS') }}</h2>
              </div>

              <div style="max-height: 630px; overflow: auto" class="border-bottom">
                <div v-for="(post, index) in featuredPosts" :key="post.id"
                  class="post-thumbnail blog-mini-post blog-mini-post-large">
                  <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div>


                        <img :src="url + post.videoOrImage" class="custom-post-small-image" />



                      </div>
                      <router-link :to="{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }" class="post-overlay text-decoration-none">

                        <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span>
                          <span></span>
                        </div>
                      </router-link>
                    </div>

                  </div>
                  <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div class="">
                        <img
                          :src="'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'"
                          class="custom-post-small-image">


                      </div>
                      <a href="javascript:void(0)" @click="getVideoSrc(post.youtubeLink)" data-toggle="modal"
                        data-target="#videoModal" :title="post.youtubeLink" class="post-overlay text-decoration-none">
                        <div class="post-categories-container custom-play-icon">
                          <div
                            class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                            <b-icon icon="play-circle"></b-icon>
                          </div>
                        </div>
                      </a>


                    </div>
                  </div>
                  <div v-else>
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div class="">
                        <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">


                      </div>



                    </div>
                  </div>
                  <!--                <div class="blog-mini-post-thumb sh-post-review-mini">
                                    <div>
                                      <img :src="url+post.videoOrImage" style="width: 108px; height: 75px"/>
                                    </div>
                                    <router-link
                                      :to="{ name: 'PostDetail',
                                                params: { id: post.id }
                                              }"
                                      class=" post-overlay-small"
                                    >

                                      <div class="post-overlay-small"></div>
                                    </router-link>
                                  </div>-->

                  <div class="blog-mini-post-content">
                    <div class="blog-mini-post-content-container">

                      <router-link :to="{
                        name: 'CategoryList',
                        params: { id: post.categoryId }
                      }" class="post-title">
                        <h4 class="text-capitalize">
                          {{ post.categoryTitle }}
                        </h4>
                      </router-link>
                      <h5 class="post-title font-italic">
                        {{ post.category }}
                      </h5>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div>
              <div class="w-100">
                <div v-if="HomeRightSideAdd != []" class="w-100 text-center">
                  <a :href="HomeRightSideAdd[0].url" :title="HomeRightSideAdd[0].url" target="_blank"
                    class="navbar-brand mr-0">
                    <img :src="url + HomeRightSideAdd[0].image" class="addimagestyling" />
                  </a>
                </div>
                <div v-else class="w-100 h-100 text-center">
                  <img src="../assets/images/large-ad.jpg" class=" w-100" />
                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-3" v-if="urdudata == true">
          <div class="ml-1 mr-1">
            <div class="mb-5">
              <div class="custom-widget mt-2 mb-2">
                <h2 style="float:right" class="widget-title">{{ $t('Home.FEATUREDPOSTS') }}</h2>
              </div>

              <div style="max-height: 630px; overflow: auto" class="border-bottom">
                <div v-for="(post, index) in featuredPosts" :key="post.id"
                  class="post-thumbnail blog-mini-post blog-mini-post-large">
                  <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div>


                        <img :src="url + post.videoOrImage" class="custom-post-small-image" />



                      </div>
                      <router-link :to="{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }" class="post-overlay text-decoration-none">

                        <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span>
                          <span></span>
                        </div>
                      </router-link>
                    </div>

                  </div>
                  <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div class="">
                        <img
                          :src="'https://img.youtube.com/vi/' + post.youtubeLink.substring(30, post.youtubeLink.length) + '/0.jpg'"
                          class="custom-post-small-image">


                      </div>
                      <a href="javascript:void(0)" @click="getVideoSrc(post.youtubeLink)" data-toggle="modal"
                        data-target="#videoModal" :title="post.youtubeLink" class="post-overlay text-decoration-none">
                        <div class="post-categories-container custom-play-icon">
                          <div
                            class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                            <b-icon icon="play-circle"></b-icon>
                          </div>
                        </div>
                      </a>


                    </div>
                  </div>
                  <div v-else>
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div class="">
                        <img src="../assets/images/whychooseus.jpg" class="custom-post-small-image">


                      </div>



                    </div>
                  </div>
                  <!--                <div class="blog-mini-post-thumb sh-post-review-mini">
                                    <div>
                                      <img :src="url+post.videoOrImage" style="width: 108px; height: 75px"/>
                                    </div>
                                    <router-link
                                      :to="{ name: 'PostDetail',
                                                params: { id: post.id }
                                              }"
                                      class=" post-overlay-small"
                                    >

                                      <div class="post-overlay-small"></div>
                                    </router-link>
                                  </div>-->

                  <div class="blog-mini-post-content">
                    <div class="blog-mini-post-content-container">

                      <router-link :to="{
                        name: 'CategoryList',
                        params: { id: post.categoryId }
                      }" class="post-title">
                        <h4 class="text-capitalize">
                          {{ post.urduDescriptionTitle }}
                        </h4>
                      </router-link>
                      <h5 class="post-title font-italic">
                        {{ post.category }}
                      </h5>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div>
              <div class="w-100">
                <div v-if="HomeRightSideAdd != []" class="w-100 text-center">
                  <a :href="HomeRightSideAdd[0].url" :title="HomeRightSideAdd[0].url" target="_blank"
                    class="navbar-brand mr-0">
                    <img :src="url + HomeRightSideAdd[0].image" class="addimagestyling" />
                  </a>
                </div>
                <div v-else class="w-100 h-100 text-center">
                  <img src="../assets/images/large-ad.jpg" class=" w-100" />
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- Video Modal-->

    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" @click="closeModal()" class="close custom-close-btn" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="embed-responsive embed-responsive-16by9">

            <iframe v-if="isVideoAvailable" class="embed-responsive-item" width="1280" height="720" :src="videoUrl"
              frameborder="0" allowfullscreen></iframe>
          </div>

          <!--          <video id="videoId" width="100%" controls>
            <source :src="videoUrl" type="video/mp4">

            Your browser does not support HTML video.
          </video>-->

        </div>
      </div>
    </div>


  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios';
import moment from 'moment'

// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: 'Home',
  components: {


  },
  data() {
    return {



      isVideoAvailable: false,
      urdudata:false,
      slides: [
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
      ],
      settingsForLatestOrFeaturedJobs: {

        infinite: true,
        slidesToShow: 2,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,

              infinite: true

            }
          }
        ]
      },

      settings1: {
        "infinite": true,
        "slidesToShow": 3,
        "autoplay": true,
        "slidesToScroll": 3,
        "autoplaySpeed": 8000,
        "pauseOnFocus": true,
        "pauseOnHover": true,
      },


      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      pageSIZE: 6,
      totalCount: 0,

      list: [],
      PageOfItems: [],
      info: [],
      option: 0,

      isHealthAndBeauty: false,
      isupcomming: true,
      isSportsAndLeisure: false,
      isBeveragesAndDining: false,
      isHotelsAndStaycations: false,


      //model for Featured Posts

      modelFeaturedPosts: {

        PageSize: 0,
        PageNumber: 1,
        Text: "",
        postType: "FeaturedPost"
      },


      //model for latest posts
      modelLatestPosts: {

        PageSize: this.pageSIZE,
        PageNumber: this.currentPage,
        Text: "",
        postType: "LatestArticles"
      },



      //posts
      featuredPosts: [],
      latestPosts: [],
      Upcomingevents: [],
      postType: null,


      //Video Source
      videoUrl: "",

      //Posts by categories
      postList: [],
      healthAndBeauty: {},
      beveragesAndDining: {},
      hotelsAndStaycations: {},
      sportsAndLeisure: {},
      kidsAndFamily: {},
      flowersAndGardening: {},



      loading: false,
      errored: false,


      componentKey: 0,
      postscomponentKey: 0,
    }
  },

  computed: {
    rows() {
      return this.totalCount
    },

    HomeRightSideAdd() {
      console.log("availableHomeRightSide : ", this.$store.getters.availableHomeRightSide)
      return this.$store.getters.availableHomeRightSide
    },

  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      debugger
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    GetUpcommingEvents() {
      axios.post('/api/Shehri/GetUpcommingEvents')
        .then(res => {
          let list = res.data.data;

          this.Upcomingevents = list
          console.log("Upcomingevents", this.Upcomingevents);
          this.loading = false;
        })
    },
    showPrevious() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
    getAdds() {
      this.$store.dispatch("fetchAdds");
    },
    getLatestPosts() {
      this.rerenderLatestPosts();

      this.loading = true;

      // this.list = [];
      this.latestPosts = [];
      this.modelLatestPosts.PageNumber = this.currentPage;

      this.modelLatestPosts.TotalRecord = this.totalCount;
      this.modelLatestPosts.PageSize = this.pageSIZE;


      axios.post('/api/Shehri/GetPostType', this.modelLatestPosts)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }


          let list = res.data.data;
          console.log('list : ', list)
          this.totalCount = res.data.totalCount;
          console.log("totalCount:" + this.totalCount);
          /*this.total = Math.ceil(this.totalCount / this.pageSIZE);
          console.log("totlapages:" + this.total);*/
          this.latestPosts = list
          /*console.log('this.list : ', this.list)
          for (let post in this.list){

            if(this.list[post].postType == "LatestArticles"){
              this.latestPosts.push(this.list[post]);
            }

          }*/
          console.log("latestPosts  : ", this.latestPosts);


          this.loading = false;


          //setTimeout(this.timeOut,1000);

        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    getFeaturedPosts() {
      this.rerender()
      //this.info = [];
      this.featuredPosts = [];



      axios.post('/api/Shehri/GetPostType', this.modelFeaturedPosts)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }


          /*let list = res.data.data;
          this.totalCount = list.length;
          console.log("totalCount:" + this.totalCount);
          this.total = Math.ceil(this.totalCount / this.pageSIZE);
          console.log("totlapages:" + this.total);*/
          let list = res.data.data;
          console.log('list : ', list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')


          this.featuredPosts = list.slice().reverse();

          /* for (let post in this.info){
 
             if(this.info[post].postType === "FeaturedPost"){
               this.featuredPosts.push(this.info[post]);
             }
 
           }*/
          console.log("Featured Jobs : ", this.featuredPosts);



        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },


    getPostByCategoryHealthAndBeauty() {
      this.postList = []
      let model = {
        categoryid: 117
      }

      axios
        .post('/api/Shehri/GetPost', model)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('healthAndBeauty : ', list)
          this.postList = list.slice().reverse();
          console.log('healthAndBeauty1 : ', this.postList)

          if (this.postList.length != 0) {
            this.isHealthAndBeauty = true;







            let sl = {
              categoryId: this.postList[0].categoryId,
              category: this.postList[0].category,
              categoryDescription: this.postList[0].categoryDescription,
              shortDescription: this.postList[0].shortDescription,
              urduCategoryDescription: this.postList[0].urduCategoryDescription,
              urduDescriptionTitle: this.postList[0].urduDescriptionTitle,
              urduShortDescription: this.postList[0].urduShortDescription,
              categoryTitle: this.postList[0].categoryTitle,
              id: this.postList[0].id,
              descriptionTitle: this.postList[0].descriptionTitle,
              numberofDays: this.postList[0].numberofDays,
              type: this.postList[0].type,
              videoOrImage: this.postList[0].videoOrImage,
              youtubeLink: this.postList[0].youtubeLink
            }
            console.log("healthAndBeauty obj", sl)
            this.healthAndBeauty = sl;
          }
        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })
    },
    getPostByCategoryBeveragesAndDining() {
      this.postList = []
      let model = {
        categoryid: 118
      }

      axios
        .post('/api/Shehri/GetPost', model)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('beveragesAndDining : ', list)
          this.postList = list.slice().reverse();
          console.log('beveragesAndDining : ', this.postList)

          if (this.postList.length != 0) {

            this.isBeveragesAndDining = true;

            let sl = {
              categoryId: this.postList[0].categoryId,
              category: this.postList[0].category,
              categoryDescription: this.postList[0].categoryDescription,
              shortDescription: this.postList[0].shortDescription,
              categoryTitle: this.postList[0].categoryTitle,
              id: this.postList[0].id,
              descriptionTitle: this.postList[0].descriptionTitle,
              urduCategoryDescription: this.postList[0].urduCategoryDescription,
              urduDescriptionTitle: this.postList[0].urduDescriptionTitle,
              urduShortDescription: this.postList[0].urduShortDescription,
              numberofDays: this.postList[0].numberofDays,
              type: this.postList[0].type,
              videoOrImage: this.postList[0].videoOrImage,
              youtubeLink: this.postList[0].youtubeLink
            }
            console.log(sl)
            this.beveragesAndDining = sl;
          }
        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })
    },
    getPostByCategoryHotelsAndStaycations() {
      this.postList = []
      let model = {
        categoryid: 119
      }

      axios
        .post('/api/Shehri/GetPost', model)
        .then(res => {
          // code that we will 'try' to run

          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('hotelsAndStaycations : ', list)
          this.postList = list.slice().reverse();
          console.log('hotelsAndStaycations : ', this.postList)

          if (this.postList.length != 0) {

            this.isHotelsAndStaycations = true;
            let sl = {
              categoryId: this.postList[0].categoryId,
              category: this.postList[0].category,
              categoryDescription: this.postList[0].categoryDescription,
              shortDescription: this.postList[0].shortDescription,
              categoryTitle: this.postList[0].categoryTitle,
              urduCategoryDescription: this.postList[0].urduCategoryDescription,
              urduDescriptionTitle: this.postList[0].urduDescriptionTitle,
              urduShortDescription: this.postList[0].urduShortDescription,
              id: this.postList[0].id,
              descriptionTitle: this.postList[0].descriptionTitle,
              numberofDays: this.postList[0].numberofDays,
              type: this.postList[0].type,
              videoOrImage: this.postList[0].videoOrImage,
              youtubeLink: this.postList[0].youtubeLink
            }
            console.log(sl)
            this.hotelsAndStaycations = sl;
          }
        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })
    },
    getPostByCategorySportsAndLeisure() {
      this.postList = []
      let model = {
        categoryid: 120
      }

      axios
        .post('/api/Shehri/GetPost', model)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('sportsAndLeisurelist : ', list)
          this.postList = list.slice().reverse();
          console.log('sportsAndLeisure : ', this.postList)

          if (this.postList.length != 0) {
            this.isSportsAndLeisure = true;
            let sl = {
              categoryId: this.postList[0].categoryId,
              category: this.postList[0].category,
              categoryDescription: this.postList[0].categoryDescription,
              shortDescription: this.postList[0].shortDescription,
              urduCategoryDescription: this.postList[0].urduCategoryDescription,
              urduDescriptionTitle: this.postList[0].urduDescriptionTitle,
              urduShortDescription: this.postList[0].urduShortDescription,
              categoryTitle: this.postList[0].categoryTitle,
              id: this.postList[0].id,
              descriptionTitle: this.postList[0].descriptionTitle,
              numberofDays: this.postList[0].numberofDays,
              type: this.postList[0].type,
              videoOrImage: this.postList[0].videoOrImage,
              youtubeLink: this.postList[0].youtubeLink
            }
            console.log(sl)
            this.sportsAndLeisure = sl;
          }


        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })
    },


    getVideoSrc(vSrc) {
      // var video = document.getElementById("videoId");
      this.videoUrl = vSrc;
      console.log("videoUrl : ", this.videoUrl)

      this.isVideoAvailable = true;
    },


    closeModal(id) {
      /*var video = document.getElementById(id);
        video.pause();*/
      this.isVideoAvailable = false;
      /*$('#videoModal').on('hidden.bs.modal', function (e) {
        $('#videoModal video').pause()
      })*/
    },


    onChange(e) {
      console.log("Page Event : ", e)
      this.currentPage = e;
      this.getLatestPosts();
    },

    timeOut() {
      this.loading = false;
    },

    showPrevious() {
      this.$refs.carousel.prev()
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob() {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob() {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs() {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs() {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee() {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee() {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar() {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar() {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers() {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers() {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender() {
      this.componentKey += 1
    },
    //Rerender Component
    rerenderLatestPosts() {
      this.postscomponentKey += 1
    },
  },
  // beforemounted(){

  // },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  mounted() {
    this.GetUpcommingEvents();
    this.getAdds();
    this.getLatestPosts();
    this.getFeaturedPosts();
    this.getPostByCategoryHealthAndBeauty();
    this.getPostByCategoryBeveragesAndDining();
    this.getPostByCategoryHotelsAndStaycations();
    this.getPostByCategorySportsAndLeisure();
    
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
    
    //this.scrollTo(this.mainContainer);
    //this.getAllJobs();
    //this.getLatestJobs();
    //this.getEmployers();
    //this.getCityList();
  }
}
</script>
<style scoped>
.carousel {
  margin: 50px auto;
  padding: 0 70px;
}

.carousel .item {
  color: #747d89;
  min-height: 325px;
  text-align: center;
  overflow: hidden;
}

.carousel .thumb-wrapper {
  padding: 25px 15px;
  background: #fff;
  border-radius: 6px;
  text-align: center;
  position: relative;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.carousel .item .img-box {
  height: 120px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.carousel .item img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.carousel .item h4 {
  font-size: 18px;
}

.carousel .item h4,
.carousel .item p,
.carousel .item ul {
  margin-bottom: 5px;
}

.carousel .thumb-content .btn {
  color: #7ac400;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: 1px solid #7ac400;
  padding: 6px 14px;
  margin-top: 5px;
  line-height: 16px;
  border-radius: 20px;
}

.carousel .thumb-content .btn:hover,
.carousel .thumb-content .btn:focus {
  color: #fff;
  background: #7ac400;
  box-shadow: none;
}

.carousel .thumb-content .btn i {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}

.carousel .item-price {
  font-size: 13px;
  padding: 2px 0;
}

.carousel .item-price strike {
  opacity: 0.7;
  margin-right: 5px;
}

.carousel-control-prev,
.carousel-control-next {
  height: 44px;
  width: 40px;
  background: #7ac400;
  margin: auto 0;
  border-radius: 4px;
  opacity: 0.8;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background: #78bf00;
  opacity: 1;
}

.carousel-control-prev i,
.carousel-control-next i {
  font-size: 36px;
  position: absolute;
  top: 50%;
  display: inline-block;
  margin: -19px 0 0 0;
  z-index: 5;
  left: 0;
  right: 0;
  color: #fff;
  text-shadow: none;
  font-weight: bold;
}

.carousel-control-prev i {
  margin-left: -2px;
}

.carousel-control-next i {
  margin-right: -4px;
}

.carousel-indicators {
  bottom: -50px;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 50%;
  border: none;
}

.carousel-indicators li {
  background: rgba(0, 0, 0, 0.2);
}

.carousel-indicators li.active {
  background: rgba(0, 0, 0, 0.6);
}

.carousel .wish-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
  cursor: pointer;
  font-size: 16px;
  color: #abb0b8;
}

.carousel .wish-icon .fa-heart {
  color: #ff6161;
}

.star-rating li {
  padding: 0;
}

.star-rating i {
  font-size: 14px;
  color: #ffc000;
}

.addimagestyling {
  height: 800px;
  width: 250px;
}

@media screen and (max-width:720px) {
  .addimagestyling {
    height: 350px;
width: 170px;
  }
}
</style>