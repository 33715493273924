import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home'
import CategoryList from '../pages/CategoryList'
import DirectoryList from '../pages/directory/DirectoryList'
import PostDetail from '../pages/PostDetail'
import Eventdetails from '../pages/Eventdetails'
import Packages from '../pages/Packages'
import Archive from '../pages/Archive'
import orderform from '../pages/orderform'
import PackageInvoice from '../pages/PackageInvoice'
import PaymentDetails from '../pages/PaymentDetails'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/category-list/:id',
    name: 'CategoryList',
    component: CategoryList
  },

  {
    path: '/post-detail/:id',
    name: 'PostDetail',
    component: PostDetail
  },
  {
    path: '/event-details/:id',
    name: 'Eventdetails',
    component: Eventdetails
  },
  {
    path: '/order-form/:id',
    name: 'orderform',
    component: orderform
  },
  {
    path: '/package-invoice/:id',
    name: 'PackageInvoice',
    component: PackageInvoice
  },
  {
    path: '/payment-details/:id',
    name: 'PaymentDetails',
    component: PaymentDetails
  },
  {
    path: '/social-register',
    name: 'DirectoryList',
    component: DirectoryList
  },
  
  {
    path: '/packages',
    name: 'Packages',
    component: Packages
  },
  {
    path: '/archive/:id',
    name: 'Archive',
    component: Archive
  },
  
  
  {
    path: '/about-us',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about-us" */ '../pages/AboutUs.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact-us" */ '../pages/ContactUs.vue')
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("../pages/PageNotFound"),
  },
  { path: "*", name: "redirectPageNotFound", redirect: "/404" }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
