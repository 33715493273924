<template>
  <div  class="style-mustard-bg">
    <div class="container-fluid style-mustard-bg style-brand-spaces custom-bottom-border">
      <div class="container">
        <div class="row">
          <!--        <div class="col-md-3">
                    <div class="style-logo-footer-section">
                      <img src="../assets/images/logo-dark-159x76.png" />
                    </div>
                  </div>-->
          <div v-if="urdudata == false" class="col-md-4">
            <div class="ml-1 mr-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="footer widget-title">News of the Day</h3>
              </div>
              <div v-for="(post, index) in news" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-small">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null" >
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-mini-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-mini-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrcInFooter(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModalInFooter" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-mini-post-small-image">


                    </div>



                  </div>
                </div>
              

                  <div class="blog-mini-post-content">
                    <div class="blog-mini-post-content-container">
                   
                      <router-link :to="{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }" class="post-title">
                        <h4 class=" footer  text-capitalize custom-footer-post-title">
                          {{ post.categoryTitle }}
                        </h4>
                      </router-link>
                      <div class="post-content">
                        <p class="">
                          <span>{{ post.shortDescription }}</span>

                        </p>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                                <span>
                                  <router-link
                                    :to="{
                                      name: 'PostDetail',
                                      params: { id: post.id }
                                    }"
                                    class="text-decoration-none post-categories-link post-categories-footer-link">
                                    <span>
                                      Read More
                                      <!--                          Category-->
                                    </span>
                                  </router-link>
                                </span>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              
  <router-link
                        :to="{
                          name: 'Archive',
                          params: { id: 1 }
                        }"
                        class="btn btn-danger"
                        style="margin: 0rem 0 0 12rem;"
                      >
                      Archive
                      </router-link>
              </div>
            </div>
            <div v-if="urdudata == true" class="col-md-4">
            <div class="ml-1 mr-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="footer widget-title">  {{ $t('footer.NewsoftheDay') }}</h3>
              </div>
              <div v-for="(post, index) in news" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-small">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null" >
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-mini-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-mini-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrcInFooter(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModalInFooter" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-mini-post-small-image">


                    </div>



                  </div>
                </div>
              

                  <div class="blog-mini-post-content">
                    <div class="blog-mini-post-content-container">
                   
                      <router-link :to="{
                        name: 'PostDetail',
                        params: { id: post.id }
                      }" class="post-title">
                        <h4 class=" footer  text-capitalize custom-footer-post-title">
                          {{ post.urduDescriptionTitle }}
                        </h4>
                      </router-link>
                      <div class="post-content">
                        <p class="">
                          <span>{{ post.urduShortDescription }}</span>

                        </p>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                                <span>
                                  <router-link
                                    :to="{
                                      name: 'PostDetail',
                                      params: { id: post.id }
                                    }"
                                    class="text-decoration-none post-categories-link post-categories-footer-link">
                                    <span>
                                      {{ $t('Home.ReadMore') }}
                                      <!--                          Category-->
                                    </span>
                                  </router-link>
                                </span>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              
  <router-link
                        :to="{
                          name: 'Archive',
                          params: { id: 1 }
                        }"
                        class="btn btn-danger"
                        style="margin: 0rem 0 0 12rem;"
                      >
                      Archive
                      </router-link>
              </div>
            </div>
          <div v-if="urdudata == false" class="col-md-4">
            <div class="ml-1 mr-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="footer widget-title">Featured Stories</h3>
              </div>

              <div v-for="(post, index) in featuredStories" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-small">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null" >
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-mini-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-mini-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrcInFooter(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModalInFooter" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-mini-post-small-image">


                    </div>



                  </div>
                </div>
                <!--                <div class="blog-mini-post-thumb sh-post-review-mini">
                  <div>
                    <img :src="url+'/'+post.videoOrImage" style="width: 108px; height: 75px"/>
                  </div>
                  <router-link
                    :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                    class=" post-overlay-small"
                  >

                    <div class="post-overlay-small"></div>
                  </router-link>
                </div>-->

                <div class="blog-mini-post-content">
                  <div class="blog-mini-post-content-container">
                    <!--                  v-if="post.category == 'HealthAndBeauty'"-->
                    <!--                  <h5  class="footer post-title font-italic">
                                        {{ post.category }}
                                      </h5>-->
                    <!--                  <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                                        Beverages & Dining
                                      </h5>
                                      <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                                        Hotels & Staycations
                                      </h5>
                                      <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                                        Sports & Leisure
                                      </h5>
                                      <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                                        Kids & Family
                                      </h5>
                                      <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                                        Flowers & Gardening
                                      </h5>
                                      <span v-else class="post-title font-italic">

                                      </span>-->
                    <router-link :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }" class="post-title">
                      <h4 class=" footer  text-capitalize custom-footer-post-title">
                        {{ post.categoryTitle }}
                      </h4>
                    </router-link>
                    <div class="post-content">
                      <p class="">
                        <span>{{ post.shortDescription}}</span>

                      </p>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                              <span>
                                <router-link
                                  :to="{ name: 'PostDetail',
                                    params: { id: post.id }
                                    }"
                                  class="text-decoration-none post-categories-link post-categories-footer-link">
                                  <span>
                                    Read More
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                      </div>
                    </div>
                    <!--                  <div class="post-meta">
                                        <div class="post-meta-content">
                                        <span class="post-auhor-date">
                                          <span>
                                            <a
                                              href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                                              class="post-author">
                                              Gillion
                                            </a>
                                        </span>,
                                        <a
                                          href="https://gillion.shufflehound.com/home/2017/01/31/the-buddha-said-it-is-not-the-number-of-blog-tips-you-offer-but-the-quality/"
                                          class="post-date"> 5 years ago
                                        </a>
                                      </span>
                                          <span class="post-readtime">
                                            <i
                                              class="icon icon-clock">

                                            </i> 1 min
                                            <span>read</span>
                                        </span>
                                        </div>
                                      </div>-->
                  </div>
                </div>
              </div>
              <router-link
                        :to="{
                          name: 'Archive',
                          params: { id: 2 }
                        }"
                        class="btn btn-danger"
                        style="margin: 10rem 0 0 12rem;"
                      >
                      Archive
                      </router-link>

            </div>
          </div>
          <div v-if="urdudata == true" class="col-md-4">
            <div class="ml-1 mr-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="footer widget-title">{{ $t('footer.FeaturedStories') }}</h3>
              </div>

              <div v-for="(post, index) in featuredStories" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-small">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null" >
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-mini-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-mini-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrcInFooter(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModalInFooter" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-mini-post-small-image">


                    </div>



                  </div>
                </div>
                <!--                <div class="blog-mini-post-thumb sh-post-review-mini">
                  <div>
                    <img :src="url+'/'+post.videoOrImage" style="width: 108px; height: 75px"/>
                  </div>
                  <router-link
                    :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                    class=" post-overlay-small"
                  >

                    <div class="post-overlay-small"></div>
                  </router-link>
                </div>-->

                <div class="blog-mini-post-content">
                  <div class="blog-mini-post-content-container">
                    <!--                  v-if="post.category == 'HealthAndBeauty'"-->
                    <!--                  <h5  class="footer post-title font-italic">
                                        {{ post.category }}
                                      </h5>-->
                    <!--                  <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                                        Beverages & Dining
                                      </h5>
                                      <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                                        Hotels & Staycations
                                      </h5>
                                      <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                                        Sports & Leisure
                                      </h5>
                                      <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                                        Kids & Family
                                      </h5>
                                      <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                                        Flowers & Gardening
                                      </h5>
                                      <span v-else class="post-title font-italic">

                                      </span>-->
                    <router-link :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }" class="post-title">
                      <h4 class=" footer  text-capitalize custom-footer-post-title">
                        {{ post.urduDescriptionTitle }}
                      </h4>
                    </router-link>
                    <div class="post-content">
                      <p class="">
                        <span>{{ post.urduShortDescription}}</span>

                      </p>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                              <span>
                                <router-link
                                  :to="{ name: 'PostDetail',
                                    params: { id: post.id }
                                    }"
                                  class="text-decoration-none post-categories-link post-categories-footer-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                      </div>
                    </div>
                    <!--                  <div class="post-meta">
                                        <div class="post-meta-content">
                                        <span class="post-auhor-date">
                                          <span>
                                            <a
                                              href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                                              class="post-author">
                                              Gillion
                                            </a>
                                        </span>,
                                        <a
                                          href="https://gillion.shufflehound.com/home/2017/01/31/the-buddha-said-it-is-not-the-number-of-blog-tips-you-offer-but-the-quality/"
                                          class="post-date"> 5 years ago
                                        </a>
                                      </span>
                                          <span class="post-readtime">
                                            <i
                                              class="icon icon-clock">

                                            </i> 1 min
                                            <span>read</span>
                                        </span>
                                        </div>
                                      </div>-->
                  </div>
                </div>
              </div>
              <router-link
                        :to="{
                          name: 'Archive',
                          params: { id: 2 }
                        }"
                        class="btn btn-danger"
                        style="margin: 10rem 0 0 12rem;"
                      >
                      Archive
                      </router-link>

            </div>
          </div>
          <div v-if="urdudata == false" class="col-md-4">
            <div class="ml-1 mr-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="footer widget-title">Articles & Blogs</h3>
              </div>
              <div v-for="(post, index) in articlesAndBlogs" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-small">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-mini-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-mini-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrcInFooter(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModalInFooter" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-mini-post-small-image">


                    </div>



                  </div>
                </div>
                <!--                <div class="blog-mini-post-thumb sh-post-review-mini">
                  <div>
                    <img :src="url+'/'+post.videoOrImage" style="width: 108px; height: 75px"/>
                  </div>
                  <router-link
                    :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                    class=" post-overlay-small"
                  >

                    <div class="post-overlay-small"></div>
                  </router-link>
                </div>-->

                <div class="blog-mini-post-content">
                  <div class="blog-mini-post-content-container">
                    <!--                  v-if="post.category == 'HealthAndBeauty'"-->
                    <!--                  <h5  class="footer post-title font-italic">
                                        {{ post.category }}
                                      </h5>-->
                    <!--                  <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                                        Beverages & Dining
                                      </h5>
                                      <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                                        Hotels & Staycations
                                      </h5>
                                      <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                                        Sports & Leisure
                                      </h5>
                                      <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                                        Kids & Family
                                      </h5>
                                      <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                                        Flowers & Gardening
                                      </h5>
                                      <span v-else class="post-title font-italic">

                                      </span>-->
                    <router-link :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }" class="post-title">
                      <h4 class=" footer text-capitalize custom-footer-post-title">
                        {{ post.categoryTitle }}
                      </h4>
                    </router-link>
                    <div class="post-content">
                      <p class="">
                        <span>{{ post.shortDescription}}</span>

                      </p>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                              <span>
                                <router-link
                                  :to="{ name: 'PostDetail',
                                  params: { id: post.id }
                                  }"
                                  class="text-decoration-none post-categories-link post-categories-footer-link">
                                  <span>
                                    Read More
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                      </div>
                    </div>
                    <!--                  <div class="post-meta">
                                        <div class="post-meta-content">
                                        <span class="post-auhor-date">
                                          <span>
                                            <a
                                              href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                                              class="post-author">
                                              Gillion
                                            </a>
                                        </span>,
                                        <a
                                          href="https://gillion.shufflehound.com/home/2017/01/31/the-buddha-said-it-is-not-the-number-of-blog-tips-you-offer-but-the-quality/"
                                          class="post-date"> 5 years ago
                                        </a>
                                      </span>
                                          <span class="post-readtime">
                                            <i
                                              class="icon icon-clock">

                                            </i> 1 min
                                            <span>read</span>
                                        </span>
                                        </div>
                                      </div>-->
                  </div>
                </div>
              </div>
              <router-link
                        :to="{
                          name: 'Archive',
                          params: { id: 3 }
                        }"
                        class="btn btn-danger"
                        style="margin: 1rem 0 0 12rem;"
                      >
                      Archive
                      </router-link>
              <!--            <div class="custom-banner-column">
                            <div class="custom-banner-image-wrap">
                              <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item"
                                        src="https://www.youtube.com/embed/Zv11L-ZfrSg"
                                        allowfullscreen></iframe>
                              </div>
                              &lt;!&ndash; <video width="100%" controls>
                                  <source src="./assets/videos/h.mp4" type="video/mp4">

                                  Your browser does not support HTML video.
                              </video> &ndash;&gt;
                              &lt;!&ndash; <img src="./assets/images/Rectangle.jpg" class="custom-banner-image"
                                  alt=""> &ndash;&gt;
                            </div>

                          </div>-->

            </div>
          </div>
          <div v-if="urdudata == true" class="col-md-4">
            <div class="ml-1 mr-1">
              <div class="custom-widget mt-2 mb-2">
                <h3 class="footer widget-title">{{ $t('footer.Articles&Blogs') }}</h3>
              </div>
              <div v-for="(post, index) in articlesAndBlogs" :key="post.id" class="post-thumbnail blog-mini-post blog-mini-post-small">

                <div v-if="post.videoOrImage != '' && post.videoOrImage != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">

                      <img :src="url+post.videoOrImage" class="custom-mini-post-small-image"/>

                    </div>
                    <router-link
                      :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                      class="post-overlay text-decoration-none"
                    >

                      <div class="post-overlay-content custom-mini-post-overlay-content"><span></span> <span></span> <span></span></div>
                    </router-link>
                  </div>

                </div>
                <div v-else-if="post.youtubeLink != '' && post.youtubeLink != null">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img :src="'https://img.youtube.com/vi/'+post.youtubeLink.substring(30, post.youtubeLink.length)+'/0.jpg'" class="custom-mini-post-small-image">


                    </div>
                    <a
                      href="javascript:void(0)"
                      @click="getVideoSrcInFooter(post.youtubeLink)"
                      data-toggle="modal" data-target="#videoModalInFooter" :title="post.youtubeLink"
                      class="post-overlay text-decoration-none">
                      <div class="post-categories-container custom-play-icon">
                        <div class="post-overlay-content custom-post-overlay-content custom-mini-custom-post-overlay-content">
                          <b-icon icon="play-circle"></b-icon>
                        </div>
                      </div>
                    </a>


                  </div>
                </div>
                <div v-else>
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div class="">
                      <img src="../assets/images/whychooseus.jpg" class="custom-mini-post-small-image">


                    </div>



                  </div>
                </div>
                <!--                <div class="blog-mini-post-thumb sh-post-review-mini">
                  <div>
                    <img :src="url+'/'+post.videoOrImage" style="width: 108px; height: 75px"/>
                  </div>
                  <router-link
                    :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }"
                    class=" post-overlay-small"
                  >

                    <div class="post-overlay-small"></div>
                  </router-link>
                </div>-->

                <div class="blog-mini-post-content">
                  <div class="blog-mini-post-content-container">
                    <!--                  v-if="post.category == 'HealthAndBeauty'"-->
                    <!--                  <h5  class="footer post-title font-italic">
                                        {{ post.category }}
                                      </h5>-->
                    <!--                  <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                                        Beverages & Dining
                                      </h5>
                                      <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                                        Hotels & Staycations
                                      </h5>
                                      <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                                        Sports & Leisure
                                      </h5>
                                      <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                                        Kids & Family
                                      </h5>
                                      <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                                        Flowers & Gardening
                                      </h5>
                                      <span v-else class="post-title font-italic">

                                      </span>-->
                    <router-link :to="{ name: 'PostDetail',
                              params: { id: post.id }
                            }" class="post-title">
                      <h4 class=" footer text-capitalize custom-footer-post-title">
                        {{ post.urduDescriptionTitle }}
                      </h4>
                    </router-link>
                    <div class="post-content">
                      <p class="">
                        <span>{{ post.urduShortDescription}}</span>

                      </p>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                              <span>
                                <router-link
                                  :to="{ name: 'PostDetail',
                                  params: { id: post.id }
                                  }"
                                  class="text-decoration-none post-categories-link post-categories-footer-link">
                                  <span>
                                    {{ $t('Home.ReadMore') }}
                                    <!--                          Category-->
                                  </span>
                                </router-link>
                              </span>
                      </div>
                    </div>
                    <!--                  <div class="post-meta">
                                        <div class="post-meta-content">
                                        <span class="post-auhor-date">
                                          <span>
                                            <a
                                              href="https://gillion.shufflehound.com/home/author/gillionshufflehound/"
                                              class="post-author">
                                              Gillion
                                            </a>
                                        </span>,
                                        <a
                                          href="https://gillion.shufflehound.com/home/2017/01/31/the-buddha-said-it-is-not-the-number-of-blog-tips-you-offer-but-the-quality/"
                                          class="post-date"> 5 years ago
                                        </a>
                                      </span>
                                          <span class="post-readtime">
                                            <i
                                              class="icon icon-clock">

                                            </i> 1 min
                                            <span>read</span>
                                        </span>
                                        </div>
                                      </div>-->
                  </div>
                </div>
              </div>
              <router-link
                        :to="{
                          name: 'Archive',
                          params: { id: 3 }
                        }"
                        class="btn btn-danger"
                        style="margin: 1rem 0 0 12rem;"
                      >
                      Archive
                      </router-link>
              <!--            <div class="custom-banner-column">
                            <div class="custom-banner-image-wrap">
                              <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item"
                                        src="https://www.youtube.com/embed/Zv11L-ZfrSg"
                                        allowfullscreen></iframe>
                              </div>
                              &lt;!&ndash; <video width="100%" controls>
                                  <source src="./assets/videos/h.mp4" type="video/mp4">

                                  Your browser does not support HTML video.
                              </video> &ndash;&gt;
                              &lt;!&ndash; <img src="./assets/images/Rectangle.jpg" class="custom-banner-image"
                                  alt=""> &ndash;&gt;
                            </div>

                          </div>-->

            </div>
          </div>






        </div>
      </div>
    </div>
    <div class="container-fluid style-black-bg style-brand-spaces">
      <div class="container">
        <div class="row style-light-grey">
          <div class="col-lg-5">
            <div class="style-right-reserved">
              <p>© {{ new Date().getFullYear() }}. All Rights Reserved.</p>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="style-up-icon text-lg-center">
              <a href="javascript:void(0)" @click="scrollTo()" id="back-to-top" class="add-fix-top active">
                <i class="fa fa-angle-up" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="social text-lg-right">
              <span class="style-social-icons"
              ><a href="https://www.facebook.com/Harshehar.Offical/" class="facebook" target="_blank"> <i class="fab fa-facebook"></i></a>
              </span>
              <span class="style-social-icons"
              ><a href="https://twitter.com/har_shehar" class="twitter" target="_blank"> <i class="fab fa-twitter"></i> </a
              ></span>
              <span class="style-social-icons"
              ><a href="https://www.youtube.com/channel/UCetNHbRcnPDyPdPlKwhGPjw" class="youtube" target="_blank"> <i class="fab fa-youtube"></i></a>
              </span>
              <span class="style-social-icons"
              ><a href="https://www.linkedin.com/company/har-shehar" class="linkedin" target="_blank"> <i class="fab fa-linkedin"></i></a
              ></span>

              <span class="style-social-icons"
              ><a href="https://www.instagram.com/harshehar.offical/" class="instagram" target="_blank"> <i class="fab fa-instagram"></i></a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="videoModalInFooter" tabindex="-1" role="dialog" aria-labelledby="videoModalInFooterLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" @click="closeModal()" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="embed-responsive embed-responsive-16by9">

            <iframe v-if="isVideoAvailableInFooter" class="embed-responsive-item" width="1280" height="720" :src="videoUrlInFooter"  allowfullscreen></iframe>
          </div>
<!--          <video id="videoId" width="100%" controls>
            <source :src="videoUrl" type="video/mp4">

            Your browser does not support HTML video.
          </video>-->

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name:"Footer",
  data(){
    return{
      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      pageSIZE: 3,
      totalCount:0,
      urdudata:false,
      list: [],
      PageOfItems: [],
      info: [],
      option: 0,




      //model for all posts

      model: {

        PageSize: 5,
        PageNumber: 1,
        Text: ""
      },

      //posts
      featuredStories: [],
      //model for Featured Posts

      modelFeaturedStories: {

        PageSize: 5,
        PageNumber: 1,
        Text: "",
        postType: "FeaturedStories"
      },
      news: [],
      modelNews: {

        PageSize: 5,
        PageNumber: 1,
        Text: "",
        postType: "NewsofDay"
      },
      articlesAndBlogs: [],
      modelArticlesAndBlogs: {

        PageSize: 5,
        PageNumber: 1,
        Text: "",
        postType: "ArticlesAndBlogs"
      },
      postType: null,


      //Video Source
      isVideoAvailableInFooter:false,
      videoUrlInFooter:"",


    }
  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  methods:{
    scrollTo(){
      this.$emit('scrollTo-top');
    },
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    getAll() {
      this.rerender()

      this.list = [];
      this.model.PageNumber = this.currentPage;
      this.model.TotalPages = this.total;
      this.model.TotalRecord = this.totalCount;
      this.model.PageSize = this.pageSIZE;


      axios.post('/api/Shehri/GetAllPost',this.model)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }


          let list = res.data.data;
          this.totalCount = list.length;
          console.log("totalCount:" + this.totalCount);
          this.total = Math.ceil(this.totalCount / this.pageSIZE);
          console.log("totlapages:" + this.total);
          this.list = list;
          console.log('this.list : ', this.list)


        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    getNewsOfTheDay(){
      this.rerender()
      //this.info = [];
      this.news = [];



      axios.post('/api/Shehri/GetPostType',this.modelNews)
        .then(res => {



          /*let list = res.data.data;
          this.totalCount = list.length;
          console.log("totalCount:" + this.totalCount);
          this.total = Math.ceil(this.totalCount / this.pageSIZE);
          console.log("totlapages:" + this.total);*/
          let list = res.data.data;
          console.log('list : ', list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')


          this.news = list

          /* for (let post in this.info){

             if(this.info[post].postType === "FeaturedPost"){
               this.featuredPosts.push(this.info[post]);
             }

           }*/
          console.log("news  : ", this.news);



        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    getFeaturedStories(){
      this.rerender()
      //this.info = [];
      this.featuredStories = [];



      axios.post('/api/Shehri/GetPostType',this.modelFeaturedStories)
        .then(res => {



          /*let list = res.data.data;
          this.totalCount = list.length;
          console.log("totalCount:" + this.totalCount);
          this.total = Math.ceil(this.totalCount / this.pageSIZE);
          console.log("totlapages:" + this.total);*/
          let list = res.data.data;
          console.log('list : ', list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')


          this.featuredStories = list


          console.log("featuredStories  : ", this.featuredStories);



        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    getArticlesAndBlogs(){
      this.rerender()
      //this.info = [];
      this.articlesAndBlogs = [];



      axios.post('/api/Shehri/GetPostType',this.modelArticlesAndBlogs)
        .then(res => {



          /*let list = res.data.data;
          this.totalCount = list.length;
          console.log("totalCount:" + this.totalCount);
          this.total = Math.ceil(this.totalCount / this.pageSIZE);
          console.log("totlapages:" + this.total);*/
          let list = res.data.data;
          console.log('list : ', list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')


          this.articlesAndBlogs = list

          /* for (let post in this.info){

             if(this.info[post].postType === "FeaturedPost"){
               this.featuredPosts.push(this.info[post]);
             }

           }*/
          console.log("articlesAndBlogs  : ", this.articlesAndBlogs);



        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    getVideoSrcInFooter(vSrc){

      this.isVideoAvailableInFooter = true;
      /*this.isVideoAvailable = true;
      var video = document.getElementById("videoId");*/
      this.videoUrlInFooter = vSrc;
      console.log("videoUrl : ",this.isVideoAvailableInFooter, this.videoUrlInFooter)

      /*video.src = this.videoUrl;
      video.play();*/
    },
    closeModal(id){
      this.isVideoAvailableInFooter = false;
      this.videoUrlInFooter = "";
      console.log("vidurl", this.videoUrlInFooter)
      /* var video = document.getElementById(id);
       video.pause();*/
      /*$('#videoModal').on('hidden.bs.modal', function (e) {
        $('#videoModal video').pause()
      })*/
    },
    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },
  mounted () {
    //this.getAll();
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
    this.getNewsOfTheDay();
    this.getFeaturedStories();
    this.getArticlesAndBlogs();
  }
}
</script>
