<template>
  <header class="custom-mainmenu sticky-top">

    <div class="">
      <nav class="custom-navbar navbar navbar-expand-lg navbar-light">

        <div></div>




        <div
          class="navbar-collapse custom-multi-navbar ml-3 mr-3"
          id="headerSupportedContent"
        >

          <div  class="navbar mr-auto w-100 pl-0 pr-0">
            <ul class="navbar-nav">
              <li class="nav-item mr-5">
                <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" :to="{ name: 'Home'}">
                  {{ $t('nav.Home') }}
                </router-link
                >
              </li>
              <li class="nav-item dropdown mr-5">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="categoriesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ $t('nav.Categories') }}

                </a>
                <div v-if="urdudata== false" class="dropdown-menu w3-animate-opacity" aria-labelledby="categoriesDropdown">
                  <router-link
                    v-for="destination in destinations" :key="destination.id" class="dropdown-item custom-menu-link"
                    :to="{ name : 'CategoryList',
                    params : {id: destination.id}}">{{ destination.value }}</router-link>
                </div>
                <div v-if="urdudata== true" class="dropdown-menu w3-animate-opacity" aria-labelledby="categoriesDropdown">
                  <router-link
                    v-for="destination in destinations" :key="destination.id" class="dropdown-item custom-menu-link"
                    :to="{ name : 'CategoryList',
                    params : {id: destination.id}}">{{ destination.Urduvlaue }}</router-link>
                </div>
              </li>
              <li class="nav-item mr-5">
                <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/social-register">
                  {{ $t('nav.SocialRegister') }}
                </router-link>
              </li>
              <li class="nav-item mr-5">
                <router-link
                  class="nav-link custom-menu-link py-1 px-0 mr-3"
                  to="/packages"
                >
                 {{ $t('nav.Packages') }}
                </router-link>
              </li>
              <li class="nav-item mr-5">
                <router-link
                  class="nav-link custom-menu-link py-1 px-0 mr-3"
                  to="/about-us"
                >
                {{ $t('nav.AboutUs') }}
                </router-link>
              </li>
              <li class="nav-item mr-5">
                <router-link
                  class="nav-link custom-menu-link py-1 px-0 mr-3"
                  to="/contact-us"
                >
                {{ $t('nav.ContactUs') }}
                </router-link>
              </li>

            </ul>
            <div class="">
              <div class="input-group custom-search-input" style="direction: ltr;"> 
                <div class="input-group-prepend">
                    <span
                    
                      class="input-group-text custom-input-group-icon"
                      id="basic-addon1"
                    ><b-icon icon="Search"></b-icon
                    ></span>
                </div>
                <input
                  type="text"
                  class="form-control custom-filter-input form-control-sm"
                  v-model="jobSearchByName.filter.text"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  @keydown.enter.prevent="setSearchString()"
                />
              </div>
            </div>
          </div>

          <!--            <template v-if="$auth.check()" >
                        &lt;!&ndash; <ul v-if="$auth.check()" class="navbar-nav mr-auto"> &ndash;&gt;

                           <ul  v-if="$auth.user().user.role == 'Employer'" class="navbar-nav mr-auto">
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-dashboard">
                            Dashboard
                          </router-link
                          >
                        </li>
                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myJobsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Jobs
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myJobsDropdown">
                            <router-link class="dropdown-item" to="/employer-jobs">All Listed Jobs</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates/0">All Employee Profile</router-link>


                          </div>
                        </li>

          &lt;!&ndash;              <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            CV Folder
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/employer-jobs">Description of Jobs</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV via Unassisted Hiring</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV via Assisted Hiring</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV upload within a Department</router-link>

                          </div>
                        </li>&ndash;&gt;


                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myToolsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Tools
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myToolsDropdown">
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-analytics">Company</router-link>&ndash;&gt;
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-about-us">Company Public Profile</router-link>&ndash;&gt;
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-teamlist">Department</router-link>&ndash;&gt;


                            <router-link class="dropdown-item" to="/employer-profile">Update Company Profile</router-link>
                            <router-link class="dropdown-item" to="/employer-teamlist">Team Members</router-link>
                            <router-link class="dropdown-item" to="/change-password">Account Settings</router-link>
                            <router-link class="dropdown-item" to="/employer-accountsetting">Alerts / Notifications </router-link>
                            <router-link class="dropdown-item" to="/employer-packagehistory">Purchase History </router-link>
                            <router-link class="dropdown-item" to="/employer-package">Packages </router-link>


                          </div>
                        </li>
                        &lt;!&ndash;              <li class="nav-item mr-2">
                                        <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                          My Account
                                        </router-link>

                                      </li>&ndash;&gt;

                        &lt;!&ndash; <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                            Employer
                          </router-link>
                        </li> &ndash;&gt;

                       <li class="nav-item mr-2">
                         <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/cvsearch">
                           CV Search
                         </router-link>
                       </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-about-us/0">
                            About Us
                          </router-link>
                        </li>
                         <li class="nav-item mr-2">
                           <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-postjob">
                             Post A Job
                           </router-link>
                         </li>
                      </ul>
                          <ul v-else class="navbar-nav mr-auto">
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employee-dashboard">
                            Dashboard
                          </router-link
                          >
                        </li>
                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Account
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/employee-profile">Profile</router-link>
                            <router-link class="dropdown-item" to="/employee-jobs">Applications</router-link>
                            <router-link class="dropdown-item" to="/purchase-history">Purchase History</router-link>
                            <router-link class="dropdown-item" :to="{ name: 'ChangePassword'}">Change Password</router-link>

                          </div>
                        </li>
                        &lt;!&ndash;              <li class="nav-item mr-2">
                                        <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                          My Account
                                        </router-link>

                                      </li>&ndash;&gt;
                        <li class="nav-item mr-2">
                          <router-link
                              class="nav-link custom-menu-link py-1 px-0 mr-3"
                              to="/all-jobs"
                          >
                            Find Job
                          </router-link>
                        </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                            Employer
                          </router-link>
                        </li>


                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/about-us">
                            About Us
                          </router-link>
                        </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/add-profile-detail">
                            Complete Your Profile
                          </router-link>
                        </li>
                      </ul>
                    </template>-->
        </div>

        <div></div>
      </nav>
    </div>




  </header>
</template>

<script>
import axios from 'axios';
export default {
  name: "MainMenu",
  components:{

  },
  data(){
    return{
      status:0,

      // bool
      urdudata:false,
      loggedOut:true,
      loggedIn:false,
      //userName: this.$auth.user().fullName,
      //email: this.$auth.user().email,
      //phoneNo:this.$auth.user().phoneNumber,
      //mailtoEmail:"mailto:"+this.$auth.user().email,

      //url:axios.defaults.baseURL,

      destinations:[
    {id: 117,	value:"Health & Beauty",Urduvlaue:"تندرستی اور خوبصورتی"},
    {id: 118,	value:"Beverages & Dining",Urduvlaue:"مشروبات اور کھانے"},
    {id: 119,	value:"Hotels & Staycations",Urduvlaue:"ہوٹل اور قیام"},
    {id: 120,	value:"Sports & Leisure",Urduvlaue:"کھیل اور تفریح"},
    {id: 121,	value:"Kids & Family",Urduvlaue:"بچے اور خاندان"},
    {id: 122,	value:"Recipes",Urduvlaue:"ترکیبیں"},
    {id: 133,	value:"Trending Styles",Urduvlaue:"ٹرینڈنگ اسٹائلز"},
    {id: 245,	value:"Restaurants",Urduvlaue:"ریستوراں"}
      ],
      jobSearchByName:{
        categoryid:0,
        filter:{
          pageSize: 5,
          pageNumber: 1,
          text: null
        }

      },
    };

  },
  mounted(){

    this.getCMSCategoryList();
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
   //this.setSearchString();
    //this.getAll();


    // this.$nextTick(() => {
    //   // Change top navbar on scroll
    //   $(window).on("scroll", function() {
    //     if($(window).scrollTop() > 100) {
    //       $("#ad-banner-id").addClass("active");
    //     } else {
    //       $("#ad-banner-id").removeClass("active");
    //     }
    //   });
    // });

  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },


  methods: {
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      debugger
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    //Get Dropdowns List
    // getCMSCategoryList(){
    //   this.destinations = null;
    //   let model = {
    //     type: "CmsCategory"
    //   };

    //   axios
    //     .post("/api/KeyValuePair/GetDropDownListByIds", model)
    //     .then(res => {
    //       // code that we will 'try' to run
    //       this.destinations = res.data.data;

    //       console.log("Add Response portion");

    //       console.log("destinations:", this.destinations);
    //     })
    //     .catch(error => {
    //       console.log("Errorrrrrr : ",error);
    //       this.errored = true;
    //     })
    //     .finally(() => {
    //       if (this.errored) {

    //         console.log("Error : Sorry there is no response");
    //         // this.$bvToast.toast("Sorry there is no response ", {
    //         //   toaster: "b-toaster-top-center",
    //         //   variant: "danger",

    //         //   solid: true,
    //         // });
    //       } else {






    //       }
    //     });
    // },

    setSearchString(){
      console.log("type", this.$route.name)

      console.log("id : ",this.$route.params.id)
      if(this.$route.name == "CategoryList" && this.$route.params.id != 0){
        /*this.$router.push(
            { name: 'AllJobs', params: { searchString: this.jobTitle }
            })*/
        this.jobSearchByName.categoryid = Number(this.$route.params.id);
        this.getJobsByName(this.jobSearchByName);
      }
      else {
        this.jobSearchByName.categoryid = 0;
        this.$router.push(
          { name: 'CategoryList', params:{ id: this.jobSearchByName.categoryid} })
        this.getJobsByName(this.jobSearchByName);


      }

    },
    getJobsByName(jobFilter){
      console.log("jobFilter : ", jobFilter)
      this.$store.dispatch("getJobsByName", jobFilter)
      //this.$router.push({name: 'AllJobs', params: { jobTitle }})
    },

  },
};
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>
