<template>
  <header class="custom-mainmenu" style="direction: ltr;">

    <div class="custom-header">
      <!--      :class="[!$auth.check() ? 'custom-header-wrap':'', $auth.check() ? 'custom-header-height':'']"-->
      <div class="custom-header-wrap" >
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light">

          <div></div>
          <router-link class="navbar-brand custom-navbar-brand py-0" to="/">
            <img
              src="../assets/images/final-logo-har-shehar-159x44.png"
              class="d-inline-block align-top custom-navbar-logo"
            />
          </router-link>



          <div
            class="navbar-collapse custom-multi-navbar ml-5"
            id="headerSupportedContent"
          >



            <div id="ad-banner-id" class="mr-auto">

              <div v-if="topBarAdd != []">
                <a :href="topBarAdd[0].url" :title="topBarAdd[0].url" target="_blank" class="navbar-brand mr-0">
                  <img :src="url+topBarAdd[0].image" class="w-100" style="height: 90px;object-fit: cover" />
                </a>
              </div>
              <div v-else>
                <router-link to="/" target="_blank" class="navbar-brand mr-0">
                  <img src="../assets/images/wide.f3b806b1.png" class="w-100" />
                </router-link>
              </div>
            </div>


          </div>

          <div></div>
        </nav>

      </div>
    </div>

    <!--    shows on mobile view-->
    <div class="custom-header-ad">
      <div class="custom-header-wrap">
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light justify-content-center">
          <div class="custom-mobile-view-ad">
            <div class="" style="">

              <div v-if="topBarAdd != []">
                <a :href="topBarAdd[0].url" :title="topBarAdd[0].url" target="_blank" class="navbar-brand mr-0">
                  <img :src="url+topBarAdd[0].image" class="w-100" />
                </a>
              </div>
              <div v-else>
                <router-link to="/" target="_blank" class="navbar-brand mr-0">
                  <img src="../assets/images/wide.f3b806b1.png" class="w-100" />
                </router-link>
              </div>
            </div>
          </div>
        </nav>

      </div>
    </div>
  </header>
</template>

<script>

import axios from "axios";
export default {
  name: "BannerHeader",
  components:{


  },
  data(){
    return{
      status:0,

      // bool
      loggedOut:true,
      loggedIn:false,
      //userName: this.$auth.user().fullName,
      //email: this.$auth.user().email,
      //phoneNo:this.$auth.user().phoneNumber,
      //mailtoEmail:"mailto:"+this.$auth.user().email,

      url:axios.defaults.baseURL,



    };

  },
  computed:{
    topBarAdd(){
      console.log("topbarAdd : ", this.$store.getters.availableTopBarAdds)
      return this.$store.getters.availableTopBarAdds
    }
  },
  mounted(){
    this.getAdds();

    //this.getAll();


    // this.$nextTick(() => {
    //   // Change top navbar on scroll
    //   $(window).on("scroll", function() {
    //     if($(window).scrollTop() > 100) {
    //       $("#ad-banner-id").addClass("active");
    //     } else {
    //       $("#ad-banner-id").removeClass("active");
    //     }
    //   });
    // });

  },



  methods: {

    getAdds(){
      this.$store.dispatch("fetchAdds");
    },

  },
};
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>
